
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter, get_identity_pool, get_aws_region, get_aws_bucket } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import { flushSync } from 'react-dom';
import AWS from 'aws-sdk'
import { useNavigate } from "react-router-dom";



export const  UploadAdSpend = () =>{
  const navigate = useNavigate();

  // Upload File
  const [file, setFile] = useState(null)
  const [fileColumns, setFileColumns] = useState(null)

  //Convert File to Text
  const [startedConvertingFile, setStartedConvertingFile] = useState(false)
  const [stringFile, setStringFile] = useState(null)

  //Slices
  const [doSendSliceApi, setDoSendSliceApi] = useState(false)
  const [sentSliceApiSend, setSentSliceApiSend] = useState(null)
  const [slices, setSlices] = useState(null)
  const [assignedSlices, setAssignedSlices] = useState({'all':false})

  //Company ID
  const [companyID, setCompanyID] = useState(null)
  const [sentCompanyIDApi, setSentCompanyIDApi] = useState(false)


  //Upload
  const [doUploadFile, setDoUploadFile] = useState(false)
  const [didStartUpload, setDidStartUpload] = useState(false)
  
  //Sending To DB
  const [doSendToDB, setDoSendToDB] = useState(false)
  const [sentToDB, setSentToDB] = useState(false)

  
  
  


  useEffect(() => {
    //Get Slices
    if(doSendSliceApi && !sentSliceApiSend){
      get_slices()
      setSentSliceApiSend(true)
    }
    //Get Company ID
    else if(companyID === null && !sentCompanyIDApi){
      get_company_id()
      setSentCompanyIDApi(true)
    }
    //Convert File to String for upload
    else if(file !== null && ! startedConvertingFile && stringFile === null){
      convert_file_to_string()
      setStartedConvertingFile(true)
    }
    //Upload to S3   
    else if(doUploadFile && !didStartUpload){
      upload_file_to_s3()
      setDidStartUpload(true)
    }
    else if(doSendToDB && !sentToDB){
      send_file_db()
      setSentToDB(true)
    }

  })


  //Auto Apis
  async function get_company_id(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'company',
        func:"get_company_id"
      }
      
      response = await send_request(body)
      setCompanyID(response['company_id'])
      
    } 
    catch(err){
      console.log('get_company_id body', body)
      console.log('get_company_id response', response)
      throw new Error(err)
    }
  }

  async function get_slices(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'slices',
        func:"get_slices"
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      for(var i=0;i<Object.keys(response).length;i++){
        var slice_id = Object.keys(response)[i]
        response[slice_id]['search_values'] = JSON.parse(response[slice_id]['search_values'])
      }
      
      setSlices(response)
      
    } 
    catch(err){
      console.log('get_slices body', body)
      console.log('get_slices response', response)
      throw new Error(err)
    }
  }

  //Upload File Apis
  async function upload_file_to_s3(){ 
    
    AWS.config.update({
      credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: get_identity_pool()
      }),
      region: get_aws_region(),
    }); 

    var bucket = get_aws_bucket('public-uploads')
    var s3 = new AWS.S3({
        params: {Bucket: get_aws_bucket('public-uploads'), region: get_aws_region()}
    });

    var key = companyID + '/ad_spend/' + file.name;
    s3.upload(
        {
        Key: key,
        Body: stringFile,
        ACL: 'public-read',
        region: get_aws_region()
        }, 
        function(err, data) {
            if(err) {debugger}
            //Upload is successful
            
            setDoSendToDB(true)
        }
        ).on(
            'httpUploadProgress', 
            function (progress) {
                var uploaded = parseInt((progress.loaded * 100) / progress.total);
                console.log('uploaded: ', uploaded)
                //$("progress").attr('value', uploaded);
            });
                   
}

  async function send_file_db(){

    var send_slice_ids = []
    
    for(var i=0; i<Object.keys(assignedSlices).length;i++){
      var slice_id = Object.keys(assignedSlices)[i]
      if(assignedSlices[slice_id]){
        send_slice_ids.push(parseInt(slice_id))
      }
    }

    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'ad_spend',
        func:"upload_ad_spend",
        file_name: file.name,
        slice_ids: send_slice_ids
      }
      
      response = await send_request(body)

      debugger
      
      navigate('/settings/ad_spend')
      
    } 
    catch(err){
      console.log('get_slices body', body)
      console.log('get_slices response', response)
      throw new Error(err)
    }
  }




//Render
function render_upload_file_button(){
  var display_classname = ''
  if(file !== null){
    display_classname = 'd-none'
  }

  return (
    <Container  className={display_classname}  >
        <Row>
          <Col lg ="4">
            <h1>Upload Ad Spend</h1>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <input type="file" onChange={handleFileSelected} id='inp_file_dialog' />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            
          </Col>
        </Row>
        
    
    </Container>
  );
}

function render_file_info(){
  if(file === null || fileColumns === null){
    return 
  }

  var text_column_names = ''
  for(var i=0;i<fileColumns.length;i++){
    text_column_names += fileColumns[i] + ', '
  }

  var date_found = fileColumns.includes("Date") || fileColumns.includes("date") ? 'True' : <p className='text-danger mb-0'>False</p>
  var amount_found = fileColumns.includes("Amount") || fileColumns.includes("amount")? 'True' : <p className='text-danger mb-0'>False</p>
  var currency_found = fileColumns.includes("Currency") || fileColumns.includes("currency")? 'True' : <p className='text-danger mb-0'>False</p>
  var platform_found = fileColumns.includes("Platform") || fileColumns.includes("platform")? 'True' : <p className='text-danger mb-0'>False</p>

  
  var has_valid_columns = date_found === 'True' && amount_found === 'True' && currency_found === 'True' && platform_found === 'True'

  var btn_refresh = ''
  if(!has_valid_columns){
    btn_refresh = 
      <React.Fragment>
        <Row className='mt-4'>
          <Col>
            Please change the names of the appropriate columns to match the above names and try again. 
          </Col>
        </Row>
        <button className='btn btn-lg btn-warning mt-3' onClick={clear_file}>Refresh</button>
      </React.Fragment> 
  }

  var btn_upload = ''
  var assigned_slices = ''
  if(has_valid_columns){
    assigned_slices = render_slices()
    if(slices !== null){
      if(doUploadFile){
        btn_upload = loading_gif('Uploading')
      }
      else{
        btn_upload = <button className='btn btn-lg btn-warning mt-3' onClick={setDoUploadFile}>Upload</button>
      }
      
    }

  }

  

  return(
    <React.Fragment>
      <Row>
        <Col>
          <h5>File Name:</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{file.name}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={2}><b>Required Column</b></Col>
        <Col><b>Found in File</b></Col>
      </Row>
      <Row>
        <Col lg={2}>Date:</Col>
        <Col>{date_found}</Col>
      </Row>
      <Row>
        <Col lg={2}>Amount:</Col>
        <Col>{amount_found}</Col>
      </Row>

      <Row className='mt-4'>
        <Col lg={2}><b>Optional Column</b></Col>
        <Col><b>Found in File</b></Col>
      </Row>
      <Row>
        <Col lg={2}>Currency:</Col>
        <Col>{currency_found}</Col>
      </Row>
      <Row>
        <Col lg={2}>Platform:</Col>
        <Col>{platform_found}</Col>
      </Row>

      {btn_refresh}
      {assigned_slices}
      {btn_upload}

    </React.Fragment>
  )

}

  function render_slices(){
    
    if(slices === null && !doSendSliceApi){
      setDoSendSliceApi(true)
      return loading_gif('Loading Slices')
    }
    else if(slices === null && doSendSliceApi){
      return loading_gif('Loading Slices')
    }

    var header = ''
    if(Object.keys(slices).length > 0){
      header = 
      <React.Fragment>
        <p className='mt-3'>&nbsp;</p>
        <h5 className='mt-4'><b>Assign Slices:</b></h5>
        <Row className='mt-1'>
          <Col lg={1}><b>Add</b></Col>
          <Col><b>Slice Name</b></Col>
        </Row>
      </React.Fragment>
    }

    var slice_options = []
    var all_checked = assignedSlices['all']
    if(!assignedSlices['all']){
      for(var i=0; i<Object.keys(slices).length;i++){
        var key = Object.keys(slices)[i]
        var selected = Object.keys(assignedSlices).includes(key) ? assignedSlices[key] : false
        slice_options.push(
          <React.Fragment>
            <Row>
              <Col lg={1}>
                <input type='checkbox' key={'chk_' + key} id={'chk_' + key} onClick={chk_slice_clicked} checked={selected}/>  
              </Col>
              <Col>
                {slices[Object.keys(slices)[i]]['slice_name']}  
              </Col>
            </Row>
          </React.Fragment>
        )
      }
    }
    
    
    var spacer = assignedSlices['all'] ? '' : <hr></hr>
    
    

    return(
      <React.Fragment>
        {header}
        <Row>
            <Col lg={1}>
              <input type='checkbox' key={'chk_all'} id={'chk_all'} onClick={chk_slice_clicked} checked={all_checked}/>  
            </Col>
            <Col>
              All
            </Col>
          </Row>
          <Row>
            <Col lg={2}>
              {spacer}
            </Col>
          </Row>
          
          
        {slice_options}
      </React.Fragment>
    )


  }

//Functions
function chk_slice_clicked(e){
  

  var new_assigned_slices = {}
  for(var i=0;i<Object.keys(slices).length;i++){
    var key = Object.keys(slices)[i]
    var ele = document.getElementById('chk_' + key)
    
    if(ele !== null){
      new_assigned_slices[Object.keys(slices)[i]] = get_ele('chk_' + key).checked 
    }
  }

  //Check "All" check
  new_assigned_slices['all'] = get_ele('chk_all').checked 

  setAssignedSlices(new_assigned_slices)


}

function clear_file(){
  flushSync(
    setFile(null),
    setFileColumns(null),
    setStringFile(null)
  )
}

async function convert_file_to_string(){
  var files = document.getElementById('inp_file_dialog').files;
  
  var t_file = files[0]
  var reader = new FileReader();
  //reader.readAsDataURL(t_file);
  reader.readAsText(t_file)
  reader.onload = function () {
      var results = reader.result
      //debugger
      //let utf8Encode = new TextEncoder();
      //results = utf8Encode.encode(results);
      console.log(reader.result);
      flushSync(
          setStringFile(results),
          setStartedConvertingFile(false)
      )
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
      debugger
  };

}

  //Select and read file
  const fileReader = new FileReader();
  //fileReader.fileName = get_val('inp_file_dialog')
  fileReader.onload = function (event) {
    const csv_text = event.target.result;
    const columns = csv_text.slice(0, csv_text.indexOf("\n")).split(",");
    var cols = []
    for(var i=0;i<columns.length;i++){
      cols.push(columns[i].toLowerCase())
    }
    setFileColumns(cols)
  };


  const handleFileSelected = (e) => { 
    var t_file = e.target.files[0]
    if (t_file && fileColumns === null) {
      fileReader.readAsText(t_file);
    }

    setFile(t_file)

  };



return(
  <React.Fragment>
    {render_upload_file_button()}
    {render_file_info()}
  </React.Fragment>
)




 }

  
    