import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {send_request, format_dollar} from "../globals/globals";
import arrow_rise from "../images/arrow_rise.svg";
import arrow_fall from "../images/arrow_fall.svg";
import loading_gif from "../globals/loading_gif";
import btn_right_arrow from "../images/btn_right_arrow.svg";
import btn_down_arrow from "../images/btn_down_arrow.svg";
import {Link, } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { isna } from "../globals/globals";
import { Tooltip } from 'react-tooltip'




export const  DriversAndDrags = (props) =>{
      const [dnd_dict, setDndDict] = useState({}) // {'positive0':{}, 'positive1':{},'positive2':{},'negative0':{}, 'negative1':{},'negative2':{}}
      const [is_getting_drivers_n_drags, setIsGettingDriversNDrags] = useState(false)
      const prev_props = useRef();


      useEffect(() => {
        if(props.selected_calendar_date !== null && Object.keys(dnd_dict).length === 0 && props.cohort_filter_id !== 0 && !isna(props.selected_calendar_date['value']) && !is_getting_drivers_n_drags){
          prev_props.cohort_filter_id = props.cohort_filter_id
          prev_props.calendar_type = props.selected_calendar_type
          prev_props.as_of = props.selected_calendar_date['value']
          get_drivers_n_drags()
          setIsGettingDriversNDrags(true)
        }
        else if(props.selected_calendar_date !== null && prev_props.cohort_filter_id !== props.cohort_filter_id | prev_props.calendar_type !== props.selected_calendar_type | prev_props.as_of !== props.selected_calendar_date['value']){
          setTimeout(() =>{setDndDict({})}, 1)
          
        }
      });




  //api calls
  async function get_drivers_n_drags(){
    var body = ''
    var response = ''
    try{
      body = {
        path:"drivers_and_drags",
        func:"get_drivers_and_drags",
        as_of: props.selected_calendar_date['value'],
        calendar_type: props.selected_calendar_type,
        cohort_filter_id: props.cohort_filter_id
      };
      
      let response = await send_request(body)
      response = JSON.parse(response)
        
    
    

    
  
    let measures = Object.values(response['measure'])
    let values = Object.values(response['value'])
    let yoys = Object.values(response['yoy'])
    
    let pretty_print_measure = {
      'impact_order_frequency_existing':'Existing Order Frequency',
      'impact_price_per_unit_new': 'New Price Per Unit',
      'impact_units_per_order_new': 'New Units Per Order',
      'impact_price_per_unit_existing': 'Existing Price Per Unit',
      'lever_number_of_new': 'Number of New',
      'impact_retention_existing': 'Retention',
      'impact_units_per_order_existing': 'Existing Units Per Order',
      'impact_order_frequency_new': 'New Order Frequency'
    }

    var new_dnd_dict = {'positive0':{}, 'positive1':{},'positive2':{},'negative0':{}, 'negative1':{},'negative2':{}}

    //Get top positive
    for(var i=0;i<3;i++){
      let measure = measures[i]
      measure = pretty_print_measure[measure]
      let value = values[i]
      let yoy = yoys[i]
      if(yoy < 0){
        break
      }
      
      new_dnd_dict['positive'+i]['num'] = i
      new_dnd_dict['positive'+i]['measure'] = measure
      new_dnd_dict['positive'+i]['yoy'] = yoy
      new_dnd_dict['positive'+i]['dollar'] = value

    }

    //Get negative positive
    var negative_count = 0
    for(i=measures.length-1;i>measures.length-4;i--){
      let measure = measures[i]
      //console.log('negative measure: ' + i + '|' + measure)
      measure = pretty_print_measure[measure]
      let value = values[i]
      let yoy = yoys[i]
      if(yoy > 0){
        break
      }
      
      new_dnd_dict['negative'+negative_count]['num'] = i
      new_dnd_dict['negative'+negative_count]['measure'] = measure
      new_dnd_dict['negative'+negative_count]['yoy'] = yoy
      new_dnd_dict['negative'+negative_count]['dollar'] = value
      negative_count ++
    }

    setDndDict({...new_dnd_dict})
    setIsGettingDriversNDrags(false)
  }

  catch(err){
    console.log('get_drivers_n_drags body:', body)
    console.log('get_drivers_n_drags response:' , response)
    throw new Error(err);
  }
  }
  

  //Rendering
  function render_drivers_and_drags(which){
    //console.log('render_drivers_and_drags', dnd_dict.length)
    if(Object.keys(dnd_dict).length === 0){
      var loading = null
      if(which === 'positive'){
        loading = loading_gif('Powering Up Rockets')
      }else{
        loading = loading_gif('Deploying Parachutes')
      }
      return(
        <React.Fragment>
          {loading}
        </React.Fragment>
      )
    }
    else{
      return(
        <React.Fragment>
          {generate_drivers_and_drags(which,0)}
          {generate_drivers_and_drags(which,1)}
          {generate_drivers_and_drags(which,2)}
          <Tooltip id = 'tooltip_dnd_positive0'/>
          <Tooltip id = 'tooltip_dnd_positive1'/>
          <Tooltip id = 'tooltip_dnd_positive2'/>
          <Tooltip id = 'tooltip_dnd_negative0'/>
          <Tooltip id = 'tooltip_dnd_negative1'/>
          <Tooltip id = 'tooltip_dnd_negative2'/>

        </React.Fragment>
      )
    }
  }

    function generate_drivers_and_drags(direction, num){
        var ele = direction + String(num)
        //console.log('ele', ele)
        var dollar = dnd_dict[ele]['dollar']
        if(dollar ===undefined){
          return false
        }

        var measure = dnd_dict[ele]['measure']
        measure = measure.replace('Existing', 'Returning')
        var yoy = parseFloat(dnd_dict[ele]['yoy'])
        yoy = Math.round(yoy*1000)/10//get one decimal
        var yoy_string = ''
        var tooltip_gain_loss = ''
        var arrow = null
        var arrow_alt = null
        if(yoy>0){
          yoy_string = "+" + yoy + '%'
          arrow = arrow_rise
          arrow_alt = 'Up Arrow'
          tooltip_gain_loss = 'gained'
        }
        else{
          yoy_string = yoy + '%'
            arrow = arrow_fall
            arrow_alt = 'Down Arrow'
            tooltip_gain_loss = 'lost'
        }

        var row_arrow = <img alt={arrow_alt} src={btn_right_arrow}/>
        var on_row_click = function(){goto_drivers_and_drags('link_drivers_and_drags_'+direction+'_'+num)}
        if(props.parent_component === 'page_drivers_and_drags'){
          row_arrow = <img alt={arrow_alt} src={btn_down_arrow}/>
          on_row_click=function(){props.change_measure_dropdown({measure})}
        }


/*
        'impact_order_frequency_existing':'',
      'impact_price_per_unit_new': '',
      'impact_units_per_order_new': '',
      'impact_price_per_unit_existing': '',
      'lever_number_of_new': '',
      'impact_retention_existing': '',
      'impact_units_per_order_existing': '',
      'impact_order_frequency_new': ''
      */

        var tooltip_content = 'The dollar/percent change versus last year for <br/>'
        if(measure === 'Existing Order Frequency'){tooltip_content += 'the number of orders placed by returning customers. '}
        else if(measure === 'New Price Per Unit'){tooltip_content += 'the average price per unit by new customers. '}
        else if(measure === 'New Units Per Order'){tooltip_content += 'the number of units per order by new customers. '}
        else if(measure === 'Existing Price Per Unit'){tooltip_content += 'the average price per unit by returning customers. '}
        else if(measure === 'Number of New'){tooltip_content += 'the number of new customers. '}
        else if(measure === 'Retention'){tooltip_content += 'the number of returning customers. '}
        else if(measure === 'Existing Units Per Order'){tooltip_content += 'the average units per order by returning customers. '}
        else if(measure === 'New Order Frequency'){tooltip_content += 'the number of orders placed by returning customers. '}

        tooltip_content += ' Compared to last year, you ' + tooltip_gain_loss + ' ' + yoy_string + ', amounting to a ' + tooltip_gain_loss + ' of ' + format_dollar(Math.round(dollar),false)
        
        console.log('measure: ', measure)
            
        return(
          <React.Fragment>
            <Link id={'link_drivers_and_drags_'+direction+'_'+num} to="/drivers_and_drags" state= {{default_measure: measure}} className="float-start"/>
              <Row className="mb-1 float-start "  role='button'  onClick={on_row_click}>
                <Col  md='auto' lg='auto' className='float-end align-items-center'>
                  <img alt={arrow_alt} src={arrow}/>
                </Col>
                <Col >
                  <Row>
                    <Col>
                      <h6 
                        className="m-0"
                        data-tooltip-id={'tooltip_dnd_'+ele}
                        data-tooltip-html={tooltip_content}
                        data-tooltip-place="top"
                      >
                        <b>{yoy_string}</b> 
                        {measure}
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col><p className="text-muted m-0">Impact {format_dollar(Math.round(dollar),true)}</p></Col>
                  </Row>
                </Col>
                <Col className="float-end align-items-center"  lg='auto'>
                  {row_arrow}
                </Col>
              </Row>
            
            {generate_drivers_and_drags_spacer(direction, num)}

          </React.Fragment> 
          
        )
      }
    
      function generate_drivers_and_drags_spacer(direction, i){
        var do_return = true
        if(i <= 1){
          var ele = direction + String(i+1)
          var next_val = dnd_dict[ele]['yoy']
          if(next_val === undefined){
            do_return = false
          }
        }
        else{
          do_return = false
        }
    
        if(do_return){
          return(
            <React.Fragment>
              <Row className="row-spacer bg-secondary opacity-25 mb-1"></Row>
            </React.Fragment>
          )
        }
        else{
          return false
        }
      }

  

  //onClick
  function goto_drivers_and_drags(id){
    
    var ele = document.getElementById(id)
    if(ele != null){
      ele.click()
    }
    
  }


  return (
    <React.Fragment>
      <Col className="card px-5 float-start me-5" lg={5}>
        <Row className="">
          <Col>
            <p className="display-6">Revenue Drivers</p>
          </Col>
        </Row> 
        {render_drivers_and_drags('positive')}
      </Col>
      <Col className="card px-5 float-start" lg={5}>
        <Row>
          <Col>
            <p className="display-6">Revenue Drags</p>
          </Col>
        </Row>
        {render_drivers_and_drags('negative')}
      </Col>
    </React.Fragment>  
  )
    

}


