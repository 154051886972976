
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import { flushSync } from 'react-dom';


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  


export const  PageShopifyApis = (props) =>{

const [shopifyApis, setShopifyApis] = useState(null)


//Insert Modal
const [openAddModal, setOpenAddModal] = useState(null)
const [addModalWarning, setAddModalWarning] = useState('')

  //Insert
  const [sendShopifyInsertApi, setSendShopifyInsertApi] = useState(null)
  const [didSendSaveShopifyID, setDidSendSaveShopifyID] = useState(null)


// Edit Modal
const [editShopifyApiId, setEditShopifyApiId] = useState(null)

  //Update
  const [sendShopifyUpdateApi, setSendShopifyUpdateApi] = useState(null)
  const [didSendShopifyUpdateApi, setDidSendShopifyUpdateApi] = useState(null)

  //Test
  const [sendShopifyTestApi, setSendShopifyTestApi] = useState(null)
  const [didSendShopifyTestApi, setDidSendShopifyTestApi] = useState(null)
  const [shopifyTestMessage, setShopifyTestMessage] = useState(null)

  //Remove
  const [sendShopifyRemoveApi, setSendShopifyRemoveApi] = useState(null)
  const [didSendShopifyRemoveApi, setDidSendShopifyRemoveApi] = useState(null)

  

  //Check job_status
  useEffect(() => {
    if(shopifyApis === null){
        get_shopify_apis()
    }
    //Insert   
    else if(sendShopifyInsertApi && !didSendSaveShopifyID){
      insert_shopify_api()
      setDidSendSaveShopifyID(true)
    } 
    //Remove
    else if(sendShopifyRemoveApi !== null && !didSendShopifyRemoveApi){
        remove_shopify_api()
        setDidSendShopifyRemoveApi(true)
    }
    //Update
    else if(sendShopifyUpdateApi && !didSendShopifyUpdateApi){
        update_shopify_api()
        setDidSendShopifyUpdateApi(true)
    }
    //Test
    else if(sendShopifyTestApi && !didSendShopifyTestApi){
      test_shopify_api()
      setDidSendShopifyTestApi(true)

    }
    else if(sendShopifyTestApi === null && didSendShopifyTestApi === null){//For some reason the flushsync on close of the modal wasn't flushing shopifyTestMessage prior to re-render. So I put it here and it seems to work.
      setShopifyTestMessage(null)
    }

    
      

  })


  //Api Calls
  async function get_shopify_apis(){
    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'shopify_apis',
        func:"get_shopify_apis"
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      flushSync(
        setShopifyApis(response)
      )

    } 
    catch(err){
      console.log('get_shopify_apis body', body)
      console.log('get_shopify_apis response', response)
      throw new Error(err)
    }
  }

  async function insert_shopify_api(){

    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'shopify_apis',
        func:"insert_shopify_api",
        name: get_val('inp_name'),
        url: get_val('inp_url'),
        access_token: get_val('inp_access_token'),
        api_key: get_val('inp_api_key'),
        api_secret_key: get_val('inp_secret_api_key'),
        tags: []
      }
      response = await send_request(body)
      response = JSON.parse(response)
      

      flushSync(
        setSendShopifyInsertApi(null),
        setShopifyApis(response),
        setOpenAddModal(null),
        setDidSendSaveShopifyID(null)
      )

      
    } 
    catch(err){
      console.log('save_shopify_api body', body)
      console.log('save_shopify_api response', response)
      throw new Error(err)
    }
  }

  async function remove_shopify_api(){
    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'shopify_apis',
        func:"remove_shopify_api",
        id: editShopifyApiId
      }
      
      response = await send_request(body)
      response = JSON.parse(response)

      
      flushSync(
        setSendShopifyRemoveApi(null),
        setShopifyApis(response),
        setEditShopifyApiId(null),
        setDidSendShopifyRemoveApi(null),
        
        setSendShopifyTestApi(null),
        setDidSendShopifyTestApi(null)
      )

      
    } 
    catch(err){
      console.log('remove_shopify_api body', body)
      console.log('remove_shopify_api response', response)
      throw new Error(err)
    }
  }

  async function update_shopify_api(){
    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'shopify_apis',
        func:"update_shopify_api",
        name: get_val('inp_name'),
        url: get_val('inp_url'),
        access_token: get_val('inp_access_token'),
        api_key: get_val('inp_api_key'),
        api_secret_key: get_val('inp_secret_api_key'),
        tags: [],
        id: editShopifyApiId
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      flushSync(
        setShopifyApis(response),
        setDidSendShopifyUpdateApi(null),
        setSendShopifyUpdateApi(null),
        setEditShopifyApiId(null),
        setSendShopifyTestApi(null),
        setDidSendShopifyTestApi(null)
      )

    } 
    catch(err){
      console.log('update_shopify_api body', body)
      console.log('update_shopify_api response', response)
      throw new Error(err)
    }
  }

  async function test_shopify_api(){
    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'shopify_apis',
        func:"test_shopify_api",
        id: editShopifyApiId
      }
      response = await send_request(body)
      var message = ''
      if(Object.keys(response).includes('success')){
        if(response['success']){
          message = 'Api Connected!'
        }else{
          message = 'Test Failed'
        }
      }
      else{
        message = 'Error'
      }

      flushSync(
        setShopifyTestMessage(message),
        //setDidSendShopifyTestApi(null) // This does not need to be here like the others since it is unset when closing the modal 
        //setSendShopifyUpdateApi // This does not need to be here like the others since it is unset when closing the modal
      )


    } 
    catch(err){
      console.log('test_shopify_api body', body)
      console.log('test_shopify_api response', response)
      throw new Error(err)
    }
  }


  //Rendering
  //Main Table
  function render_shopify_apis(){
    if(shopifyApis === null){
      return(loading_gif('Loading Shopify Apis'))
    }

    var rows = []
    
    for(var i=0;i<shopifyApis.length;i++){
      
      var min_order_date = new Date(shopifyApis[i]['min_order_date']);
      min_order_date = min_order_date.getFullYear() + '-' + min_order_date.getMonth() + '-' + min_order_date.getDate() + ' ' + min_order_date.getHours() + ':' + min_order_date.getMinutes()

      var max_order_date = new Date(shopifyApis[i]['max_order_date']);
      max_order_date = max_order_date.getFullYear() + '-' + max_order_date.getMonth() + '-' + max_order_date.getDate() + ' ' + max_order_date.getHours() + ':' + max_order_date.getMinutes()

      var t_ele = 
        <React.Fragment>
            <Row>
                <Col>
                    {shopifyApis[i]['name']}
                </Col>
                <Col>
                    {shopifyApis[i]['co_orders']}
                </Col>
                <Col>
                    {min_order_date}
                </Col>
                <Col>
                    {max_order_date}
                </Col>
                <Col>
                    <h6 className='hover_underline' shopify_api_id={parseInt(shopifyApis[i]['id'])} onClick={set_edit_shopify_api_id}>More</h6>
                </Col>
            </Row>
        </React.Fragment>

      rows.push(t_ele)

    }

      function set_edit_shopify_api_id(e){
        setEditShopifyApiId(parseInt(e.target.getAttribute('shopify_api_id')))
      }

    


    return(
      <React.Fragment>

        <Row>
            <Col>
                <h5>Name</h5>
            </Col>
            <Col>
                <h5># Orders</h5>
            </Col>
            <Col>
                <h5>Last Order</h5>
            </Col>
            <Col>
                <h5>First Order</h5>
            </Col>
            <Col>&nbsp;</Col>
        </Row>
        {rows}
        <Row>
            <Col>
                <h6 className='hover_underline' onClick={() => {setOpenAddModal(true)}}>Add New +</h6>
            </Col>
        </Row>
      </React.Fragment>
    )

    

  }


  //Add New
  function render_add_modal(){
    var button = sendShopifyInsertApi ? loading_gif('Saving') :<button onClick={() => {setSendShopifyInsertApi(true)}} className='btn btn-lg btn-warning mt-3'>Save</button>

    var modal_warning = addModalWarning === '' ? '' : <h6>{addModalWarning}</h6>

    return(
        <React.Fragment>
            <Modal
                isOpen={openAddModal}
                onRequestClose={set_close_add_modal}
                style={customStyles}
                >
                    <Row>
                        <Col>
                            {modal_warning}
                        </Col>
                    </Row>
                    <Row>
                        <Col>Name</Col>
                        <Col><input id='inp_name'  type='text'/></Col>
                        </Row>
                    <Row className='mt-2'>
                        <Col>Url</Col>
                        <Col><input id='inp_url'/></Col>
                    </Row>
                    <Row className='mt-2'>
                      <Col>Access Token</Col>
                      <Col><input id='inp_access_token'/></Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>Api Key</Col>
                        <Col><input id='inp_api_key'/></Col>
                    </Row>
                    <Row className='mt-2'>
                    <Col>Secret Api Key</Col>
                        <Col><input id='inp_secret_api_key'/></Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>&nbsp;</Col>
                        <Col>
                            {button}
                        </Col>
                    </Row>
                
                </Modal>
        </React.Fragment>
    )


  }


    function set_close_add_modal(){
      flushSync(
          setOpenAddModal(false),
          setSendShopifyInsertApi(false)
      )
    }


  //Edit Modal
  function render_edit_modal(){
    
    if(editShopifyApiId === null){
      return
    }


    var api = {}
    for(var i = 0;i<shopifyApis.length;i++){
      if(shopifyApis[i]['id'] == editShopifyApiId){
        api = shopifyApis[i]
        break
      }
    }

    var updating_button = sendShopifyUpdateApi ? loading_gif('Updating') :<button onClick={() => {setSendShopifyUpdateApi(true)}} className='btn btn-lg btn-warning mt-3'>Update</button>

    var test_button = sendShopifyTestApi ? loading_gif('Testing') :<button onClick={() => {setSendShopifyTestApi(true)}} className='btn btn-lg btn-warning mt-3'>Test</button>
    
    if(shopifyTestMessage !== null){
      if(shopifyTestMessage == 'Api Connected!'){
        test_button = <h6 className='mt-4'>{shopifyTestMessage}</h6>
      }
      else{
        test_button = <h6 className='text-danger mt-4'>{shopifyTestMessage}</h6>
      }
      
    }

    var remove_button = didSendShopifyRemoveApi ? loading_gif('Removing') :<button onClick={() => {setSendShopifyRemoveApi(true)}} className='btn btn-lg btn-warning mt-3'>Remove</button>

    
    

    return(
        <React.Fragment>
            <Modal
                isOpen={true}
                onRequestClose={set_close_edit_modal}
                style={customStyles}
                >
                    
                    <Row className='ms-3 me-3'>
                        <Col>Name</Col>
                        <Col><input id='inp_name'  type='text' defaultValue={api['name']}/></Col>
                    </Row>
                    <Row className='mt-2 ms-3 me-3'>
                        <Col>Url</Col>
                        <Col><input id='inp_url' defaultValue={api['url']}/></Col>
                    </Row>
                    <Row className='mt-2 ms-3 me-3'>
                      <Col>Access Token</Col>
                      <Col><input id='inp_access_token' defaultValue={api['access_token']}/></Col>
                    </Row>
                    <Row className='mt-2 ms-3 me-3'>
                        <Col>Api Key</Col>
                        <Col><input id='inp_api_key' defaultValue={api['api_key']}/></Col>
                    </Row>
                    <Row className='mt-2 ms-3 me-3'>
                    <Col>Secret Api Key</Col>
                        <Col><input id='inp_secret_api_key' defaultValue={'...****'}/></Col>
                    </Row>
                    <Row className='mt-2 ms-3 me-3'>
                        <Col>{test_button}</Col>
                        <Col>{updating_button}</Col>
                        <Col>{remove_button}</Col>
                    </Row>
                
                </Modal>
        </React.Fragment>
    )


  }

  function set_close_edit_modal(){
    flushSync(
      setEditShopifyApiId(null),
      setSendShopifyUpdateApi(false),
      setSendShopifyTestApi(null),
      setDidSendShopifyTestApi(null)
    )
  }


  //Output
  return(
    <Container>
        <Row>
          <Col lg ="auto">
            <h1>Shopify Apis</h1>
          </Col>
        </Row>

        {render_shopify_apis()}
        
        {render_add_modal()}

        {render_edit_modal()}
        
        
    </Container>
  )
    
    
  


 }

  
    