import React from "react";
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from "@aws-amplify/ui-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Amplify} from "aws-amplify";
import { get_ele, get_val } from "../globals/globals";
import { useState, useEffect } from "react";
import { flushSync } from "react-dom";
import { Auth } from 'aws-amplify';
import loading_gif from "../globals/loading_gif";


export const  PageLogin = (props) =>{

    //Login
    const [emailRequired, setEmailRequired] = useState(false)
    const [passwordRequired, setPasswordRequired] = useState(false)
    const [btnLoggingIn, setBtnLoggingIn] = useState(false)

    //Change Password
    const [changePassword, setChangePassword] = useState(false)
    const [comfirmPasswordRequired, setConfirmPasswordRequired] = useState(false)
    const [newPasswordRequired, setNewPasswordRequired] = useState(false)
    const [passwordMismatch, setPasswordMismatch] = useState(false)
    const [invalidPasswordException, setInvalidPasswordException] = useState(false)
    const [btnChangePassword, setBtnChangePassword] = useState(false)

    //Reset Password
    const [showForgotPassword, setForgotPassword] = useState(false)
    const [unverifiedEmail, setUnverifiedEmail] = useState(false)
    const [sentResetCode, setSentResetCode] = useState(false)
    const [resetCodeRequired, setResetCodeRequired] = useState(false)
    const [btnGetForgottenPasswordCode, setBtnGetForgottenPasswordCode] = useState(false)

    
    //Auth
    const [authCode, setAuthCode] = useState('')
    const [user, setUser] = useState()

    useEffect(() => {
        if(btnLoggingIn){
            attempt_login(undefined)
        }
        else if(btnGetForgottenPasswordCode){
            send_forgot_password_code()
        }
        else if(btnChangePassword){
            if(sentResetCode){
                change_password_with_reset_code()
            }
            else{//Regular password change
                change_password()
            }
            
        }

      })


    //Calls to Cognito
    function attempt_login(new_password){
        try{
            var password = new_password === undefined ? get_val('password') : new_password
        }
        catch(e){
            return
        }
        var email = get_val('email')

        var valid_email = email.length > 0
        var valid_password = password.length > 0

        
        if(!valid_email || !valid_password){
            flushSync(
                setEmailRequired(!valid_email),
                setPasswordRequired(!valid_password),
                setAuthCode(''),
                setNewPasswordRequired(false),
                setUser(),
                setBtnLoggingIn(false)
            )
        }
        else{//neither length is 0
            Auth.signIn(email, password)
            .then((user) => {
                console.log('user: ', user)
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    flushSync(
                        setEmailRequired(false),
                        setPasswordRequired(false),
                        setAuthCode(''),
                        setChangePassword(true),
                        setUser(user),
                        setBtnLoggingIn(false)
                    )
                } else {
                    window.localStorage.setItem('authState', 'signedin')
                    props.setAuthState('signedin') //Used to cause re-render

                // other situations
                }
            })
            .catch((e) => {
                if(Object.keys(e).includes('code')){
                    var code = e['code']
                    console.log('e-code:', e)
                    if(['UserNotFoundException', "NotAuthorizedException"].includes(code)){
                        flushSync(
                            setEmailRequired(false),
                            setPasswordRequired(false),
                            setAuthCode('user_or_password_incorrect'),
                            setNewPasswordRequired(false),
                            setUser(),
                            setBtnLoggingIn(false)
                        )
                    }
                    else{
                        console.log('Unhandled error', e);
                    }
                }
                else{
                    console.log('Unhandled error', e);
                }
                
                
            });
        }


    }

    function change_password(){
        var password = get_val('new_password')
        var valid_password = password.length > 0

        var new_password = get_val('confirm_password')
        var valid_new_password = new_password.length > 0

        
        if(!valid_password || !valid_new_password){
            flushSync(
                setPasswordRequired(!valid_password),
                setConfirmPasswordRequired(!valid_new_password),
                setPasswordMismatch(false),
                setInvalidPasswordException(false),
                setBtnChangePassword(false)
            )
        }
        else if(password !== new_password){
            flushSync(
                setPasswordRequired(!valid_password),
                setConfirmPasswordRequired(!valid_new_password),
                setPasswordMismatch(true),
                setInvalidPasswordException(false),
                setBtnChangePassword(false)
            )
        }
        else{
            Auth.completeNewPassword(
                user, // the Cognito User Object
                new_password, // the new password
              )
                .then((user) => {
                    debugger
                  // at this time the user is logged in if no MFA required
                  props.setAuthState('signedin')
                })
                .catch((e) => {
                    debugger
                    if(Object.keys(e).includes('code')){
                        if(e['code'] == "InvalidPasswordException"){
                            flushSync(
                                setPasswordRequired(!valid_password),
                                setConfirmPasswordRequired(!valid_new_password),
                                setPasswordMismatch(false),
                                setInvalidPasswordException(true),
                                setBtnChangePassword(false)
                            )
                        }
                    }
                    else{
                        console.log('unhandled error: ', e);
                    }
                });

        }

    }

    function change_password_with_reset_code(){
        var email = get_val('email')
        var valid_email = email.length > 0

        var code = get_val('reset_code')
        var valid_reset_code = code.length > 0

        var password = get_val('new_password')
        var valid_password = password.length > 0

        var new_password = get_val('confirm_password')
        var valid_new_password = new_password.length > 0

        
        if(!valid_password || !valid_new_password || !valid_email || !valid_reset_code){
            flushSync(
                setPasswordRequired(!valid_password),
                setConfirmPasswordRequired(!valid_new_password),
                setEmailRequired(!valid_email),
                setResetCodeRequired(!valid_reset_code),
                setPasswordMismatch(false),
                setInvalidPasswordException(false),
                setBtnChangePassword(false)
            )
        }
        else if(password !== new_password){
            flushSync(
                setPasswordRequired(!valid_password),
                setConfirmPasswordRequired(!valid_new_password),
                setEmailRequired(!valid_email),
                setResetCodeRequired(!valid_reset_code),
                setPasswordMismatch(true),
                setInvalidPasswordException(false),
                setBtnChangePassword(false)
            )
        }
        else{
            Auth.forgotPasswordSubmit(email, code, new_password)
                .then((user) => {
                    debugger
                  // at this time the user is logged in if no MFA required
                  attempt_login(new_password)
                })
                .catch((e) => {
                    if(Object.keys(e).includes('code')){
                        if(e['code'] == "InvalidPasswordException"){
                            flushSync(
                                setPasswordRequired(!valid_password),
                                setConfirmPasswordRequired(!valid_new_password),
                                setEmailRequired(!valid_email),
                                setResetCodeRequired(!valid_reset_code),
                                setPasswordMismatch(false),
                                setInvalidPasswordException(true),
                                setBtnChangePassword(false)
                            )
                        }
                    }
                    else{
                        console.log('unhandled error: ', e);
                    }
                  
                });

        }

    }

    function send_forgot_password_code(){
        var email = get_val('email')
        var valid_email = email.length > 0

        if(!valid_email){
            flushSync(
                setEmailRequired(true),
                setBtnGetForgottenPasswordCode(false)
            )
        }
        else{
            Auth.forgotPassword(email)
            .then((data) => {
                flushSync(
                    setSentResetCode(true),
                    setBtnGetForgottenPasswordCode(false)
                )
            }
            )
            .catch((err) => {
                if(Object.keys(err).includes('message')){
                    if(err['message'] === 'Cannot reset password for the user as there is no registered/verified email or phone_number'){
                        flushSync(
                            setUnverifiedEmail(true),
                            setBtnGetForgottenPasswordCode(false)
                        )
                    }
                }else{
                    console.log('unhandled error: ', err)
                }
                
                
            })
        }

        
    }



    
    function render_body(){
        var form = ''
        
        if(changePassword || sentResetCode){
            form = render_change_password()
        }
        else if(showForgotPassword){
            form = render_forgot_password()
        }
        else{
            form = render_login()
        }



        return (
            <React.Fragment>
                <Row style={{}} className="card p-4">
                    {form}
                </Row>
            </React.Fragment>
          );
    }

        function render_login(){
            
            var password_required = passwordRequired ? <h6 className="float-start text-danger" id='password_required'>&nbsp;Required</h6> : ''
            var email_required = emailRequired ? <h6 className='float-start text-danger' id='email_required'>&nbsp;Required</h6> : ''
            var user_or_password_incorrect = authCode === 'user_or_password_incorrect' ? <h6 className='text-danger' id='user_or_password_incorrect'>Email or password is incorrect</h6> : ''

            var btn_login = btnLoggingIn ? loading_gif('Logging In') : <button onClick={set_logging_in} id='btn_login' className="btn btn-success">Login</button>

            return(
                <React.Fragment>
                    <Row>
                        <Col>
                            <h4>Equinauts</h4>
                            {user_or_password_incorrect}
                        </Col>
                    </Row>
                    
                    <Row className="mt-3">
                        <Col>
                            <h6 className="float-start">Email:</h6>
                            {email_required}
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input id='email'  key='email' type="text" className=" form-control"/>
                        </Col>
                    </Row>
                        
                        
                    <Row className="mt-3">
                        <Col>
                            <h6 className="float-start">Password:</h6>
                            {password_required}
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input id='password'  key='password' type="password"  className=" form-control"/>
                        </Col>
                    </Row>
                    

                    <Row>
                        <Col>
                            <p class="hover_underline" onClick={goto_forgot_password}>Forgot Password?</p>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            {btn_login}
                        </Col>
                    </Row>
                </React.Fragment>
            )

        }

            //Button Function
            function goto_forgot_password(){
                setForgotPassword(true)
            }

            function set_logging_in(){
                setBtnLoggingIn(true)
            }



        function render_forgot_password(){

            var email_required = emailRequired ? <h6 className='float-start text-danger' id='email_required'>&nbsp;Required</h6> : ''

            var btn = btnGetForgottenPasswordCode ? loading_gif('Sending Code') : <button onClick={set_send_reset_code} id='btn_forgot_password' className="btn btn-success">Reset Password</button>

            var fields = unverifiedEmail ? 
                <React.Fragment>
                    <Row>
                        <Col>
                            <h4>Equinauts Login</h4>
                            <h5>Your email has not been verified. Please contact Equinauts for help resetting your password.</h5>
                        </Col>
                    </Row>
                </React.Fragment>
                :
                <React.Fragment>
                    <Row>
                        <Col>
                            <h4>Equinauts Login</h4>
                            <h5>An email will be sent with instructions to reset your password.</h5>
                        </Col>
                    </Row>
                    
                    <Row className="mt-3">
                        <Col>
                            <h6 className="float-start">Email:</h6>
                            {email_required}
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input id='email'  key='email' type="text"  className=" form-control"/>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            {btn}
                        </Col>
                    </Row>
                </React.Fragment>

            return(
                <React.Fragment>
                    {fields}
                </React.Fragment>
            )

        }

            function set_send_reset_code(){
                setBtnGetForgottenPasswordCode(true)
            }

        function render_change_password(){
            //Change Password
            var title_password_mismatch = passwordMismatch ? <h6 className="text-danger">Passwords do not match</h6> : ''

            var invalid_password = invalidPasswordException ? <h6 className="text-danger">Invalid Password. Required: Minimum Length 8. Minimum 1 Symbol.  Minimum 1 Number.</h6> : ''
            
            var new_password_required = newPasswordRequired ?   <h6 className="text-danger">&nbsp;Required</h6> : ''
            var confirm_password_required = comfirmPasswordRequired ?   <h6 className="text-danger">&nbsp;Required</h6> : ''
            
            var reset_code_required = resetCodeRequired ? <h6 className="text-danger">&nbsp;Required</h6> : ''
            
            var email_required = emailRequired ? <h6 className='float-start text-danger' id='email_required'>&nbsp;Required</h6> : ''

            var reset_password_code = sentResetCode ? 
            <React.Fragment>
                <Row className="mt-3">
                    <Col>
                        <h5>Check your email for reset code.</h5>
                        
                        <h6 className="float-start">Email:</h6>
                        {email_required}
                        
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <input id='email'  key='email' type="text" className=" form-control"/>
                    </Col>
                </Row>

                <Row className="mt-3">
                        <Col>
                            <h6 className="float-start">Reset Code:</h6>
                            {reset_code_required}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input id='reset_code' key='reset_code' type="text" className=" form-control"/>
                        </Col>
                    </Row>
            </React.Fragment>
            : <h6>You are required to change your password</h6>


            var button = btnChangePassword ?
                loading_gif('Changing Password')
                :
                <button onClick={set_change_password_btn} id='btn_change_password' className="btn btn-success">Change Password</button>
            

            return(
                <React.Fragment>
                    <Row>
                        <Col>
                            <h4>Equinauts Login</h4>
                            {invalid_password}
                            {title_password_mismatch}
                        </Col>
                    </Row>
                    {reset_password_code}

                    <Row className="mt-3">
                        <Col>
                            <h6 className="float-start">New Password:</h6>
                            {new_password_required}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input id='new_password' key='new_password' type="password" className=" form-control"/>
                        </Col>
                    </Row>


                    <Row className="mt-3">
                        <Col>
                            <h6 className="float-start">Confirm Password:</h6>
                            {confirm_password_required}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input id='confirm_password'  key='confirm_password' type="password" className=" form-control"/>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            {button}
                        </Col>
                    </Row>
                </React.Fragment>
            )
        }


            function set_change_password_btn(){
                setBtnChangePassword(true)
            }









    



    return (
        <React.Fragment>
            <Row className="" style={{'flex-direction':'column'}}>
                <Col style={{width:"500px", 'margin-left': 'auto', 'margin-right': 'auto'}} className="mt-5">
                    {render_body()}     
                </Col>
            </Row>
            
        </React.Fragment>
        );

}



{/*
        <React.Fragment>
          
          <AmplifyAuthenticator  usernameAlias="email">
              <AmplifySignIn
                handleAuthStateChange={props.handleAuthStateChange} 
                slot="sign-in"
                headerText="Sign in to your Equinauts account"
                usernameAlias="email"
                hideSignUp
              ></AmplifySignIn>
              
          </AmplifyAuthenticator> 
         
          
        </React.Fragment>
        */}