
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';


export const  PageAdSpend = (props) =>{

const [adSpend, setAdSpend] = useState(null)
const [companySlices, setCompanySlices] = useState(null)
const [adSpendIDToRemove, setAdSpendIDToRemove] = useState(null)




//Bulk Upload

    

  //Check job_status
  useEffect(() => {
    if(adSpend === null){
        get_ad_spend()
    }    
    else if(adSpendIDToRemove !== null){
        remove_ad_spend()
    }

  })


  //Api Calls
  async function get_ad_spend(){
    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'ad_spend',
        func:"get_ad_spend"
      }
      
      response = await send_request(body)
      
      var ad_spend = JSON.parse(response['ad_spend'])
      var t_slices = JSON.parse(response['slices'])['id']
      
      flushSync(
        setAdSpend(ad_spend),
        setCompanySlices(t_slices)
      )

      
    } 
    catch(err){
      console.log('get_ad_spend body', body)
      console.log('get_ad_spend response', response)
      throw new Error(err)
    }
  }

  async function remove_ad_spend(){
    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'ad_spend',
        func:"remove_ad_spend",
        id: adSpendIDToRemove
      }
      
      response = await send_request(body)
      
      var ad_spend = JSON.parse(response['ad_spend'])
      var t_slices = JSON.parse(response['slices'])['id']
      
      flushSync(
        setAdSpend(ad_spend),
        setCompanySlices(t_slices),
        setAdSpendIDToRemove(null)
      )

      
    } 
    catch(err){
      console.log('get_ad_spend body', body)
      console.log('get_ad_spend response', response)
      throw new Error(err)
    }
  }

  function set_remove_ad_spend(e){
    setAdSpendIDToRemove(parseInt(e.target.getAttribute('ad_spend_id')))
  }


  function render_ad_spend(){
    if(adSpend === null || companySlices === null){
      return(loading_gif('Loading Ad Spend'))
    }
    else if(adSpendIDToRemove !== null){
        return(loading_gif('Removing Ad Spend'))
    }




    var rows = []
    
    for(var i=0;i<adSpend.length;i++){
      var date = adSpend[i]['date']  
      var split_date = date.split('-')
      var display_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
      var amount = adSpend[i]['amount']
      var currency = adSpend[i]['currency']
      var converted_ad_spend = adSpend[i]['converted_ad_spend']
      var platform = adSpend[i]['platform']
      var slice_ids = JSON.parse(adSpend[i]['slice_id'])

      for(var j=0;j<slice_ids.length;j++){
        var slice_id = parseInt(slice_ids[j])
        
        var slice_label = ''
        for(var k=0;k<Object.keys(companySlices).length; k++){
            var slice_name = Object.keys(companySlices)[k]
            if(companySlices[slice_name] == slice_id){
                slice_label += slice_name + ', '
                break
            }
        }
        slice_label = slice_label.substring(0, slice_label.length - 2);

      }


      var t_ele = 
        <React.Fragment>
            <Row>
                <Col>
                    {date}
                </Col>
                <Col>
                    {format_dollar(amount, false)}
                </Col>
                <Col>
                    {currency}
                </Col>
                <Col>
                    {format_dollar(converted_ad_spend, false)}
                </Col>
                <Col>
                    {platform}
                </Col>
                <Col>
                    {slice_label}
                </Col>
                <Col>
                    <h6 className='hover_underline' onClick={set_remove_ad_spend} ad_spend_id={adSpend[i]['id']}>Remove</h6>
                </Col>
            </Row>
        </React.Fragment>

      rows.push(t_ele)

    }
    


    return(
      <React.Fragment>
        <Row>
            <Col>
                <h5>Date</h5>
            </Col>
            <Col>
                <h5>Amount</h5>
            </Col>
            <Col>
                <h5>Currency</h5>
            </Col>
            <Col>
                <h5>Converted</h5>
            </Col>
            <Col>
                <h5>Platform</h5>
            </Col>
            <Col>
                <h5>Slice IDs</h5>
            </Col>
            <Col>
                &nbsp;
            </Col>
        </Row>
        {rows}
      </React.Fragment>
    )

    

  }






  //Output
  return(
    <Container>
        <Row className='mb-3'>
          <Col lg ="auto">
            <h1>Ad Spend</h1>
          </Col>
          <Col lg={1}>&nbsp;</Col>
          <Col>
            <Link to='upload_ad_spend'><button className='btn btn-lg btn-warning'>Upload</button></Link>
          </Col>
        </Row>
        

        {render_ad_spend()}
      </Container>
        
  )
    
    
  


 }

  
    