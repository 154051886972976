
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';
import Calendar from 'react-calendar'

import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  


export const  PageFXRates = (props) =>{

const [fxRates, setFXRates] = useState(null)
const [fxRateIDToRemove, setFXRateIDToRemove] = useState(null)

//Modal
const [openAddModal, setOpenAddModal] = useState(false)
const [saveFXRate, setSaveFXRate] = useState(false)
const [addModalWarning, setAddModalWarning] = useState('')

  //Check job_status
  useEffect(() => {
    if(fxRates === null){
        get_fx_rates()
    }    
    else if(fxRateIDToRemove !== null){
        remove_fx_rate()
    }
    else if(saveFXRate){
        save_fx_rate()
    }

  })


  //Api Calls
  async function get_fx_rates(){
    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'fx_rates',
        func:"get_fx_rates"
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      flushSync(
        setFXRates(response)
      )

    } 
    catch(err){
      console.log('get_fx_rates body', body)
      console.log('get_fx_rates response', response)
      throw new Error(err)
    }
  }

  async function remove_fx_rate(){
    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'fx_rates',
        func:"remove_fx_rate",
        id: fxRateIDToRemove
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      debugger

      flushSync(
        setFXRateIDToRemove(null),
        setFXRates(response)
      )

      
    } 
    catch(err){
      console.log('remove_fx_rate body', body)
      console.log('remove_fx_rate response', response)
      throw new Error(err)
    }
  }


  async function save_fx_rate(){

    debugger
    var calendar_type = get_val('inp_calendar_type')
    var date = get_val('inp_date')
    var from_cur = get_val('inp_from_cur')
    var to_cur = get_val('inp_to_cur')
    var amount = parseFloat(get_val('inp_rate'))


    if(from_cur === to_cur){
        flushSync(
            setAddModalWarning('To currency and from currency can\'t be the same'),
            setSaveFXRate(false)
        )
    }
    else if(amount === 0){
        flushSync(
            setAddModalWarning('Amount Cannot be 0.'),
            setSaveFXRate(false)
        )
    }



    var body = ''
    var response = ''
    try{
      //Put together the body
      body = { 
        path:'fx_rates',
        func:"add_fx_rate",
        calendar_type: calendar_type,
        date: date,
        from_cur: from_cur,
        to_cur: to_cur,
        amount: amount 
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      debugger

      flushSync(
        setSaveFXRate(false),
        setFXRates(response)
      )

      
    } 
    catch(err){
      console.log('save_fx_rate body', body)
      console.log('save_fx_rate response', response)
      throw new Error(err)
    }
  }

  function set_remove_fx_rate(e){
    setFXRateIDToRemove(parseInt(e.target.getAttribute('fx_rate_id')))
  }



  function render_fx_rates(){
    if(fxRates === null){
      return(loading_gif('Loading FX Rates'))
    }
    else if(fxRateIDToRemove !== null){
        return(loading_gif('Removing FX Rate'))
    }




    var rows = []
    
    for(var i=0;i<fxRates.length;i++){
      var date = fxRates[i]['date']  
      var split_date = date.split('-')
      var display_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
     
      if(fxRates[i]['calendar_type'] == 'year_end_445'){var calendar_type = 'Year Ending:'}
      else if(fxRates[i]['calendar_type'] == 'quarter_end_445'){var calendar_type = 'Quarter Ending: '}
      else if(fxRates[i]['calendar_type'] == 'month_end_445'){var calendar_type = 'Month Ending: '}
      else if(fxRates[i]['calendar_type'] == 'week_end_445'){var calendar_type = 'Week Ending: '}
      
                

      var t_ele = 
        <React.Fragment>
            <Row>
                <Col>
                    <p className='text-end'>{calendar_type}</p>
                </Col>
                <Col>
                    {display_date}
                </Col>
                <Col>
                    {fxRates[i]['from_cur']}
                </Col>
                <Col>
                    {fxRates[i]['to_cur']}
                </Col>
                <Col>
                    {fxRates[i]['amount']}
                </Col>
                
                
                <Col>
                    <h6 className='hover_underline' onClick={set_remove_fx_rate} fx_rate_id={fxRates[i]['id']}>Remove</h6>
                </Col>
            </Row>
        </React.Fragment>

      rows.push(t_ele)

    }

    


    return(
      <React.Fragment>

        <Row>
            <Col>
                &nbsp;
            </Col>
            <Col>
                <h5>Date</h5>
            </Col>
            <Col>
                <h5>From Currency</h5>
            </Col>
            <Col>
                <h5>To Currency</h5>
            </Col>
            <Col>
                <h5>Amount</h5>
            </Col>
            <Col>
                &nbsp;
            </Col>
        </Row>
        {rows}
        <Row>
            <Col>
                <h6 className='hover_underline' onClick={set_open_add_modal}>Add New +</h6>
            </Col>
        </Row>
      </React.Fragment>
    )

    

  }



  function set_open_add_modal(){
    setOpenAddModal(true)
  }

  function set_close_add_modal(){
    flushSync(
        setOpenAddModal(false),
        setSaveFXRate(false)
    )
  }

  function set_save_fx_rate(){
    setSaveFXRate(true)
  }


  function render_add_modal(){
    var button = saveFXRate ? loading_gif('Saving') :<button onClick={set_save_fx_rate} className='btn btn-lg btn-warning mt-3'>Save</button>

    var modal_warning = addModalWarning === '' ? '' : <h6>{addModalWarning}</h6>

    return(
        <React.Fragment>
            <Modal
                isOpen={openAddModal}
                onRequestClose={set_close_add_modal}
                style={customStyles}
                >
                <Row>
                    <Row>
                        <Col>
                            {modal_warning}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Date End Type
                        </Col>
                        <Col>
                            Date
                        </Col>
                        <Col>
                            From Currency
                        </Col>
                        <Col>
                            To Currency
                        </Col>
                        <Col>
                            Rate
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <select id='inp_calendar_type'>
                                <option value='week_end_445'>Week End</option>
                                <option value='month_end_445'>Month End</option>
                                <option value='quarter_end_445'>Quarter End</option>
                                <option value='year_end_445'>Year End</option>
                            </select>
                        </Col>
                        <Col>
                            <input id='inp_date' type='date'/>
                        </Col>
                        <Col>
                            <select id='inp_from_cur'>
                                <option value='USD'>USD</option>
                                <option value='CAD'>CAD</option>
                            </select>
                        </Col>
                        <Col>
                            <select id='inp_to_cur'>
                                <option value='USD'>USD</option>
                                <option value='CAD'>CAD</option>
                            </select>
                        </Col>
                        <Col>
                            <input id='inp_rate' type='number'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>&nbsp;</Col>
                        <Col>&nbsp;</Col>
                        <Col>&nbsp;</Col>
                        <Col>&nbsp;</Col>
                        <Col>
                            {button}
                        </Col>
                    </Row>
                </Row>
                </Modal>
        </React.Fragment>
    )


  }


  //Output
  return(
    <Container>
        <Row>
          <Col lg ="auto">
            <h1>FX Rates</h1>
          </Col>
        </Row>

        {render_fx_rates()}
        
        {render_add_modal()}
        
        
    </Container>
  )
    
    
  


 }

  
    