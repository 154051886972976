
import { useState, useEffect } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get_val, send_request } from '../../../globals/globals';



export const  UploadDialog = (props) =>{
  
    //Select and read file
    const fileReader = new FileReader();
    //fileReader.fileName = get_val('inp_file_dialog')
    fileReader.onload = function (event) {
      const csv_text = event.target.result;
      const csvHeader = csv_text.slice(0, csv_text.indexOf("\n")).split(",");
      props.handle_file_header(csvHeader)
    };
  

  const handleFileSelected = (e) => { 
    var t_file = e.target.files[0]
    if (t_file && props.fileColumns === null) {
      fileReader.readAsText(t_file);
    }

    props.handle_file_uploaded(t_file)

  };


  function render_upload_dialog(){
    
    var container_classname = ''
    if(!props.stageShowUploadFile){
      container_classname = 'd-none'
    }


    return (
      <Container  className={container_classname}>
          <Row>
            <Col>
              <h1>Upload Transactions</h1>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <input type="file" onChange={handleFileSelected} id='inp_file_dialog' />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              
            </Col>
          </Row>
          
      
      </Container>
    );
  }


  return(
    <React.Fragment>
      {render_upload_dialog()}
    </React.Fragment>
  )

  
    
    
  }
  
  