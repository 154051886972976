
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';
import Modal from 'react-modal';

import { Tooltip } from 'react-tooltip'

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

export const  PageWhatIfSettings = (props) =>{
  const [settings, setSettings] = useState(null)

  const [apiIsSaving, setApiIsSaving] = useState(null)
  const [apiSavingSent, setApiSavingSent] = useState(false)
  
  //For Saving
  const [projectionSettingsCompanyDefaultId, setProjectionSettingsCompanyDefaultId] = useState(null)
  const [inputType, setInputType] = useState(null)
  const [inputVal, setInputVal] = useState(null)
  const [variableOperatingExpensePerc, setVariableOperatingExpensePerc] = useState(null)
  const [grossMarginPerc, setGrossMarginPerc] = useState(null)

  
  //Check job_status
  useEffect(() => {
    if(settings === null){
      get_settings()
    }
    else if(apiIsSaving & !apiSavingSent){
      save_settings()
      setApiSavingSent(true)
    }
  })

  //Api Calls
  async function get_settings(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'what_if_settings',
        func:"get_company_default_settings"
      }
      
      response = await send_request(body)
      
      flushSync(
        setSettings(response),
        setProjectionSettingsCompanyDefaultId(response['projection_settings_company_default_id'])
      )
      
    } 
    catch(err){
      console.log('get_settings body', body)
      console.log('get_settings response', response)
      throw new Error(err)
    }
    
      

  }

  async function save_settings(){

    var body = ''
    var response = ''
    try{
      body = { 
        path:'what_if_settings',
        func:"save_company_default_settings",
        projection_settings_company_default_id: projectionSettingsCompanyDefaultId,
        input_type: inputType,
        input_val:inputVal,
        variable_operating_expense_perc: variableOperatingExpensePerc,
        gross_margin_perc: grossMarginPerc
      }
      
      response = await send_request(body)
      flushSync(
        setSettings(null),
        setProjectionSettingsCompanyDefaultId(null),
        setInputType(null),
        setInputVal(null),
        setVariableOperatingExpensePerc(null),
        setGrossMarginPerc(null),
        setApiIsSaving(false),
        setApiSavingSent(false)
      )
    } 
    catch(err){
      console.log('save_settings body', body)
      console.log('save_settings response', response)
      throw new Error(err)
    }
  }




  //Rendering 
  function render_body(){
      if(settings === null){
          return loading_gif('Loading Settings')
      }
      else if( apiIsSaving){
        return loading_gif('Saving')
      }

      


      var input_val = settings['input_val']
      if(settings['input_type'] == 'ad_spend_perc'){
        input_val = Math.round(input_val * 1000) / 10
      }

      var voe_expense_perc = Math.round(settings['variable_operating_expense_perc'] * 1000) / 10

      var gross_margin_perc = Math.round(settings['gross_margin_perc'] * 1000) / 10



      return(
          <React.Fragment>
          <Row>
              <Col></Col>
              <Col><b>New Default</b></Col>
              <Col><b>Current Default</b></Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              Input Type
            </Col>
            <Col>
              <select defaultValue={settings['input_type']} className='w-100' id='inp_input_type'>
                <option value='payback_period'>Payback Period</option>
                <option value='ad_spend_dollars'>Ad Spend $</option>
                <option value='ad_spend_perc'>Ad Spend YoY %</option>
              </select>
            </Col>
            <Col>
              <select defaultValue={settings['input_type']} className='w-100' disabled>
                <option value='payback_period'>Payback Period</option>
                <option value='ad_spend_dollars'>Ad Spend $</option>
                <option value='ad_spend_perc'>Ad Spend YoY %</option>
              </select>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              Input Val
            </Col>
            <Col>
              <input type='number' defaultValue={input_val} className='w-100'   id='inp_input_val'/>
            </Col>
            <Col>
              <input type='number' defaultValue={input_val} className='w-100' disabled/>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              VOE %
            </Col>
            <Col>
              <input type='number' defaultValue={voe_expense_perc} className='w-100'   id='inp_voe_expense_perc'/>
            </Col>
            <Col>
              <input type='number' defaultValue={voe_expense_perc} className='w-100' disabled/>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              Gross Margin %
            </Col>
            <Col>
              <input type='number' defaultValue={gross_margin_perc} className='w-100'   id='inp_gross_margin_perc'/>
            </Col>
            <Col>
              <input type='number' defaultValue={gross_margin_perc} className='w-100' disabled/>
            </Col>
          </Row>

          <Row>
            <Col></Col>
            
            <Col>
              <button className='btn btn-lg btn-warning mt-3' onClick={set_to_save}>Save</button>
            </Col>
            <Col></Col>
          </Row>
          
          </React.Fragment>
      )

  }


    function set_to_save(){
      var input_type = get_val('inp_input_type')

      var input_val = parseFloat(get_val('inp_input_val'))

      if(input_type == 'ad_spend_perc'){
        input_val = input_val / 100
      }

      var voe = parseFloat(get_val('inp_voe_expense_perc')) / 100

      var gross_margin_perc = parseFloat(get_val('inp_gross_margin_perc')) / 100

      

      flushSync(
        setApiIsSaving(true),
        setInputType(input_type),
        setInputVal(input_val),
        setVariableOperatingExpensePerc(voe),
        setGrossMarginPerc(gross_margin_perc)
      )
    }

    

  //Output
  return(
    <Container>
        <Row>
          <Col lg ="auto">
            <h1>What If Default Settings</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            This will overwrite any existing settings where the settings are still the default settings.
            <br/>
            I.E Any manual inputs will remain. 
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col lg={6}>
            {render_body()}
          </Col>
        </Row>

    </Container>
  )
    
    
  


 }

  
    