
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';
import Calendar from 'react-calendar'

import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  


export const  CompTransactionFileCompanyFilters = (props) =>{
    const [globalFilters, setGlobalFilters] = useState(null)
    

    //Check job_status
    useEffect(() => {
      if(globalFilters === null){
        get_global_filters()
      }
  
    })
  
    //Api Calls
    async function get_global_filters(){
      var body = ''
      var response = ''
      try{
        //Put together the body
      
        body = { 
          path:'transaction_files',
          func:"get_global_filters"
        }
        
        response = await send_request(body)
        response = JSON.parse(response)
        
        setGlobalFilters(response)
        
      } 
      catch(err){
        console.log('get_global_filters body', body)
        console.log('get_global_filters response', response)
        throw new Error(err)
      }
      
        
  
    }
  
  
    function render_table(){
      if(globalFilters === null){
        return(
          loading_gif('Loading Global Filters')
        )
      }
  
  
  
  
      var rows = []
      for(var i=0;i<globalFilters.length;i++){
        var row = globalFilters[i]
  
        var operator = row['operator']
        if(operator == '!='){
          operator = 'not equal to'
        }
  
        var t_ele = 
        <React.Fragment>
          <Row lg={4}>
            <Col>
              {row['column_name']}
            </Col>
            <Col>
              {operator}
            </Col>
            <Col>
              {row['value']}
            </Col>
          </Row>
          
        </React.Fragment>
        rows.push(t_ele)
      }
  
  
      return(
        <React.Fragment>
          {rows}
        </React.Fragment>
      )
  
      
  
    }
  
  
  
    //Output
    return(
      <Container lg={800}>
          <Row>
            <Col lg ="auto">
              <h5>Global Filters</h5>
            </Col>
          </Row>
  
          <Row className='card p-2'>
            
          <Row lg={4}>
            <Col>
              <h6>Column</h6>
            </Col>
            <Col>
              <h6>Operator</h6>
            </Col>
            <Col>
              <h6>Value</h6>
            </Col>
          </Row>
          
          
          {render_table()}
          
          </Row>
  
          <Row>
            <Col>
              <Link className='text-dark text-decoration-none' to='../settings/company_filters'><h6  className='hover_underline' >Manage Global Filters</h6></Link>
            </Col>
          </Row>
            
  
          
      </Container>
    )
      
      
    
  
  


 }

  
    