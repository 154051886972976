import { Auth } from 'aws-amplify';
import { useParams } from 'react-router-dom';

export function withParams(Component) {
  //Testing router6 useparams for inter-page data transfer
  return props => <Component {...props} params={useParams()} />;
}

export const dollar_formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export function format_dollar(val, add_sign){
  if(val==null){
    return ''
  }
  if(val>0 && add_sign){
    return '+' + dollar_formatter.format(Math.round(val))
  }else{
    return dollar_formatter.format(Math.round(val))
  }
  
}


export function format_number(val, add_sign){
  if(val === undefined){
    val = 0
  }
  if(val > 0 && add_sign){
    return '+' + val.toLocaleString("en-US")
  }else{
    return val.toLocaleString("en-US")
  }
  
}


export function format_percent(val, add_sign){
  if(val > 0 && add_sign){
    return '+' + Math.round(val*1000)/10 + '%'
  }else{
    return Math.round(val*1000)/10 + '%'
  }
  
}











export function get_val(id){
  return document.getElementById(id).value
}

export function get_ele(id){
  return document.getElementById(id)
}

export function set_attr(id, attr, val){
  return document.getElementById(id).setAttribute(attr, val)
}

export function get_attr(id, attr){
  return document.getElementById(id).getAttribute(attr)
}

export function get_color(ratio, color1, color2){
  if(ratio > 1){
    ratio = 1
  }
  
  
  var hex = function(x) {
      x = x.toString(16);
      return (x.length === 1) ? '0' + x : x;
  };

  var r = Math.ceil(parseInt(color1.substring(0,2), 16) * ratio + parseInt(color2.substring(0,2), 16) * (1-ratio));
  var g = Math.ceil(parseInt(color1.substring(2,4), 16) * ratio + parseInt(color2.substring(2,4), 16) * (1-ratio));
  var b = Math.ceil(parseInt(color1.substring(4,6), 16) * ratio + parseInt(color2.substring(4,6), 16) * (1-ratio));

  var final_color = hex(r) + hex(g) + hex(b);
  return final_color
}







  
export function get_aws_bucket(bucket){
  if(process.env.REACT_APP_LOCAL === 'true'){//t_web_app
    return 't-' + bucket
  }
  else{//p_web_app
    return 'p-' + bucket
  }
}

export function get_identity_pool(){
  return 'us-west-2:0d3b6258-072e-4ccb-9c19-68286a8f603e'
}

export function get_aws_region(){
  return 'us-west-2'
}


export async function send_request(body, print){
  try{
    let token = (await Auth.currentSession()).getIdToken().getJwtToken()
    //console.log('send_request', token)
    body['auth_token'] = token

    if(print !== undefined){
      console.log(print, body)
    }


    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    };


    var endpoint = "";
    if(process.env.REACT_APP_LOCAL === 'true'){//t_web_app
      endpoint = "https://kr7pnywn74.execute-api.us-west-2.amazonaws.com/dev";
    }
    else{//p_web_app
      endpoint = "https://iqiby9yv62.execute-api.us-west-2.amazonaws.com/prod";
    }

    
    var response = ''
    try{
      response = await fetch(endpoint, requestOptions)
      response = response.json()
      
    }
    catch(err){
      throw new Error(err)
    }
    

    return response
  }
  catch(err){
    if(err === 'No current user'){
      window.localStorage.setItem('user', null)
      window.localStorage.setItem('authState', null)  
    }
    throw new Error(err)
  }
  
    


}



export function isna(val){
  if(val === undefined || val === null){
    return true
  }else{
    return false
  }

}



export function get_job_status_step_counter(stepCounter){
  const job_status_step_counter = [
    'Starting Solar Cells',
    'Finding Astronauts',
    'Training Astronauts',
    'Finding Space Suits',
    'Building a Rocket',
    'Testing Rocket',
    'Rocket Exploded',
    'No Astronauts Harmed',
    'Building Rocket 2.0',
    'Testing Rocket 2.0',
    'Astronauts in Rocket',
    'Powering up Thrusters.'
  ]

  
  if(stepCounter === -1 || stepCounter >= job_status_step_counter.length){
    return "Finishing Up"
  }
  else{
    return job_status_step_counter[stepCounter]
  }

}



export function epoch_to_date_string(ts){
  var date_obj = new Date(0)
  date_obj.setUTCMilliseconds(ts)

  var year = date_obj.getUTCFullYear()
  var month = date_obj.getUTCMonth() + 1 // 0 indexed
  if(month < 10){
    month = '0' + month
  }
  var date = date_obj.getUTCDate()
  if(date < 10){
    date = '0' + date
  }

  var full_date = year + '-' + month + '-' + date
  return full_date

}

export function date_string_to_epoch(ts){
  var epoch = Date.parse(ts)
  return epoch
}

