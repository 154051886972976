import React from "react";
import { Container } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import '../../globals/globals.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from "react";
import { useState } from "react";
import { send_request} from '../../globals/globals.js';
import { flushSync } from "react-dom";
import loading_gif from "../../globals/loading_gif";


export const  AdminCompanyDetails = (props) =>{
  
  const [companyID, setCompanyID] = useState(null)
  const [companyDetails, setCompanyDetails] = useState(null)
  const [sentRequest, setSentRequest] = useState(false)
  
  

  //Check job_status
  useEffect(() => {
    if(companyID !== null && !sentRequest && companyDetails === null){
      get_company_details()
      setSentRequest(true)
      return
    }
    
  })


 

  // api calls
  async function get_company_details(){
    
    var body = ''
    var response = ''
    try{
      body = { 
        path:'admin_company_details',
        func:"get_dates",
        company_id: companyID
      }
      
      response = await send_request(body)
      
      response['transaction_line_items'] = JSON.parse(response['transaction_line_items'])
      response['customers'] = JSON.parse(response['customers'])
      response['daily_vals'] = JSON.parse(response['daily_vals'])
      response['cohort_table_data'] = JSON.parse(response['cohort_table_data'])
      response['algo_output'] = JSON.parse(response['algo_output'])
      response['slices'] = JSON.parse(response['slices'])
      response['cohort_filters'] = JSON.parse(response['cohort_filters'])
      response['projections'] = JSON.parse(response['projections'])

      debugger

      flushSync(
        setCompanyDetails(response),
        setSentRequest(false)
      )
      

    } 
    catch(err){
      console.log('get_company_details body', body)
      console.log('get_company_details response', response)
      throw new Error(err)
    }

  }



    function set_company_id(){
      var new_id = document.getElementById('inp_company_id').value
      setCompanyID(new_id)
    }

  


  function render_change_company(){

    var btn = <button onClick={set_company_id} className='btn btn-lg btn-warning' id='btn_update_company_id'>Get Data</button>
    if(sentRequest){
      btn = loading_gif('Loading')
    }
    else if(companyDetails !== null){
      btn = ''
    }


    var disable_input = companyDetails !== null 

    var transaction_line_items = ''
    if(companyDetails !== null){
      transaction_line_items = render_transaction_line_items()
    }

    var customers = ''
    if(companyDetails !== null){
      customers = render_customers()
    }

    var daily_vals = ''
    if(companyDetails !== null){
      daily_vals = render_daily_vals()
    }

    var cohort_table_data = ''
    if(companyDetails !== null){
      cohort_table_data = render_cohort_table_data()
    }

    var algo_output = ''
    if(companyDetails !== null){
      algo_output = render_algo_output()
    }

    var slices = ''
    if(companyDetails !== null){
      slices = render_slices()
    }

    var cohort_filters = ''
    if(companyDetails !== null){
      cohort_filters = render_cohort_filters()
    }

    var projections = ''
    if(companyDetails !== null){
      projections = render_projections()
    }


    
    
    return(
      <React.Fragment>
        <Row>
          <Col>
            Company ID:
          </Col>
        </Row>
        <Row>
          <Col lg={2}>
            <input id="inp_company_id" key="inp_company_id" type='number'  className={'w-100 text-align-center '} disabled={disable_input}/>
          </Col>
          <Col>
            {btn}
          </Col>
        </Row>

        {transaction_line_items}
        {customers}
        {daily_vals}
        {cohort_table_data}
        {algo_output}
        {slices}
        {cohort_filters}
        {projections}
        

      </React.Fragment>
    )
  }



  function render_transaction_line_items(){
    var vals = companyDetails['transaction_line_items'][0]

    return(
      <React.Fragment>
        <Row className="mt-5">
          <Col><h4>Transaction Line Items</h4></Col>
        </Row>
        <Row>
          <Col lg={2}><p>Min Date</p></Col>
          <Col lg={2}><p>Max Date</p></Col>
        </Row>
        <Row>
          <Col lg={2}><p>{vals['min_date']}</p></Col>
          <Col lg={2}><p>{vals['max_date']}</p></Col>
        </Row>
      </React.Fragment>
    )

  }


  function render_customers(){
    var vals = companyDetails['customers'][0]

    return(
      <React.Fragment>
        <Row className="mt-5">
          <Col><h4>Customers</h4></Col>
        </Row>
        <Row>
          <Col lg={2}><p>Min Date</p></Col>
          <Col lg={2}><p>Max Date</p></Col>
        </Row>
        <Row>
          <Col lg={2}><p>{vals['min_date']}</p></Col>
          <Col lg={2}><p>{vals['max_date']}</p></Col>
        </Row>
      </React.Fragment>
    )

  }

  function render_daily_vals(){
    var vals = companyDetails['daily_vals'][0]

    return(
      <React.Fragment>
        <Row className="mt-5">
          <Col><h4>Daily Vals</h4></Col>
        </Row>
        <Row>
          <Col lg={2}><p>Row Min Date</p></Col>
          <Col lg={2}><p>Row Max Date</p></Col>
          <Col lg={2}><p>Col Max Date</p></Col>
          <Col lg={2}><p>Col Max Date</p></Col>
        </Row>
        <Row>
          <Col lg={2}><p>{vals['row_min_date']}</p></Col>
          <Col lg={2}><p>{vals['row_max_date']}</p></Col>
          <Col lg={2}><p>{vals['col_min_date']}</p></Col>
          <Col lg={2}><p>{vals['col_max_date']}</p></Col>
        </Row>
      </React.Fragment>
    )

  }


  function render_cohort_table_data(){
    var vals = companyDetails['cohort_table_data']

    var table_vals = []
    for(var i=0;i<vals.length;i++){
      var record = vals[i]
      table_vals.push(<React.Fragment>
          <Row>
          <Col lg={2}><p>{record['cohort_filter_id']}</p></Col>
          <Col lg={2}><p>{record['calendar_type']}</p></Col>
          <Col lg={2}><p>{record['row_min_date']}</p></Col>
          <Col lg={2}><p>{record['row_max_date']}</p></Col>
          <Col lg={2}><p>{record['col_min_date']}</p></Col>
          <Col lg={2}><p>{record['col_max_date']}</p></Col>
        </Row>
      </React.Fragment>)
    }


    return(
      <React.Fragment>
        <Row className="mt-5">
          <Col><h4>Daily Vals</h4></Col>
        </Row>
        <Row>
        <Col lg={2}><p>Cohort Filter ID</p></Col>
        <Col lg={2}><p>Calendar Type</p></Col>
          <Col lg={2}><p>Row Min Date</p></Col>
          <Col lg={2}><p>Row Max Date</p></Col>
          <Col lg={2}><p>Col Max Date</p></Col>
          <Col lg={2}><p>Col Max Date</p></Col>
        </Row>
        {table_vals}
      </React.Fragment>
    )

  }


  function render_algo_output(){
    var vals = companyDetails['algo_output']

    var table_vals = []
    for(var i=0;i<vals.length;i++){
      var record = vals[i]
      table_vals.push(<React.Fragment>
          <Row>
          <Col lg={2}><p>{record['cohort_filter_id']}</p></Col>
          <Col lg={2}><p>{record['calendar_type']}</p></Col>
          <Col lg={2}><p>{record['min_date']}</p></Col>
          <Col lg={2}><p>{record['max_date']}</p></Col>
        </Row>
      </React.Fragment>)
    }


    return(
      <React.Fragment>
        <Row className="mt-5">
          <Col><h4>Algo Output</h4></Col>
        </Row>
        <Row>
        <Col lg={2}><p>Cohort Filter ID</p></Col>
        <Col lg={2}><p>Calendar Type</p></Col>
          <Col lg={2}><p>Min Date</p></Col>
          <Col lg={2}><p>Max Date</p></Col>
        </Row>
        {table_vals}
      </React.Fragment>
    )

  }

  function render_slices(){
    var vals = companyDetails['slices']

    
    var table_vals = []
    for(var i=0;i<vals.length;i++){
      var record = vals[i]
      table_vals.push(<React.Fragment>
          <Row>
          <Col lg={2}><p>{record['slice_id']}</p></Col>
          <Col lg={2}><p>{record['slice_name']}</p></Col>
          <Col lg={2}><p>{record['search_values']}</p></Col>
          <Col lg={2}><p>{record['search_column']}</p></Col>
        </Row>
      </React.Fragment>)
    }


    return(
      <React.Fragment>
        <Row className="mt-5">
          <Col><h4>slices</h4></Col>
        </Row>
        <Row>
        <Col lg={2}><p>slice_id</p></Col>
        <Col lg={2}><p>slice_name</p></Col>
          <Col lg={2}><p>search_values</p></Col>
          <Col lg={2}><p>search_column</p></Col>
        </Row>
        {table_vals}
      </React.Fragment>
    )

  }

  function render_cohort_filters(){
    var vals = companyDetails['cohort_filters']
    debugger
    var table_vals = []
    for(var i=0;i<vals.length;i++){
      var record = vals[i]
      table_vals.push(<React.Fragment>
          <Row>
          <Col lg={2}><p>{record['cohort_filter_id']}</p></Col>
          <Col lg={2}><p>{record['description']}</p></Col>
          <Col lg={2}><p>{record['nice_name']}</p></Col>
          <Col lg={2}><p>{record['first_transaction_date']}</p></Col>
        </Row>
      </React.Fragment>)
    }


    return(
      <React.Fragment>
        <Row className="mt-5">
          <Col><h4>cohort_filters</h4></Col>
        </Row>
        <Row>
        <Col lg={2}><p>cohort_filter_id</p></Col>
        <Col lg={2}><p>description</p></Col>
          <Col lg={2}><p>nice_name</p></Col>
          <Col lg={2}><p>first_transaction_date</p></Col>
        </Row>
        {table_vals}
      </React.Fragment>
    )

  }

  function render_projections(){
    var vals = companyDetails['projections']
    
    //, id , 
    var table_vals = []
    for(var i=0;i<vals.length;i++){
      var record = vals[i]
      table_vals.push(<React.Fragment>
          <Row>
          <Col lg={2}><p>{record['cohort_filter_id']}</p></Col>
          <Col lg={2}><p>{record['projection_id']}</p></Col>
          <Col lg={2}><p>{record['last_date']}</p></Col>
        </Row>
      </React.Fragment>)
    }


    return(
      <React.Fragment>
        <Row className="mt-5">
          <Col><h4>Projections</h4></Col>
        </Row>
        <Row>
        <Col lg={2}><p>cohort_filter_id</p></Col>
        <Col lg={2}><p>projection_id</p></Col>
          <Col lg={2}><p>last_date</p></Col>
        </Row>
        {table_vals}
      </React.Fragment>
    )

  }


  //Output
    return (
      <React.Fragment>
      <Container>
        <Row>
          <h1>Company Details</h1>
        </Row>
        {render_change_company()}
          

      </Container>
   
    
    </React.Fragment>
    )
    
    
  


 }

  
    


  


    





  
















