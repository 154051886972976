import React from "react";
import moment from "moment";
import { useLocation } from 'react-router-dom'
//import { Line } from "react-chartjs-2";
//import dashboard_api from "../../api/dashboard";
import { Container } from "react-bootstrap";
import { Chart, registerables } from 'chart.js';
import "react-datepicker/dist/react-datepicker.css";
import '../globals/globals.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {DriversAndDrags} from "../dashboard/drivers_and_drags";
import {TwoYearsPrior} from "./two_years_prior";
import { useState } from "react";
import { SideMenu } from '../side_menu function/SideMenu.jsx';
import { flushSync } from "react-dom";
import { DNDGraphs } from "./DnD_Graphs";







Chart.register(...registerables);



export const  PageDriversAndDrags = (props) =>{
  const location = useLocation()
  //Side menu api
  const [filters, setFilters] = useState(window.localStorage.getItem('filters') === null ? null : JSON.parse(window.localStorage.getItem('filters')))
  const [calendar, setCalendar] = useState(window.localStorage.getItem('calendar') === null ? null : JSON.parse(window.localStorage.getItem('calendar')))

  //Side menu set
  const [firstTransaction, setFirstTransaction] = useState(window.localStorage.getItem('firstTransaction') === null ? null : (window.localStorage.getItem('firstTransaction')))
  const [lastTransaction, setLastTransaction] = useState(window.localStorage.getItem('lastTransaction') === null ? null : (window.localStorage.getItem('lastTransaction')))
  const [selected_calendar_date, setSelectedCalendarDate] = useState(window.localStorage.getItem('selected_calendar_date') === null ? null : JSON.parse(window.localStorage.getItem('selected_calendar_date')))
  const [cohort_filter_id, setCohortFilterID] = useState(window.localStorage.getItem('cohort_filter_id') === null ? null : parseInt(window.localStorage.getItem('cohort_filter_id')))
  const [selected_calendar_type, setSelectedCalendarType] = useState(window.localStorage.getItem('selected_calendar_type') === null ? 'month_end_445' : window.localStorage.getItem('selected_calendar_type')) 
  
  

  // For two years prior
  var default_measure = 'total_lever_number_of_new'
  if(Object.keys(location).includes('state') && location.state !== null && Object.keys(location.state).includes('default_measure')){
    var default_measure = location.state['default_measure']
    if(default_measure === 'Number of New'){default_measure = 'total_lever_number_of_new'}
    else if(default_measure === 'New Order Frequency'){default_measure = 'new_order_frequency'}
    else if(default_measure === 'New Units Per Order'){default_measure = 'new_units_per_order'}
    else if(default_measure === 'New Price Per Unit'){default_measure = 'new_price_per_unit'}
    else if(default_measure === 'Retention'){default_measure = 'existing_retention'}
    else if(default_measure === 'Returning Order Frequency'){default_measure = 'existing_order_frequency'}
    else if(default_measure === 'Returning Units Per Order'){default_measure = 'existing_units_per_order'}
    else if(default_measure === 'Returning Price Per Unit'){default_measure = 'existing_price_per_unit'}
  }
  
  const [selected_measure, setSelectedMeasure] = useState(default_measure)
  const [measures, setMeasures] = useState({})




  //Drivers and Drags Measure Change
  function handle_drivers_and_drags_measure_change(new_measure){
    flushSync(
      setSelectedMeasure(new_measure)
    )
  }

  function handle_drivers_and_drags_update_measures(new_measures){
    flushSync(
      setMeasures(new_measures)
    )
  }

  


  function change_measure_dropdown(measure){
    

    if(measure['measure'] === 'Number of New'){measure = 'total_lever_number_of_new'}
    else if(measure['measure'] === 'New Order Frequency'){measure = 'new_order_frequency'}
    else if(measure['measure'] === 'New Units Per Order'){measure = 'new_units_per_order'}
    else if(measure['measure'] === 'New Price Per Unit'){measure = 'new_price_per_unit'}
    else if(measure['measure'] === 'Retention'){measure = 'existing_retention'}
    else if(measure['measure'] === 'Returning Order Frequency'){measure = 'existing_order_frequency'}
    else if(measure['measure'] === 'Returning Units Per Order'){measure = 'existing_units_per_order'}
    else if(measure['measure'] === 'Returning Price Per Unit'){measure = 'existing_price_per_unit'}

    console.log('measure', measure)
    setSelectedMeasure(measure)
  }


  function render_page_components(){
    if(cohort_filter_id === 0){
      return 
    }
    else{
      return(
        <React.Fragment>
          <Row className="mt-3">
            <DriversAndDrags
              selected_calendar_type= {selected_calendar_type}
              selected_calendar_date={selected_calendar_date}
              cohort_filter_id={cohort_filter_id}
              parent_component="page_drivers_and_drags"
              change_measure_dropdown={change_measure_dropdown}
              />
            </Row>
            <Row className="mt-3">
              <TwoYearsPrior
                selected_calendar_type= {selected_calendar_type}
                selected_calendar_date={selected_calendar_date}
                cohort_filter_id={cohort_filter_id}
                calendar={calendar}
                selected_measure={selected_measure}
                setSelectedMeasure={setSelectedMeasure}
                measures={measures} 
                handle_drivers_and_drags_measure_change={handle_drivers_and_drags_measure_change}
                handle_drivers_and_drags_update_measures={handle_drivers_and_drags_update_measures}
                />
            </Row>
            <Row className="mt-5"></Row>
            <Row className="mt-5">
              <DNDGraphs
                selected_calendar_type= {selected_calendar_type}
                selected_calendar_date={selected_calendar_date}
                cohort_filter_id={cohort_filter_id}
                calendar={calendar}
                selected_measure={selected_measure}
                />
            </Row>
          </React.Fragment>
      )
    }
  }


  return (
    <React.Fragment>
    <Container>
      <Row>
        <Col lg ="4">
          <h1>Drivers And Drags</h1>
        </Col>
        {SideMenu(
          {
            firstTransaction:firstTransaction, setFirstTransaction:setFirstTransaction,
            lastTransaction:lastTransaction, setLastTransaction:setLastTransaction,
            filters:filters, setFilters:setFilters,
            calendar:calendar, setCalendar:setCalendar,
            selected_calendar_type:selected_calendar_type, setSelectedCalendarType:setSelectedCalendarType,
            selected_calendar_date:selected_calendar_date, setSelectedCalendarDate:setSelectedCalendarDate,
            cohort_filter_id:cohort_filter_id, setCohortFilterID:setCohortFilterID,
            parent_component: 'page_drivers_and_drags',
            calendar_dropdown_to_fill: 'last_transaction_and_prior'
          }
          )
        }
        {render_page_components()}
      </Row>
    </Container>
  </React.Fragment>
  );
  


}







