
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';
import Modal from 'react-modal';

import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';




const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


export const  CompTransactionFileFilters = (props) =>{
  
    

    //Passed from transaction_file_info.jsx
    const [dataInputTranslationId, setDataInputTranslationId] = useState(props.data_input_translation_id)


    const [fileFilters, setFileFilters] = useState(null)

    //Add Tag Modal
    const [openAddFilter, setOpenAddFilter] = useState(false); //For Modal
    const [sentAddFilterAPI, setSentAddFilterAPI] = useState(false); //For loading Gif
    const [sentAddFilterWarning, setSentAddFilterWarning] = useState(false); //For loading Gif
    
    function open_add_filter_modal(){
      setOpenAddFilter(true)
    }
    function close_add_filter_modal(){
      setOpenAddFilter(false)
    }

    function save_add_filter(){
      setSentAddFilterAPI(true)
    }


    //Remove Tag Modal
    const [filterToRemove, setFilterToRemove] = useState(null);
    const [sentRemoveFilterAPI, setSentRemoveFilterAPI] = useState(false); //For loading Gif

    
    function close_remove_filter_modal(){
      setFilterToRemove(null)
    }

    function set_filter_to_remove(e){
      var column = e.target.getAttribute('column')
      var operator = e.target.getAttribute('operator')
      var value = e.target.getAttribute('value')

      setFilterToRemove({column: column, operator: operator, value: value})

    }

    function set_sent_remove_filter_api(){
      setSentRemoveFilterAPI(true)
    }
    


    

  //
  useEffect(() => {
    if(fileFilters === null){
      get_file_filters()
    }
    else if(sentAddFilterAPI){
      add_file_filter()
    }
    else if(sentRemoveFilterAPI){
      remove_file_filter()
    }
    
  })


  //Api Calls
  async function get_file_filters(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"get_file_filters",
        data_input_translation_id: dataInputTranslationId
      }
      
      response = await send_request(body)
      response = JSON.parse(response)

      
      setFileFilters(response)
      
    } 
    catch(err){
      console.log('get_file_filters body', body)
      console.log('get_file_filters response', response)
      throw new Error(err)
    }
    
      

  }

  async function add_file_filter(){
    var body = ''
    var response = ''

    var column_name = get_val('input_new_file_filter_column')
    var operator = get_val('input_new_file_filter_operator')
    var value = get_val('input_new_file_filter_value')

    if(column_name === '' || operator === '' || value === ''){
      flushSync(
        setSentAddFilterAPI(false),
        setSentAddFilterWarning(true)
      )
    }

    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"add_file_filter",
        data_input_translation_id: dataInputTranslationId,
        column: column_name,
        operator: operator,
        value: value
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      flushSync(
        setFileFilters(response),
        setSentAddFilterAPI(false),
        setSentAddFilterWarning(false),
        setOpenAddFilter(false)
      )
      
    } 
    catch(err){
      console.log('get_file_filters body', body)
      console.log('get_file_filters response', response)
      throw new Error(err)
    }
    
      

  }

  async function remove_file_filter(){
    var body = ''
    var response = ''

    

    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"remove_file_filter",
        data_input_translation_id: dataInputTranslationId,
        column: filterToRemove['column'],
        operator: filterToRemove['operator'],
        value: filterToRemove['value']
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      flushSync(
        setFileFilters(response),
        setSentRemoveFilterAPI(false),
        setFilterToRemove(null)
      )
      
    } 
    catch(err){
      console.log('get_file_filters body', body)
      console.log('get_file_filters response', response)
      throw new Error(err)
    }
    
      

  }


  function render_table(){
    if(fileFilters === null){
      return(
        loading_gif('Loading File Filters')
      )
    }




    var rows = []
    for(var i=0;i<fileFilters.length;i++){
      var row = fileFilters[i]

      var operator = row['operator']
      if(operator == '!='){
        operator = 'not equal to'
      }

      var t_ele = 
      <React.Fragment>
        <Row lg={4}>
          <Col>
            {row['column_name']}
          </Col>
          <Col>
            {operator}
          </Col>
          <Col>
            {row['value']}
          </Col>
          <Col>
            <h6 className='hover_underline' column={row['column_name']} operator={operator} value={row['value']} onClick={set_filter_to_remove}>Remove</h6>
          </Col>
        </Row>
        
      </React.Fragment>
      rows.push(t_ele)
    }


    return(
      <React.Fragment>
        {rows}
      </React.Fragment>
    )

    

  }



  //Add Modal
  function render_add_modal(){

    var btn = sentAddFilterAPI ? loading_gif('Saving'): <button id="btn_save" className='btn btn-lg btn-warning mt-3' onClick={save_add_filter}>Save</button>
    var modal_warning = sentAddFilterWarning ? <h6>Column and Value must have values.</h6> : ''


    return(
      <React.Fragment>
        <Modal
        isOpen={openAddFilter}
        onRequestClose={close_add_filter_modal}
        style={customStyles}
      >
        <Row>
          <Row>
            <Col>
              {modal_warning}
            </Col>
          </Row>
          <Row>
            <Col>
              Column / Attribute Name
            </Col>
            <Col>
              Operator
            </Col>
            <Col>
              Value
            </Col>
          </Row>
          <Row>
            <Col>
              <input type='text' className={'w-100 text-align-center '} id='input_new_file_filter_column' />
            </Col>
            <Col>
              <select className={'w-100 text-align-center '} id='input_new_file_filter_operator'>
                <option value='='>Equal To</option>
                <option value='!='>Not Equal To</option>
              </select>
            </Col>
            <Col>
              <input type='text' className={'w-100 text-align-center '} id='input_new_file_filter_value' />
            </Col>
          </Row>
          <Row>
            <Col>
              {btn}
            </Col>
          </Row>
        </Row>
      </Modal>
      </React.Fragment>
    )
  }


  //Remove Modal
  function render_remove_modal(){

    var btn_no = sentRemoveFilterAPI ? '': <button className='btn btn-lg btn-warning mt-3' onClick={close_remove_filter_modal}>No</button>
      var btn_yes = sentRemoveFilterAPI ? loading_gif('Removing'): <button className='btn btn-lg btn-warning mt-3' onClick={set_sent_remove_filter_api}>Yes</button>

      return(
        <React.Fragment>
          <Modal
          isOpen={filterToRemove !== null}
          onRequestClose={close_remove_filter_modal}
          style={customStyles}
        >
          <Row>
            <Row>
              <Col>
                Are you sure you want to delete this filter?
              </Col>
            </Row>
            <Row>
              <Col lg={2}>
                {btn_no}
              </Col>
              <Col>
                {btn_yes}
              </Col>
            </Row>
            
          </Row>
        </Modal>
        </React.Fragment>
      )
  }

  //Output
  return(
    <Container lg={800}>
        <Row>
          <Col lg ="auto">
            <h5>File Specific Filters</h5>
          </Col>
        </Row>

        <Row className='card p-2'>
          
        <Row lg={4}>
          <Col>
            <h6>Column</h6>
          </Col>
          <Col>
            <h6>Operator</h6>
          </Col>
          <Col>
            <h6>Value</h6>
          </Col>
        </Row>
        
        
        {render_table()}
        </Row>
        <Row>
          <Col>
            <h6 className='hover_underline' onClick={open_add_filter_modal}>Add New +</h6>
          </Col>
        </Row>

        {render_add_modal()}
        {render_remove_modal()}

        
    </Container>
  )
    
    
  


 }

  
    