
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


export const  CompTransactionFileTags = (props) =>{
  let subtitle;
  //Passed from transaction_file_info.jsx
    const [dataInputTranslationId, setDataInputTranslationId] = useState(props.data_input_translation_id)


    const [tags, setTags] = useState(null)

    //Add Tag Modal
    const [open, setOpen] = useState(false);
    const [addingTag, setAddingTag] = useState(false);
    const handleAddingTag = () => setAddingTag(true);

    const [modalWarning, setModalWarning] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    //Remove Tag Modal    
    const [removingTagColumn, setRemovingTagColumn] = useState('');
    const [removingSentAPI, setRemovingSentAPI] = useState(false);
    


    function open_remove_modal(e){
        var column_name = e.target.getAttribute('column_name')
        setRemovingTagColumn(column_name)
    }
    
    function close_remove_modal(){
      setRemovingTagColumn('')
    }

    function setRemovingSentAPITrue(){
      setRemovingSentAPI(true)
    }

    function setRemovingSentAPIFalse(){
      flushSync(
        setRemovingSentAPI(false),
        setRemovingTagColumn(null)
      )
    }



  

    


    

  //Check job_status
  useEffect(() => {
    console.log('open', open)
    if(tags === null){
      get_tags()
    }
    else if(addingTag){
      add_tag()
    }
    else if(removingSentAPI){
      remove_tag()
    }
  })



  //Api Calls
  async function get_tags(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"get_tags",
        data_input_translation_id: dataInputTranslationId
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      response = JSON.parse(response[0]['tags'])
      
      setTags(response)
      
    } 
    catch(err){
      console.log('get_tags body', body)
      console.log('get_tags response', response)
      throw new Error(err)
    }
    
      

  }
  
  async function add_tag(){
    var body = ''
    var response = ''

    var column = get_val('input_new_tag_column')
    var value = get_val('input_new_tag_value')

    if(column == '' || value == ''){
      flushSync(
        setModalWarning(true),
        setAddingTag(false)
      )
      return
    }

    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"add_tag",
        data_input_translation_id: dataInputTranslationId,
        values: [{'column': column, 'value': value}] //API is built for multiple entries, UI currently on have single entry.
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      response = JSON.parse(response[0]['tags'])
      
      flushSync(
        setTags(response),
        setModalWarning(false),
        setAddingTag(false),
        setOpen(false)
      )

      
    } 
    catch(err){
      console.log('add_tag body', body)
      console.log('add_tag response', response)
      throw new Error(err)
    }
  }

  async function remove_tag(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"remove_tag",
        data_input_translation_id: dataInputTranslationId,
        column: removingTagColumn
      }
      debugger
      response = await send_request(body)
      response = JSON.parse(response)
      response = JSON.parse(response[0]['tags'])
      
      flushSync(
        setTags(response),
        setRemovingSentAPI(false),
        setRemovingTagColumn('')
      )

    } 
    catch(err){
      console.log('remove_tag body', body)
      console.log('remove_tag response', response)
      throw new Error(err)
    }
  }



    //Render
  function render_tag_table(){
    if(tags === null){
      return(
        loading_gif('Loading Tags')
      )
    }

    var rows = []
    for(var i=0;i<Object.keys(tags).length;i++){
      var column_name = Object.keys(tags)[i]
      var value = tags[column_name]
      
      var t_ele = 
      <React.Fragment>
        <Row lg={4}>
          <Col>
            {column_name}
          </Col>
          <Col>
            {value}
          </Col>
          <Col>
            <h6 className='hover_underline' key={'remove_tag_' + i} onClick={open_remove_modal} column_name={column_name}>Remove</h6>
          </Col>
        </Row>
        
      </React.Fragment>
      rows.push(t_ele)
    }


    return(
      <React.Fragment>
        {rows}
      </React.Fragment>
    )

    

  }

    //Add Modal
    function render_add_modal(){


      var btn = addingTag ? loading_gif('Saving'): <button id="btn_save_tag" className='btn btn-lg btn-warning mt-3' onClick={handleAddingTag}>Save</button>
      var modal_warning = modalWarning ? <h6>Column and Value must have values.</h6> : ''


      return(
        <React.Fragment>
          <Modal
          isOpen={open}
          onRequestClose={handleClose}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Row>
            <Row>
              <Col>
                {modal_warning}
              </Col>
            </Row>
            <Row>
              <Col>
                Column / Attribute Name
              </Col>
              <Col>
                Value
              </Col>
            </Row>
            <Row>
              <Col>
                <input type='text' className={'w-100 text-align-center '} id='input_new_tag_column' />
              </Col>
              <Col>
                <input type='text' className={'w-100 text-align-center '} id='input_new_tag_value' />
              </Col>
            </Row>
            <Row>
              <Col>
                {btn}
              </Col>
            </Row>
          </Row>
        </Modal>
        </React.Fragment>
      )
    }

    //Remove Modal
    function render_remove_modal(){


      var btn_no = removingSentAPI ? '': <button className='btn btn-lg btn-warning mt-3' onClick={close_remove_modal}>No</button>
      var btn_yes = removingSentAPI ? loading_gif('Removing'): <button className='btn btn-lg btn-warning mt-3' onClick={setRemovingSentAPITrue}>Yes</button>

      return(
        <React.Fragment>
          <Modal
          isOpen={removingTagColumn !== ''}
          onRequestClose={setRemovingSentAPIFalse}
          style={customStyles}
        >
          <Row>
            <Row>
              <Col>
                Are you sure you want to delete this tag?
              </Col>
            </Row>
            <Row>
              <Col lg={2}>
                {btn_no}
              </Col>
              <Col>
                {btn_yes}
              </Col>
            </Row>
            
          </Row>
        </Modal>
        </React.Fragment>
      )
    }








  //Output
  return(
    <Container lg={800}>
        <Row>
          <Col lg ="auto">
            <h5>Tags</h5>
          </Col>
        </Row>

        <Row className='card p-2'>
          
          <Row lg={4}>
            <Col>
              <h6>Column</h6>
            </Col>
            <Col>
              <h6>Value</h6>
            </Col>
          </Row>
          
          
          {render_tag_table()}
        </Row>
        <Row>
          <Col>
            <h6 className='hover_underline' onClick={handleOpen}>Add New +</h6>
          </Col>
        </Row>

        {render_add_modal()}
        {render_remove_modal()}
        
          

        
    </Container>
  )
    
    
  


 }

  
    