import React from "react";
//import {humanizeString } from "./globals";
import {epoch_to_date_string, date_string_to_epoch,isna, send_request} from "../globals/globals";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef } from "react";
import loading_gif from "../globals/loading_gif";
import { flushSync } from "react-dom";


export function get_calendar_selected_date(selected_date, calendar, selected_calendar_type){
  //selected_date must be epoch

  var cal = calendar[selected_calendar_type]
  for(var i=0;i<cal.length;i++){
    var row = cal[i] 
    var end_date_epoch = date_string_to_epoch(row['end_date'])

    if(end_date_epoch <= selected_date){
      return {'value': row['end_date'], 'text': row['label'], 'calendar_index': i-1}
    }
  }

  return {}
}




export const  SideMenu = (props) =>{
  // Values Accepted for:
  //This controls which dates are populated into the dropdown
  // calendar_dropdown_to_fill: 'last_transaction_and_prior'  

  const [hasSentFiltersApi, setHasSentFiltersApi] = useState(false)
  const [hasSentCalendarApi, setHasSentCalendarApi] = useState(false)

  const prev_props = useRef();


useEffect(() => {
  
  //Apis
  if((props.selected_calendar_date === null || props.filters === null) &&  !hasSentFiltersApi){
    get_filters()
    setHasSentFiltersApi(true)
  }
  else if((props.selected_calendar_date === null || props.calendar === null) &&  !hasSentCalendarApi){
    get_calendar()
    setHasSentCalendarApi(true)
  }
  //If Apis have been called then set cohort filter id
  else if(props.filters !== null && props.calendar !== null && props.cohort_filter_id === null && Object.keys(props.filters).length > 0){
    
    var cohort_filter_id = window.localStorage.getItem('cohort_filter_id') === null ? Object.keys(props.filters)[0] : window.localStorage.getItem('cohort_filter_id')
    var max_transaction_date = props.filters[cohort_filter_id]['max_transaction_date']

    var selected_date = get_calendar_selected_date(max_transaction_date, props.calendar, props.selected_calendar_type)
    var firstTransaction = props.filters[cohort_filter_id]['min_transaction_date']
    var lastTransaction = props.filters[cohort_filter_id]['max_transaction_date']
    
    cohort_filter_id = parseInt(cohort_filter_id)
    set_storage(cohort_filter_id, selected_date, firstTransaction, lastTransaction, props.selected_calendar_type)
    
    flushSync(
      props.setCohortFilterID(cohort_filter_id),
      props.setFirstTransaction(firstTransaction),
      props.setLastTransaction(lastTransaction),
      props.setSelectedCalendarDate(selected_date)
    )
  }
  
  //Handle changes
    //Calendar Type
    else if(prev_props.selected_calendar_type !== props.selected_calendar_type && props.selected_calendar_date !== null){
      
      var selected_calendar_type = props.selected_calendar_type
      var parsed_date = date_string_to_epoch(props.selected_calendar_date['value'])    
      
      var selected_date = get_calendar_selected_date(parsed_date, props.calendar, selected_calendar_type)
      
      prev_props.selected_calendar_type = selected_calendar_type
      props.setSelectedCalendarDate(selected_date)
      
    }
    //Filter -> cohort_filter_id change
    else if(prev_props.cohort_filter_id !== props.cohort_filter_id && props.cohort_filter_id !== null){
      var max_transaction_date = props.filters[props.cohort_filter_id]['max_transaction_date']
      
      var selected_calendar_type = props.selected_calendar_type
      var current_date = Date.parse(props.selected_calendar_date['value'])
      var date_to_use = max_transaction_date < current_date ? max_transaction_date : current_date
    
      
      
      var selected_date = get_calendar_selected_date(date_to_use, props.calendar, selected_calendar_type)
      var firstTransaction = props.filters[props.cohort_filter_id]['min_transaction_date']
      var lastTransaction = props.filters[props.cohort_filter_id]['max_transaction_date']
      set_storage(props.cohort_filter_id, selected_date, firstTransaction, lastTransaction, selected_calendar_type)
      
      flushSync(
        props.setFirstTransaction(firstTransaction),
        props.setLastTransaction(lastTransaction),
        props.setSelectedCalendarDate(selected_date)
      )

    }
    //date -> selected_calendar_date change
    else if(prev_props.selected_calendar_date !== props.selected_calendar_date && props.selected_calendar_date !== null){   
      set_storage(props.cohort_filter_id, props.selected_calendar_date, props.firstTransaction, props.lastTransaction, props.selected_calendar_type)
    }


});




function set_storage(cohort_filter_id, selected_calendar_date, firstTransaction, lastTransaction, selected_calendar_type){
  window.localStorage.setItem('lastTransaction', lastTransaction)
  window.localStorage.setItem('firstTransaction', firstTransaction)
  window.localStorage.setItem('cohort_filter_id', cohort_filter_id)
  window.localStorage.setItem('selected_calendar_date',  JSON.stringify(selected_calendar_date))
  window.localStorage.setItem('selected_calendar_type',  selected_calendar_type)

  prev_props.cohort_filter_id = parseInt(cohort_filter_id)
  prev_props.selected_calendar_type = selected_calendar_type
  prev_props.selected_calendar_date = selected_calendar_date


}



 
// api calls
async function get_filters() {
  var body = ''
  var response = ''
  try{
    body = { 
      path:'side_menu',
      func:"get_cohort_filters"
    }
    
    response = await send_request(body)
    let json_response = JSON.parse(response)
    window.localStorage.setItem('filters', JSON.stringify(json_response))

    flushSync(
      props.setFilters(json_response),
      setHasSentFiltersApi(false)
    )
    
  }
  catch(err){
    console.log('getFilters body: ' , body)
    console.log('getFilters response: ' , response)
    throw new Error(err);
  }
}


async function get_calendar() {
  var body = ''
  var response = ''
  try{
    body = { 
      path:'side_menu',
      func:"get_calendar_date_types"
    }
    response = await send_request(body)  
    
    var calendar = JSON.parse(response)
    var calendar_types = Object.keys(calendar)
    for(var i=0;i<calendar_types.length;i++){
      calendar[calendar_types[i]] = JSON.parse(calendar[calendar_types[i]])
    }

    window.localStorage.setItem('calendar', JSON.stringify(calendar))

    flushSync(
      props.setCalendar(calendar),
      setHasSentCalendarApi(false)
    )

    return calendar
  }
  catch(err){
    console.log('get calendar body: ' , body)
    console.log('get_calendar response: ', calendar)
    throw new Error(err)
  }
}





//Rendering
function populate_filters(options) {
  
  var eles = []
  for(var i=0;i<Object.keys(options).length;i++){
    var cohort_filter_id = Object.keys(options)[i]
    var cohort_filter = options[cohort_filter_id]
    eles.push(
      <React.Fragment>
        <option value={cohort_filter_id} key={cohort_filter['nice_name']}>{cohort_filter['nice_name']}</option>
      </React.Fragment>
    )
  }

  return eles
}

function populate_calendar() {
  
  if(props.calendar[props.selected_calendar_type].length === null ){
    return
  }
  var side_menu_calendar_dates = []
  var i = 0
  
  
    var selected_calendar_type = props.selected_calendar_type
  
  
  while(i < props.calendar[selected_calendar_type].length){
    var t_date = props.calendar[selected_calendar_type][i]
    var end_date = Date.parse(t_date['end_date'])
    if(props.calendar_dropdown_to_fill === 'last_transaction_and_prior'){
      
      if(end_date <= props.lastTransaction){
        side_menu_calendar_dates.push(t_date)
      }
    }
    else{
      side_menu_calendar_dates.push(t_date)
    }
    
    i += 1
  }

 
  return side_menu_calendar_dates.map((option) => (
    <option value={option.end_date} key={option.end_date}>{option.label}</option>
  ));
}





// On Change
const change_cohort_filter_id = (e) =>{
  var cohort_filter_id = parseInt(e.target.value)
  var filter = props.filters[cohort_filter_id]


  flushSync(
    props.setLastTransaction(filter['max_transaction_date']),
    props.setFirstTransaction(filter['min_transaction_date']),
    props.setCohortFilterID(cohort_filter_id)
  )
  
}

function change_calendar_type(e){
  props.setSelectedCalendarType(e.target.value)
}

const change_calendar_date = (e) =>{
  // We need to cycle throught the calendar to get the pretty text.
  var text = undefined
  for(var i=0; i<props.calendar[props.selected_calendar_type].length;i++){
    if(props.calendar[props.selected_calendar_type][i]['end_date'] === e.target.value){
      text = props.calendar[props.selected_calendar_type][i]['label']
      break
    }
  }
  
  props.setSelectedCalendarDate({'value':e.target.value, 'text': text})
}






function render_side_menu(){
    if(props.cohort_filter_id === null || props.selected_calendar_date === null){
      return(
        loading_gif('Loading Filters')
      )
    }




    //Date Selector
    var date_selector_text = 'Date:'
    var className_calendar = "form-select float-start"
    var className_date_selector_text = "h6 float-end align-items-center"
    var date_selected_disabled = false
    if(['page_what_if'].includes(props.parent_component)){ // Pages to hide the date on
      date_selector_text = 'As Of:'
      className_calendar = 'd-none'
      className_date_selector_text = 'd-none'
      date_selected_disabled = true
    }
    


      var selected_calendar_date_value = props.selected_calendar_date['value']
    

    
    return(
      <React.Fragment>
              <Col className="p-0" lg="auto">
                <p className="h6 float-end align-items-center">Filter:</p>
              </Col>
              <Col lg="2">
                <select className="form-select float-start" id='sidemenu_filters'  value={props.cohort_filter_id} onChange={change_cohort_filter_id}  >
                  {populate_filters(props.filters)}
                </select>
              </Col>
              <Col lg="auto" className="p-0">
                  <p className="h6 float-end align-items-center">Period:</p>
              </Col>
              <Col lg="2">
                <select className="form-select float-start" id='sidemenu_calendar_types' value={props.selected_calendar_type} onChange={change_calendar_type} >
                  <option value='week_end_445'>Weekly</option>
                  <option value='month_end_445'>Monthly</option>
                  <option value='quarter_end_445'>Quarterly</option>
                  <option value='year_end_445'>Yearly</option>
                </select>
              </Col>
              <Col  lg="1" className="p-0">
              <p className={className_date_selector_text}>{date_selector_text}</p>
              </Col>
              <Col  lg="2">
                <select className={className_calendar} id='sidemenu_calendar_date' value={selected_calendar_date_value} onChange={change_calendar_date} disabled={date_selected_disabled}>
                  {populate_calendar()
                  }
                </select>
              </Col>
      </React.Fragment>
    )
}





  return (
    <React.Fragment>
          
            <Row>
              {render_side_menu()}
            </Row>
          
          
            
          
      
 
 </React.Fragment>


)


}






