import React, { Component } from "react";
import TopMenu from "../top_menu/TopMenu";
import {PageDashboard} from "../dashboard/dashboard";
import {AdminDashboard} from "../admin/AdminDashboard";
import {send_request} from "../globals/globals";
import { Routes,  Route } from "react-router-dom";
import Auth from "@aws-amplify/auth";
  

import {PageWhatIf} from "../page_what_if/page_what_if";
import {PageDriversAndDrags} from "../page_drivers_and_drags/page_drivers_and_drags";



import loading_gif from "../globals/loading_gif";
import { PageSettings } from "../settings/settings";
import { PageDataUploads, PageTransactionFiles } from "../settings/transaction_files/transaction_files";
import { PageTransactionFileInfo } from "../settings/transaction_file_info/transaction_file_info";
import { PageAdSpend } from "../settings/ad_spend/ad_spend";
import { PageFXRates } from "../settings/fx_rate/fx_rates";
import { PageCompanyFilters } from "../settings/company_filters/page_company_filters";
import { PageManageSlices } from "../settings/slices/manage_slices";
import { PageManageCohortFilters } from "../settings/cohort_filters/manage_cohort_filters";
import { PageWhatIfSettings } from "../settings/what_if/what_if_settings";
import { PageUploadTransactionLineItems } from "../settings/transaction_files/upload/upload_transaction_line_items";
import { UploadAdSpend } from "../settings/ad_spend/upload_ad_spend";
import { PageShopifyApis } from "../settings/shopify_apis/shopify_apis";
import { PageMarketing } from "../marketing/marketing";
import { AdminCompanyDetails } from "../admin/admin_company_details/AdminCompanyDetails";




class RootView extends Component {
  constructor(){
    super();
    //console.log('storage', window.localStorage.getItem('user'))
    this.state = JSON.parse(window.localStorage.getItem('user')) || {
      loggedIn: true, 
      user_id: null, 
      email: null, 
      first_name:null, 
      last_name:null, 
      is_admin:null,
      is_owner:null
    }
    
  }
  

  handleLogInOut = () => {
    console.log('handleLogInOut')
    this.setState({ loggedIn: !this.state.loggedIn });
  };

  handleSession = () => {
    //console.log('window.localStorage.getItem(\'user\')', window.localStorage.getItem('user'))
    console.log('state: ', this.state)
    if (this.state.user_id == null || this.state.is_admin == null) {
      Auth.currentAuthenticatedUser().then((user) => {
        this.get_user_info(user.attributes.sub, user.attributes.email, user).then((user_info) =>{
          
        console.log('user_info:', user_info)
        if(user_info === undefined){return}
        var state = {
          user_id: user_info['user_id'], 
          email: user_info['email'], 
          first_name:user_info['first_name'], 
          last_name:user_info['last_name'], 
          is_admin: user_info['is_admin'],
          is_owner: user_info['is_owner'],
        }
        //Set Cookies
        window.localStorage.setItem('user', JSON.stringify(state))

        super.setState(state)

        })

        


        


      });
    }
  };

  componentDidMount() {
    this.handleSession();
  }


  async get_user_info(sub, email){
    const body = {
      path:"users",
      func:"get_user_info",
      user_id: sub,
      email: email
    };
    
    return send_request(body, 'get_user_info body:').then((response) =>{
      console.log('get_user_info response:', response)
      
      try{
      response = JSON.parse(response)
      response = response[0]
      return response
      }
      catch(e){
        console.log('get_user_info, invalid json returned: ', response)
      }
      

      
    })
  }

  



  render() {
    if(window.localStorage.getItem('user') === null){
      return loading_gif('Loading User Info')
    }

    
    return (
      <React.Fragment>
        <TopMenu
          key={this.state.loggedIn}
          loggedIn={this.state.loggedIn} 
          first_name={this.state.first_name}
          is_admin={this.state.is_admin}
          onLogInOut={this.handleLogInOut}
        />
        
        <Routes>
          <Route path="/" element={<PageDashboard />} exact />
          <Route path="/dashboard" element={<PageDashboard />} exact />
          
          <Route path="/admin_dashboard" element={<AdminDashboard/>} exact/>
          <Route path="/admin_company_details" element={<AdminCompanyDetails/>} exact/>

          <Route path="/what_if" element={<PageWhatIf/>} exact/>
          <Route path="/drivers_and_drags" element={<PageDriversAndDrags /> } exact/>
          <Route path="/marketing" element={<PageMarketing /> } exact/>
          

          <Route path="/settings" element={<PageSettings /> } exact/>
          <Route path="/settings/transaction_files" element={<PageTransactionFiles /> } exact/>
          <Route path="/settings/transaction_file_info" element={<PageTransactionFileInfo /> } exact/>
          <Route path="/settings/transaction_files/upload_transaction_line_items" element={<PageUploadTransactionLineItems props={this.state} /> } exact/>
          <Route path="/settings/shopify_apis" element={<PageShopifyApis /> } exact/>

          <Route path="/settings/ad_spend" element={<PageAdSpend /> } exact/>
          <Route path="/settings/ad_spend/upload_ad_spend" element={<UploadAdSpend /> } exact/>

          <Route path="/settings/fx_rates" element={<PageFXRates /> } exact/>

          <Route path="/settings/company_filters" element={<PageCompanyFilters /> } exact/>
          

          <Route path="/settings/slices" element={<PageManageSlices /> } exact/>
          <Route path="/settings/cohort_filters" element={<PageManageCohortFilters /> } exact/>
          <Route path="/settings/what_if_settings" element={<PageWhatIfSettings /> } exact/>


        </Routes>
          
          
          
        
      </React.Fragment>
    );
  }
}




export default RootView;


/*

          <Route path="/uploadmappings" component={UploadMappings} />
          <Route
            path="/bo/upload"
            render={(props) => <UploadDialog bo={true} />}
          />
          <Route path="/keyinsights" component={KeyInsights} />
          <Route path="/upload" component={UploadDialog} />

          <Route path="/newcustomers" component={NewCustomers}/>
          <Route path="/newcustomerquality" component={NewCustomerQuality}/>
          <Route path="/customerexperience" component={CustomerExperience}/>

          */