import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {send_request, get_color, isna} from "../globals/globals";
import img_dollar_sign from "../images/dollar_sign.svg";
import loading_gif from "../globals/loading_gif";
import { useState } from "react";
import { useEffect } from "react";
import { format_dollar, format_percent, get_job_status_step_counter } from "../globals/globals";
import { useRef } from "react";
import { flushSync } from "react-dom";
export const  Tiles = (props) =>{
        
  
    
    /*{'revenue':{'yoy':null, 'val':null, 'diff':null},
      'this_period_projection':{'yoy':null, 'val':null, 'diff':null},
      'compare_last_period':{'pop':null, 'val':null, 'diff':null}}*/

  
  //Tiles API
  const [sentTilesApi, setSentTilesApi] = useState(false)
  const [tilesDict, setTilesDict] = useState(null)

  //Job Status
  const [job_status, setJobStatus] = useState('')
  const [sentJobStatusApi, setSentJobStatusApi] = useState(false)
  const [jobStatusSentCount, setJobStatusSentCount] = useState(0)
  const [jobStepCounter, setJobStepCounter] = useState(-1)
  

  const prev_props = useRef();

  var colors = {'good': '87ff63', 'bad':'ff6384', 'upper_bound':0.05, 'lower_bound':-0.05}
     



  //Check job_status


  useEffect(() => {
    
    try{
    
    //Set Initial Prev Props
    if(props.cohort_filter_id !== null && prev_props.cohort_filter_id === undefined){
      prev_props.cohort_filter_id = props.cohort_filter_id
    }
    if(props.selected_calendar_type !== null && prev_props.calendar_type === undefined){
      prev_props.calendar_type = props.selected_calendar_type
    }
    if(props.selected_calendar_date !== null && prev_props.as_of === undefined){
      prev_props.as_of = props.selected_calendar_date
    }


    //Changes
    var set_tiles_dict_to_null = false
    if(props.cohort_filter_id !== prev_props.cohort_filter_id){
      prev_props.cohort_filter_id = props.cohort_filter_id
      set_tiles_dict_to_null = true
    }
    if(props.selected_calendar_type !== prev_props.selected_calendar_type){
      prev_props.selected_calendar_type = props.selected_calendar_type
      set_tiles_dict_to_null = true
    }
    if(props.selected_calendar_date['value'] !== prev_props.selected_calendar_date){
      prev_props.selected_calendar_date = props.selected_calendar_date['value']
      set_tiles_dict_to_null = true
    }

    // Set to null if the menu changed
    if(set_tiles_dict_to_null){
      setTilesDict(null)
      return
    }
    // Get dict if it is currently null
    else if(tilesDict === null && !sentTilesApi){
      get_tiles()
      setSentTilesApi(true)
      return
    }


    // Check if we need to get job status
    /*
    var parent_job_status_id = null
    if(tilesDict !== null && Object.keys(tilesDict).length !== 0){
      if(Object.keys(tilesDict).includes('generating_projections')){

        //This Period
        if(Object.keys(tilesDict['generating_projections']).includes('this_period') && tilesDict['generating_projections']['this_period'] !== null){
          if(Object.keys(tilesDict['generating_projections']['this_period']).includes('parent_job_status_id') && tilesDict['generating_projections']['this_period']['parent_job_status_id'] !== null){
            parent_job_status_id = tilesDict['generating_projections']['this_period']['parent_job_status_id']
          }
          else if(Object.keys(tilesDict['generating_projections']['this_period']).includes('message') && tilesDict['generating_projections']['this_period']['message'] !== null){
            setJobStatus(tilesDict['generating_projections']['this_period']['message'])
          }
        }

        //Last Period
        else if(Object.keys(tilesDict['generating_projections']).includes('last_period') && tilesDict['generating_projections']['last_period'] !== null){
          if(Object.keys(tilesDict['generating_projections']['last_period']).includes('parent_job_status_id') && tilesDict['generating_projections']['last_period']['parent_job_status_id'] !== null){
            parent_job_status_id = tilesDict['generating_projections']['last_period']['parent_job_status_id']
          }
          else if(Object.keys(tilesDict['generating_projections']['last_period']).includes('message') && tilesDict['generating_projections']['last_period']['message'] !== null){
            setJobStatus(tilesDict['generating_projections']['last_period']['message'])
          }
        }
      }
    }
    

    
    
    //We are generating projections, we'll check the job status id
    if(parent_job_status_id !== null && jobStatusSentCount < 90){ 
        const interval = setInterval(() => {
          const fetchData = async () => {
          
            const body = { 
              path:'job_status',
              func:"get_job_status",
              cohort_filter_id: props.cohort_filter_id,
              parent_job_status_id: parent_job_status_id,
              calendar_type: props.selected_calendar_type
            }
            console.log('jobStatusSentCount:', jobStatusSentCount)
            console.log('job_status body', body)
            var response = await send_request(body)
            console.log('job_status response', response)
            if(response['status'] === 'finish' || response['status'] === 'no job found'){
                flushSync(
                  setTilesDict(null),
                  setJobStatus(response['status']),
                  setJobStepCounter(response['step_count']),
                  setJobStatusSentCount(jobStatusSentCount + 1),
                  setSentJobStatusApi(false)
                )
            }
            else{
              flushSync(
                setJobStatus(response['status']),
                setJobStepCounter(response['step_count']),
                setJobStatusSentCount(jobStatusSentCount + 1)
              )
            }
          
          }
  
          fetchData().catch(console.error);// make sure to catch any error
          

        }, 2000);

        
        return () => clearInterval(interval);
      
        
      }
       

      */
     
    }

    
   
    catch(err){
      debugger
      throw new Error(err)
    }

    
    
  });


  


  //api calls
  async function get_tiles(){
    var body = ''
    var response = ''
    try{
      body = {
        path:"dashboard",
        func:"get_actuals_and_projections",
        as_of: props.selected_calendar_date['value'],
        calendar_type: props.selected_calendar_type,
        cohort_filter_id:props.cohort_filter_id
      };
      
      response = await send_request(body)
      response = JSON.parse(response)
      console.log('get_tiles body:', body)
      console.log('get_tiles response:', response)
      
      flushSync(
        setSentTilesApi(false),
        setTilesDict(response)
      )
      
      
    }
    catch(err){
      console.log('get_tiles body:', body)
      console.log('get_tiles response:', response)
      debugger
      throw new Error(err)
    }
    
  }
      

  //Helper Functions
  function get_background_color(val){
      if(val == null){
        return ''
      }
      
      var ratio = null
      var color = null
      if(val > 0){
        ratio = val/colors['upper_bound']
        color = get_color(ratio, colors['good'], 'ffffff')
      }
      else{
        ratio = val/colors['lower_bound']
        color = get_color(ratio, colors['bad'], 'ffffff')
      }
      return '#' + color + '50'
  }


  //Rendering
  function render_cards(){    
    return(
      <React.Fragment>
          <Row>
            {render_actuals()
            }
            {
            //render_projections()
          }
          </Row>
        
      </React.Fragment>
    )
  }
    

  function render_actuals(){
    if(tilesDict === null){
      return(
        <React.Fragment>
          <Row>
            <Col className="">
              {loading_gif('Gathering Actuals')}
            </Col>
          </Row>
        </React.Fragment>
      )
    }

    return(
      <React.Fragment>
        <Col lg={4} className="card float-start pb-2"  style={{backgroundColor: get_background_color(tilesDict['revenue']['yoy'])}}>
              <Row>
                <Col>
                  <h5 className="mt-2 mb-0">Actual</h5>
                  <h6 className="">{props.selected_calendar_date['text']}</h6>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <Row>
                      <Col><h6 className="mb-0 align-items-center">Revenue</h6></Col>
                  </Row>
                  <Row>
                      <Col><h4 className="mb-0 align-items-center">{format_dollar(tilesDict['revenue']['val'], false)}</h4></Col>
                  </Row>
                </Col>

                <Col  lg={3}>
                  <Row>
                    <Col><h6 className="mb-0 align-items-center">Vs. LY</h6>  </Col>
                  </Row>
                  <Row>
                    <Col><h6 className="mb-0 align-items-center mt-1">{format_percent(tilesDict['revenue']['yoy'], true)}</h6></Col>
                  </Row>  
                </Col>
              </Row>
            </Col>
      </React.Fragment>
    )
  }

  function render_projections(){
    if(tilesDict === null){
      return(
        <React.Fragment>
          <Row>
            <Col className="">
              {loading_gif('Gathering Projections')}
            </Col>
          </Row>
        </React.Fragment>
      )
    }

    else if(jobStatusSentCount >= 60){
      return(
        <React.Fragment>
            <Col className="">
              Please Refresh to Load
            </Col>
        </React.Fragment>
      )
    }
    else if(job_status === 'Not enough data to perform cohort forecasts.'){
      return(
        <React.Fragment>
            <Col className="text-center">
              <h6>The selected filter does not have <br />enough data to generate a projection.</h6>
            </Col>
        </React.Fragment>
      )
    }
    else if(tilesDict['generating_projections'] === null || tilesDict['generating_projections']['this_period'] !== null || tilesDict['generating_projections']['last_period'] !== null){
      if(job_status === null){
        var text_job_status = 'Gathering Projections'
      }  
      else{
        var text_job_status = job_status
      }
      return(
          <React.Fragment>
              <Col>
                {loading_gif(text_job_status, 'float-start ms-3 mt-3')}
              </Col>
          </React.Fragment>
        )
    }
    else if(tilesDict['this_period_projection']['val'] === null){
      return(
        <React.Fragment>
            <Col>
              {loading_gif('Gathering Projections', 'float-start ms-3 mt-3')}
            </Col>
        </React.Fragment>
      )
  }
    


    var vs_last_period_classname = ''
    if(tilesDict['compare_last_period']['pop'] === 0){
      vs_last_period_classname = 'd-none'
    }
    return(
      <React.Fragment>
        <Col lg={6} className="card float-start ms-3 pb-2" style={{backgroundColor: get_background_color(tilesDict['this_period_projection']['yoy'])}}> 
              <Row>
                  <h5 className="mt-2 mb-0 pb-0">Projection</h5>  
                  <h6 className="mt-auto">End of Fiscal Year</h6>
              </Row>
              <Row>
                <Col lg={4}>
                  <Row>
                    <Col><h6 className="mb-0 align-items-center">Revenue</h6></Col>
                  </Row>
                  <Row>
                    <Col><h4 className="mb-0 align-items-center">{format_dollar(tilesDict['this_period_projection']['val'], false)}</h4></Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col><h6 className="mb-0 align-items-center">Vs. LY</h6></Col>
                  </Row>
                  <Row>
                    <Col><h6 className="mb-0 align-items-center mt-1">{format_percent(tilesDict['this_period_projection']['yoy'], true)}</h6></Col>
                  </Row>
                </Col>
                <Col lg={4} className={vs_last_period_classname}>
                  <Row>
                    <Col><h6 className="mb-0 align-items-center">Vs. Last Period</h6></Col>
                  </Row>
                  <Row>
                    <Col><h6 className="mb-0 align-items-center mt-1">{format_percent(tilesDict['compare_last_period']['pop'])}</h6></Col>
                  </Row>
                </Col>
              </Row>
            </Col>
      </React.Fragment>
    )
  }


  var projection_warning = <p>Projection using current projection inputs.</p>
  if(props.lastTransaction === props.selected_calendar_date['value']){
    projection_warning = ''
  }


  return (
      <React.Fragment>
            <Col className="float-start pb-3">
          <Row>
            <Col>
              <img alt='dollar sign' src={img_dollar_sign} className='float-start pt-4'/>
              <p className="float-start display-6 ms-3 mt-2">Revenue</p>
            </Col>
          </Row>
          <Row>
            <Col>
              {projection_warning}
            </Col>
          </Row>
          {render_cards()}

          </Col>
      </React.Fragment>


      )
  










}