import "./App.css";
import RootView from "./rootView";
import React from "react";
import {Amplify} from "aws-amplify";
import awsExports from "../../aws-exports";

import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { PageLogin } from "../login/login";


Amplify.configure(awsExports);



export const  App = (props) =>{
  var t_authState = window.localStorage.getItem('authState') === null ? '' : window.localStorage.getItem('authState')
  console.log('t_authstate:', t_authState)
  const [authState, setAuthState] = useState(t_authState)
  console.log('authstate: ', authState)

  
  return (
    <React.Fragment>
      { !['signedin'].includes(authState) ?
        <PageLogin authState={authState} setAuthState={setAuthState} />
      :
        <RootView />
      } 
    </React.Fragment>
  );
}


