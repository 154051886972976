
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SideMenu } from '../side_menu function/SideMenu.jsx';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../globals/globals.js';
import loading_gif from "../globals/loading_gif";
import btn_right_arrow from "../images/btn_right_arrow.svg";
import btn_left_arrow from "../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';


export const  PageSettings = (props) =>{
  const [companyName, setCompanyName] = useState(null)
  const [sentCompanyNameApi, setSentCompanyNameApi] = useState(null)





  //Check job_status
  useEffect(() => {
    if(companyName === null && !sentCompanyNameApi){
      get_company_name()
      setSentCompanyNameApi(true)
    }
   

  })

  async function get_company_name(){
    var body = ''
    var response = ''
    try{
      body = { 
        path:'company',
        func:"get_company_name"
      }
      
      response = await send_request(body)
      setCompanyName(response['company_name'])
    } 
    catch(err){
      console.log('get_company_name body', body)
      console.log('get_company_name response', response)
      throw new Error(err)
    }
  }


  async function signOut() {
    try {
        console.log('unsetting localstorage during signout')
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('state')
        window.localStorage.removeItem('authState')

        //Side menu
        window.localStorage.removeItem('filters')
        window.localStorage.removeItem('calendar')
        window.localStorage.removeItem('firstTransaction')
        window.localStorage.removeItem('lastTransaction')
        window.localStorage.removeItem('selected_calendar_date')
        window.localStorage.removeItem('cohort_filter_id')


        
        Auth.signOut().then(data =>{
          window.location.reload(false);
        });
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }


  function render_settings_header(){
    var title = "Settings"
    if(companyName !== null){
      title = 'Settings - ' + companyName
    }

    return(
      <React.Fragment>
        <Row>
          <Col lg ="auto">
            <h1>{title}</h1>
          </Col>
        </Row>
      </React.Fragment>
    )
  }



  //Output

  return(
    <Container lg={800}>
      
        {render_settings_header()}
        <Row>
          <Col lg ="auto">
            <h4>Data Uploads:</h4>
            <Link to='transaction_files' className='text-dark'><h6 className='ms-2'>Transaction Files</h6></Link>
            <Link to='shopify_apis' className='text-dark'><h6 className='ms-2'>Shopify Apis</h6></Link>
            <Link to='ad_spend' className='text-dark'><h6 className='ms-2'>Ad Spend</h6></Link>
            <Link to='fx_rates' className='text-dark'><h6 className='ms-2'>Foreign Exchange Rate</h6></Link>

            <h4 className='mt-5'>Segmentation:</h4>
            <Link to='slices' className='text-dark'><h6 className='ms-2'>Slices</h6></Link>
            <Link to='cohort_filters' className='text-dark'><h6 className='ms-2'>Cohort Filters</h6></Link>

            <h4 className='mt-5'>Algorithm Defaults:</h4>
            <Link to='what_if_settings' className='text-dark'><h6 className='ms-2'>What-If</h6></Link>

            
            <h6 className='mt-5 hover_underline' onClick={signOut}><u>Signout</u></h6>
          </Col>
        </Row>

        
    </Container>
  )
    
    
  


 }

  
    