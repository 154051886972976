
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';


export const  PageTransactionFiles = (props) =>{
  const [files, setFiles] = useState(null)
  const [sentFilesApi, setSentFilesApi] = useState(false)


  const [sentIsEQProcessingApi, setSentGetIsEQProcessingApi] = useState(false)
  const [isProcessing, setIsProcessing] = useState(null)
  


  //Check job_status
  useEffect(() => {
    if(files === null && !sentFilesApi){
      get_files()
      setSentFilesApi(true)
    }
    else if(isProcessing === null && !sentIsEQProcessingApi){
      check_if_eq_algo_processing()
      setSentGetIsEQProcessingApi(true)
    }
   

  })

  //Api Calls
  async function get_files(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"get_files"
      }
      
      response = await send_request(body)
      setFiles(response)
      
    } 
    catch(err){
      console.log('get_files body', body)
      console.log('get_files response', response)
      throw new Error(err)
    }
  }

  async function check_if_eq_algo_processing(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"check_if_eq_algo_processing"
      }
      
      response = await send_request(body)
      setIsProcessing(response)
      
    } 
    catch(err){
      console.log('check_if_eq_algo_processing body', body)
      console.log('check_if_eq_algo_processing response', response)
      throw new Error(err)
    }
  }


  function render_body(){
    if(files === null){
      return(
        loading_gif('Loading Files')
      )
    }

    var count_unprocessed = 0
    var rows = []
    for(var i = 0; i<Object.keys(files).length;i++){
      var data_input_translation_id = Object.keys(files)[i]
      var file_info = files[data_input_translation_id]
      
      
      var min_date = ''
      if(file_info['min_date'] !== null){
        var min_date = file_info['min_date'].split('-')
        min_date = min_date[1] + '/' + min_date[2] + '/' + min_date[0]
      }

      var max_date = ''
      if(file_info['max_date'] !== null){
        max_date = file_info['max_date'].split('-')
        max_date = max_date[1] + '/' + max_date[2] + '/' + max_date[0]
      }

      if(min_date === '' || max_date === ''){
        count_unprocessed += 1
      }
      
      var link = 
        <React.Fragment>
          <Link to='../settings/transaction_file_info' 
                    key={'link_'+file_info['data_input_translation_id']} 
                    state={{
                      file_name : file_info['file_name'],
                      data_input_translation_id : parseInt(data_input_translation_id),
                      min_date: min_date,
                      max_date: max_date,
                      kept_revenue: file_info['kept_revenue'],
                      kept_orders: file_info['kept_order_ids'],
                      kept_line_items: file_info['kept_line_items'],
                      removed_revenue: file_info['filtered_revenue'],
                      removed_orders: file_info['filtered_order_ids'],
                      removed_line_items: file_info['filtered_line_items'],
                    }}
                    >
                <p className='mb-1'>{file_info['file_name']}</p>
                </Link>
        </React.Fragment>
      
      if(!file_info['processing']){ //Most Common
        var t_ele = 
        <React.Fragment>
          <Row>
            <Col lg={5}>
              {link}
            </Col>
            <Col>
              <p className='mb-1'>{min_date}</p>
            </Col>
            <Col>
              <p className='mb-1'>{max_date}</p>
            </Col>
            <Col>
              <p className='mb-1'>{format_dollar(file_info['kept_revenue'], false)}</p>
            </Col>
          </Row>
        </React.Fragment>
      }
      else{
        
        var t_ele = 
        <React.Fragment>
          <Row>
            <Col lg={5}>
              {link}
            </Col>
            <Col>
              <p className='mb-1'>Processing...</p>
            </Col>
          </Row>
        </React.Fragment>
      }

      

      rows.push(t_ele)
    }

    
    var btn_process_all = ''
    if(count_unprocessed > 0){
      btn_process_all = 
        <React.Fragment>
          <Row className='mb-4'>
            <Col>
            <button className='btn btn-lg btn-warning mt-3'>Process All</button>
            </Col>
          </Row>
        </React.Fragment>
    }



    return(
      <React.Fragment>
        {btn_process_all}
        <Row>
          <Col lg={5}>
            <h5>File Name</h5>
          </Col>
          <Col>
            <h5>Min Date</h5>
          </Col>
          <Col>
            <h5>Max Date</h5>
          </Col>
          <Col>
            <h5>Revenue</h5>
          </Col>
        </Row>
        {rows}
      </React.Fragment>
    )

  }


  function render_is_eq_algo_processing(){
    
    if(isProcessing === null){
      return loading_gif('Fetching Processing Status')
    }
    else if(isProcessing !== null && Object.keys(isProcessing).includes('job_name')){
      return(<p>EQ Algorithm Step: {isProcessing['job_name']}</p>)
    }
    else if(isProcessing !== null && Object.keys(isProcessing).includes('is_eq_algo_processing') && !isProcessing['is_eq_algo_processing']){
      return(<p>Processing: Complete</p>)
    }

    
  }


  //Output
  return(
    <Container lg={800}>
        <Row>
          <Col lg ="auto">
            <h1>Backend Processing</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            {render_is_eq_algo_processing()}
          </Col>
        </Row>
        <Row>
          <Col lg ="auto">
            <h1>Transaction Files</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <Link to='upload_transaction_line_items'><button className='btn btn-lg btn-warning mt-3'>Upload</button></Link>
          </Col>
          
        </Row>
        <Row className='mt-4'>
          <Col lg ="auto">
            {render_body()}
          </Col>
        </Row>

        
    </Container>
  )
    
    
  


 }

  
    