
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { CompTransactionFileInfoSliceData } from './slice_data.jsx';
import { CompTransactionFileFilters } from './file_filters.jsx';
import { CompTransactionFileCompanyFilters } from './company_filters.jsx';
import { CompTransactionFileTags } from './tags.jsx';
import { CompTransactionFileColumnNames } from './column_names.jsx';


export const  PageTransactionFileInfo = (props) =>{
  const navigate = useNavigate();
  const [files, setFiles] = useState(null)
  
  
  let { state } = useLocation();
    //console.log('state:', state)

    //Passed from transaction_files.jsx
    const [dataInputTranslationId, setDataInputTranslationId] = useState(state['data_input_translation_id'])
    const [fileName, setFileName] = useState(state['file_name'])
    const [minDate, setMinDate] = useState(state['min_date'])
    const [maxDate, setMaxDate] = useState(state['max_date'])
    
    //Kept Line Items
    const [keptRevenue, setKeptRevenue] = useState(state['kept_revenue'])
    const [keptOrders, setKeptOrders] = useState(state['kept_orders'])
    const [keptLineItems, setKeptLineItems] = useState(state['kept_line_items'])
    
    // Filtered Out Line Items
    const [removedRevenue, setRemovedRevenue] = useState(state['removed_revenue'])
    const [removedOrders, setRemovedOrders] = useState(state['removed_orders'])
    const [removedLineItems, setRemovedLineItems] = useState(state['removed_line_items'])

    // Checking if File is currently processing.
    const [doAlreadyProcessingFileApi, setDoAlreadyProcessingFileApi] = useState(false)
    const [sentAlreadyProcessingFileApi, setSentAlreadyProcessingFileApi] = useState(false)
    const [isAlreadyProcessingFile, setisAlreadyProcessingFile] = useState(null)
    
    // File Processing
    const [isProcessingFile, setIsProcessingFile] = useState(false)
    const [sentProcessFileApi, setSentProcessFileApi] = useState(false)

    // Deleting File
    const [doDeleteFile, setDoDeleteFile] = useState(false)
    const [sentDeleteFileApi, setSendDeleteFileApi] = useState(false)
    


  //Check job_status
  useEffect(() => {
    //debugger
    if((minDate === undefined || minDate === '') && !doAlreadyProcessingFileApi){
      setDoAlreadyProcessingFileApi(true)
    }
    else if(doAlreadyProcessingFileApi && !sentAlreadyProcessingFileApi){
      check_if_already_processing_file()
      setSentAlreadyProcessingFileApi(true)
    }
    else if(isProcessingFile && !sentProcessFileApi){
      process_file()
      setSentProcessFileApi(true)
    }
    else if(doDeleteFile && !sentDeleteFileApi){
      delete_file()
      setSendDeleteFileApi(true)
    }

  })

  async function check_if_already_processing_file(){
    var body = ''
        var response = ''
        try{
            body = {
            path:"transaction_files",
            func:"check_if_file_is_processing",
            data_input_translation_id: dataInputTranslationId
            };
            
            let response = await send_request(body)
            if(Object.keys(response).includes('stats')){  //File has been processed and has stats.
              var stats = response['stats']
              flushSync(
                setMinDate(stats['min_date']),
                setMaxDate(stats['max_date']),

                setKeptRevenue(stats['kept_revenue']),
                setKeptOrders(stats['kept_orders']),
                setKeptLineItems(stats['kept_line_items']),

                setRemovedRevenue(stats['removed_revenue']),
                setRemovedOrders(stats['removed_orders']),
                setRemovedLineItems(stats['removed_line_items']),

                setDoAlreadyProcessingFileApi(false),
                setSentAlreadyProcessingFileApi(false),
                setIsProcessingFile(false),
                setSentProcessFileApi(false)

              )
            }
            else{
              setisAlreadyProcessingFile(response['is_processing'])
            }
            
            
        }
        catch(err){
            console.log('check_if_already_processing_file body:', body)
            console.log('check_if_already_processing_file response:' , response)
            throw new Error(err);
        }
  }

  async function process_file(){
    var body = ''
    var response = ''
    try{
        body = {
        path:"data_input_translation",
        func:"upload_all_files_to_transaction_line_items",
        data_input_translation_id: dataInputTranslationId
        };
        debugger
        let response = await send_request(body)
        console.log(response)
        
    }
    catch(err){
        console.log('process_file body:', body)
        console.log('process_file response:' , response)
        throw new Error(err);
    }
  }

  async function delete_file(){
    var body = ''
    var response = ''
    try{
        body = {
        path:"data_input_translation",
        func:"delete_file",
        data_input_translation_id: dataInputTranslationId
        };
        debugger
        let response = await send_request(body)
        debugger
        navigate("/settings/transaction_files");
        
    }
    catch(err){
        console.log('delete_file body:', body)
        console.log('delete_file response:' , response)
        throw new Error(err);
    }
  }

  function render_file_stats(){
    if(doDeleteFile){
      return 
    }
    if(doAlreadyProcessingFileApi && isAlreadyProcessingFile === null){
      return loading_gif('Is File Processing?')
    }
    else if(isAlreadyProcessingFile){
      return loading_gif('File Still Processing')
    }
    else if(isAlreadyProcessingFile === 'processing' || isProcessingFile){
      return loading_gif('Processing File')
    }
    
    if(minDate === undefined || minDate === ''){
      return( 
        <React.Fragment>
          <Row>
            <Col>
              <h6 className='mt-4'>File has not been processed.</h6>  
            </Col>
          </Row>
          <Row>
            <Col>
              <button onClick={() => {setIsProcessingFile(true)}} className='btn btn-lg btn-warning mt-3'>Process</button>
            </Col>
          </Row>
        </React.Fragment>
      )
    }

    
    return(
      <React.Fragment>
        <Row className='mt-4'>
          <Col>
            <h4>Calculated Stats</h4>
          </Col>
        </Row>
        <Row>
          <Col lg ="auto">
            <h6>Dates: {minDate} - {maxDate}</h6>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <Row className='mt-3'>
              <Col></Col>
              <Col>Kept</Col>
              <Col>Filtered Out</Col>
            </Row>

            <Row>
              <Col>Revenue</Col>
              <Col>{format_dollar(keptRevenue,false)  }</Col>
              <Col>{format_dollar(removedRevenue,false)  }</Col>
            </Row>

            <Row>
              <Col>Orders</Col>
              <Col>{format_number(keptOrders,false)  }</Col>
              <Col>{format_number(removedOrders,false)  }</Col>
            </Row>

            <Row>
              <Col>Line Items</Col>
              <Col>{format_number(keptLineItems,false)  }</Col>
              <Col>{format_number(removedLineItems,false)  }</Col>
            </Row>
          </Col>
        </Row>

        <Row className='mt-4'>
          <CompTransactionFileInfoSliceData data_input_translation_id = {dataInputTranslationId} min_date = {minDate}/>
        </Row>

      </React.Fragment>
    )
  }


  function render_delete_button(){
    if(doDeleteFile){
      return loading_gif('Deleting File')
    }
    else{
      return <button onClick={() => {setDoDeleteFile(true)}} className='btn btn-lg btn-warning mt-3'>Delete File</button>
    }
  }


  function render_input_settings(){
    if(doDeleteFile){
      return 
    }

    return(
      <React.Fragment>
        <Row className='mt-4'><Col>&nbsp;</Col></Row>
        <Row className='mt-4'><Col>&nbsp;</Col></Row>

        <Row className='mt-4'>
          <Col>
            <h4>Input Settings</h4>
          </Col>
        </Row>
        
        <Row className='mt-1'>
          <CompTransactionFileColumnNames data_input_translation_id = {dataInputTranslationId}/>
        </Row>
        <Row className='mt-4'><Col>&nbsp;</Col></Row>
        <Row className='mt-4'>   
          <CompTransactionFileTags data_input_translation_id = {dataInputTranslationId}/>
        </Row>
        <Row className='mt-4'><Col>&nbsp;</Col></Row>
        <Row className='mt-4'>
            <CompTransactionFileFilters data_input_translation_id = {dataInputTranslationId}/>
        </Row>
        <Row className='mt-4'><Col>&nbsp;</Col></Row>
        <Row className='mt-4'>
            <CompTransactionFileCompanyFilters/>
        </Row>
            
      </React.Fragment>
    )
  }

  


  //Output
  return(
    <Container lg={800}>
        <Row>
          <Col lg ="auto">
            <h1>{fileName}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            {render_delete_button()}
          </Col>
        </Row>
        
        {render_file_stats()}

        
        {render_input_settings()}

        
          

        
    </Container>
  )
    
    
  


 }

  
    