
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';


export const  CompTransactionFileColumnNames = (props) =>{
  //Passed from transaction_file_info.jsx
    const [dataInputTranslationId, setDataInputTranslationId] = useState(props.data_input_translation_id)


    const [columnMapping, setColumnMapping] = useState(null)
    const [fileColumnNames, setFileColumnNames] = useState(null)
    

  //Check job_status
  useEffect(() => {
    if(columnMapping === null){
      get_column_names()
    }

  })

  //Api Calls
  async function get_column_names(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"get_columns",
        data_input_translation_id: dataInputTranslationId
      }
      
      response = await send_request(body)
      debugger
      var column_mapping = JSON.parse(response['columns'])[0]
      column_mapping['additional_column_mappings'] = JSON.parse(column_mapping['additional_column_mappings'])
      
      var file_column_names = JSON.parse(response['file_column_names'])
      
      flushSync(
        setColumnMapping(column_mapping),
        setFileColumnNames(file_column_names)
      )
    } 
    catch(err){
      console.log('get_columns body', body)
      console.log('get_columns response', response)
      throw new Error(err)
    }
    
      

  }


  function render_table(){
    if(columnMapping === null || fileColumnNames === null){
      return(
        loading_gif('Loading Column Names')
      )
    }


    var options = [<option value=''></option>]
    for(var i=0;i<Object.keys(fileColumnNames).length;i++){
      var val = fileColumnNames[i] === null ? '' : fileColumnNames[i]
      options.push(<option value={val}>{val}</option>)
    }



    //Custom Columns
    var rows = []
    for(var i=0;i<Object.keys(columnMapping['additional_column_mappings']).length;i++){
      var column_name = Object.keys(columnMapping['additional_column_mappings'])[i]
      var value = columnMapping['additional_column_mappings'][column_name]
      
      var t_ele = 
      <React.Fragment>
        <Row lg={4}>
          <Col>
            <select id={'column_mapping_custom_'+i} defaultValue={column_name}>
              {options}
            </select>
          </Col>
          <Col>
            <input type='text' id={'inp_column_mapping_custom_column_name_' + i} defaultValue={value}/>
          </Col>
        </Row>
        
      </React.Fragment>
      rows.push(t_ele)
    }

    

    return(
      <React.Fragment>
        <Row lg={4}>
          <Col>
            <select id='column_mapping_transaction_date' defaultValue={columnMapping['transaction_date']}>
              {options}
            </select>
          </Col>
          <Col>Transaction Date</Col>
        </Row>
        <Row lg={4}>
          <Col>
            <select id='column_mapping_customers' defaultValue={columnMapping['customers']}>
              {options}
            </select>
          </Col>
          <Col>Customer ID</Col>
        </Row>
        <Row lg={4}>
          <Col>
            <select id='column_mapping_order_id' defaultValue={columnMapping['order_id']}>
              {options}
            </select>
          </Col>
          <Col>Order ID</Col>
        </Row>
        <Row lg={4}>
          <Col>
            <select id='column_mapping_revenue' defaultValue={columnMapping['revenue']}>
                {options}
              </select>
          </Col>
          <Col>Revenue</Col>
        </Row>
        <Row lg={4}>
          <Col>
           <select id='column_mapping_sku' defaultValue={columnMapping['sku']}>
              {options}
            </select>
          </Col>
          <Col>SKU</Col>
        </Row>
        <Row lg={4}>
          <Col>
            <select id='column_mapping_product_title' defaultValue={columnMapping['product_title']}>
              {options}
            </select>
          </Col>
          <Col>Product Title</Col>
        </Row>
        <Row lg={4}>
          <Col>
          <select id='column_mapping_tax' defaultValue={columnMapping['tax']}>
              {options}
            </select>
          </Col>
          <Col>Tax</Col>
        </Row>
        <Row lg={4}>
          <Col>
            <select id='column_mapping_shipping' defaultValue={columnMapping['shipping']}>
              {options}
            </select>
          </Col>
          <Col>Shipping</Col>
        </Row>
        <Row lg={4}>
          <Col>
          <select id='column_mapping_quantity' defaultValue={columnMapping['quantity']}>
              {options}
            </select>
          </Col>
          <Col>Quantity</Col>
        </Row>
        <Row lg={4}>
         <Col>
           <select id='column_mapping_discounts' defaultValue={columnMapping['discounts']}>
            {options}
           </select>
          </Col>
          <Col>Discounts</Col>
        </Row>

        <Row className='mt-4' lg={4}>
          <Col><h6>File Column Name</h6></Col>
          <Col><h6>Custom Column Name</h6></Col>
        </Row>
        {rows}
        <Row>
          <Col>
            <h6 className='hover_underline'>Add New +</h6>
          </Col>
        </Row>
      </React.Fragment>
    )
  }



  //Output
  return(
    <Container lg={800}>
        <Row>
          <Col lg ="auto">
            <h5>Column Naming</h5>
          </Col>
        </Row>

        <Row className='card p-2 mb-4'>
          
          <Row lg={4}>
            <Col>
              <h6>File Column Name</h6>
            </Col>
            <Col>
              <h6>EQ Column Name</h6>
            </Col>
          </Row>
          
          
          {render_table()}
        </Row>
          

        
    </Container>
  )
    
    
  


 }

  
    