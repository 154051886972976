import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {send_request} from "../globals/globals";
import loading_gif from "../globals/loading_gif";
import { get_color, format_percent } from "../globals/globals";
import { useEffect, useRef } from "react";
import { isna } from "../globals/globals";


export const  TwoYearsPrior = (props) =>{
  
  
          
      

  var colors = {'good': '87ff63', 'bad':'ff6384', 'upper_bound':0.05, 'lower_bound':-0.05}
  
  const prev_props = useRef();

  useEffect(() => {
    if(props.selected_calendar_date !== null && (props.measures === undefined || Object.keys(props.measures).length === 0) && props.cohort_filter_id !== 0 && !isna(props.selected_calendar_date['value'])){
      
      get_measures()
    }
    if(props.selected_calendar_type !== null && props.selected_calendar_type !== prev_props.selected_calendar_type){
      prev_props.selected_calendar_type = props.selected_calendar_type
      props.handle_drivers_and_drags_update_measures({})
    }
    else if(props.cohort_filter_id !== null && props.cohort_filter_id !== prev_props.cohort_filter_id){
      prev_props.cohort_filter_id = props.cohort_filter_id
      props.handle_drivers_and_drags_update_measures({})
    }
  });



  //Api Calls
  async function get_measures(){
    var body = ''
    var response = ''
    try{
      
      body = {
        path:"drivers_and_drags",
        func:"get_two_years_prior",
        selected_calendar_date: props.selected_calendar_date['value'],
        calendar_type: props.selected_calendar_type,
        cohort_filter_id: props.cohort_filter_id
      };
      response = await send_request(body)
      response = JSON.parse(response)
      props.handle_drivers_and_drags_update_measures(response)
    }
    catch(err){
      console.log('get_measures body:', body)
      console.log('get_measures response:', response)
      throw new Error(err)
    }
    
  }
  

  //Rendering

  function render_table(){
    //console.log('render_table ', measures.data)
    if(props.measures === undefined || Object.keys(props.measures).length === 0){
      return 
    }
    return(
      <React.Fragment>
          <Col className="mx-0 px-0 float-start" lg="2">
            <Row>
              <Col><p></p></Col>
            </Row>
            <Row>
              <Col><h6 className="mb-0">This Year</h6></Col>
            </Row>
            <Row>
              <Col><h6 className="mb-0">Last Year</h6></Col>
            </Row>
            <Row>
              <Col><h6 className="mb-0">Two Years Ago</h6></Col>
            </Row>
          </Col>
          {render_table_columns()}
      </React.Fragment>
    )
  }

    function render_table_columns(){
      var render_cols = []
      
      for(let i=Object.keys(props.measures).length - 1; i>=0;i--){
        
        if(Date.parse(Object.keys(props.measures)[i]) > Date.parse(props.selected_calendar_date['value'])){
          continue
        }
        render_cols.push(render_table_col(i))
        if(render_cols.length === 12){
          break
        }
      }

      render_cols.reverse()
      return render_cols
    }

      function render_table_col(i){
        
        //Hard coding to % for the moment
        var render_selected_measure = props.selected_measure + '_y'
        //console.log('selected_measure', render_selected_measure)


        var ty = Object.keys(props.measures)[i]
        
        var ly = props.measures[ty]['ly']
        var lly = props.measures[ty]['lly']

        var ty_val = "\u00A0"
        var ly_val = "\u00A0"
        var lly_val = "\u00A0"

        
        try{ty_val = props.measures[ty][render_selected_measure]}
        catch(err){}
        try{ly_val = props.measures[ly][render_selected_measure]}
        catch(err){}
        try{lly_val = props.measures[lly][render_selected_measure]}
        catch(err){}

        var iter_vals = [ty_val, ly_val, lly_val]

        var col_vals = []
        for(var j=0;j<iter_vals.length;j++){
          var val = iter_vals[j]
          var ratio = null
          var color = null
          
          if(val === "\u00A0"){
            color = 'ffffff'
          }
          else if(val > 0){
            ratio = Math.min(1, val/colors.upper_bound)
            color = get_color(ratio, colors.good, 'FFFFFF' )
            val = format_percent(val)
          }else{
            ratio = Math.min(1, val/colors.lower_bound)
            color = get_color(ratio, colors.bad, 'FFFFFF' )
            val = format_percent(val)
          }

          
          
            var t_col = (
              <Row className="px-0 mx-0" key={'row_'+i+'_'+j}>
                <Col className="align-items-center px-0 mx-0" style={{backgroundColor:"#"+color}}  key={'col_'+i+'_'+j}>
                  <p className="align-items-center mx-0 mb-0 px-0"  key={'val_'+i+'_'+j}><small className="mx-0 px-0">{val}</small></p>
                </Col>
              </Row>
          )

          col_vals.push(t_col)

        }




        var header = ''
        for(j=0;j<props.calendar[props.selected_calendar_type].length; j++){
          if(props.calendar[props.selected_calendar_type][j]['end_date'] !== ty){
            continue
          }
          header = props.calendar[props.selected_calendar_type][j]['label']
          if(props.selected_calendar_type === 'month_end_445' || props.selected_calendar_type === 'quarter_end_445'){
            var split_header = header.split(' ')
            header = split_header[0] + '-' + split_header[1].substring(2,4)
          }
    
          
          break
        }

        
        
        return(
          <React.Fragment  key={'header_react_fragment_'+i}>
            <Col className="px-0 float-start mx-0">
              <Row className="px-0 mx-0">
                <Col className="px-0 mx-0">
                  <h6 className="mb-0 align-items-center">{header}</h6>
                </Col>
              </Row>
              {col_vals}
            </Col>
          </React.Fragment>
        )
        
      }



  function change_select(event){
    props.handle_drivers_and_drags_measure_change(event.target.value)
  }


  function render_dropdown(){
    if(props.measures === undefined || Object.keys(props.measures).length === 0){
      return(
        <React.Fragment>
          {loading_gif("Gathering Data")}
        </React.Fragment>
      )
    }
    else{
      return(
        <React.Fragment>
            <Col className="px-0">
              <h4>Measure:</h4>
              <select id="inp_measure" onChange={(e) => change_select(e)} value={props.selected_measure}>
                <option value="total_lever_number_of_new" key="total_lever_number_of_new">Number of New</option>
                <option value="new_order_frequency" key="new_order_frequency">New: Order Frequency</option>
                <option value="new_units_per_order" key="new_units_per_order">New: Units Per Order</option>
                <option value="new_price_per_unit" key="new_price_per_unit">New: Price Per Unit</option>
                <option value="existing_retention" key="existing_retention">Retention</option>
                <option value="existing_order_frequency" key="existing_order_frequency">Returning: Order Frequency</option>
                <option value="existing_units_per_order" key="existing_units_per_order">Returning: Units Per Order</option>
                <option value="existing_price_per_unit" key="existing_price_per_unit">Returning: Price Per Unit</option>
              </select>
            </Col>
        </React.Fragment>
      )
    }
  }

  


  return (
      <React.Fragment>
          <Row className="mt-3">
            {render_dropdown()}
          </Row>
          <Row>
            <Col className="ps-0">
              <p className="mb-0">Point change from last year for the selected measure.</p>
            </Col>
          </Row>
          <Row className="mt-3 border border-2 border-dark pe-0">
            {render_table()}
          </Row>
      </React.Fragment>
      )
  










}


