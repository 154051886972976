import loading from "../images/loading.gif";
import React from "react";
import { Col } from "react-bootstrap";

export default function loading_gif(text, text_class){
    if(text_class === undefined){
      text_class = 'float-start align-items-center ms-3'
    }
    return(
        <React.Fragment>
          <Col className="d-inline">
            <img src={loading} className="loading float-start" alt="Loading GIF"/>
            <h6 className={text_class}>{text}</h6>
          </Col>
        </React.Fragment>
    )
}