import React, { Component } from "react";
import logo from "../images/eq-logo-svg.svg";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Auth } from 'aws-amplify';






class TopMenu extends Component {
  state = {
    first_name:this.props.first_name,
    is_admin:this.props.is_admin,
    loggedIn: this.props.loggedIn,
    imageUrl: logo
  };

  

  render() {
    return (
      <nav className="navbar fixed-top navbar-light bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/dashboard">
            <img src={this.state.imageUrl} alt="" />
          </a>
          
          <div>
            <Row>
              <Col>
                <a className="text-white nav-link" href="/dashboard">Dashboard</a>
              </Col>
              <Col lg="auto">
                
                {/*
                  <a className="text-white nav-link" href="/what_if">What If</a>
    */}
              </Col>
              <Col lg="auto">
                <a className="text-white nav-link" href="/drivers_and_drags">Drivers</a>
              </Col>
              {/*
              <Col lg="auto">
                <a className="text-white nav-link" href="/marketing">Marketing</a>
              </Col>
    */}
            </Row>
            </div>
          
            <a className="text-white nav-link" href="/settings">Settings</a>
          {/*{this.displayLogInOut()}*/}
        </div>
        


      </nav>
    );
  }






  displayEmail() {
    return <span className="text-white navbar-text">{this.props.email}</span>;
  }


  displayLogInOut() {
    if (this.state.loggedIn) {
      return <AmplifySignOut buttonText="Sign Out"></AmplifySignOut>;
    } else {
      return <AmplifySignOut buttonText="Sign Out"></AmplifySignOut>;
    }
  }
}

export default TopMenu;
