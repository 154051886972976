
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get_aws_bucket, get_aws_region, get_identity_pool, send_request } from '../../../globals/globals';
import { UploadDialog } from './uploadDialog';
import { ColumnMappings } from './column_mappings';
import { flushSync } from 'react-dom';
import { RenameColumns } from './rename_columns';
import { Tags } from './tags';
import { Filters } from './filters';
import loading_gif from '../../../globals/loading_gif';
import AWS from 'aws-sdk'
import {Link, useNavigate} from 'react-router-dom';

//import { Amplify, Storage } from 'aws-amplify';
//import awsconfig from './aws-exports';


<script src="https://sdk.amazonaws.com/js/aws-sdk-2.1.24.min.js"></script>



export const  PageUploadTransactionLineItems = (props) =>{
    const navigate = useNavigate();

    //Mappings from previous uploads
    const [previousColumnMappings, setPreviousColumnMappings] = useState(null)

    //uploadDiaglog.jsx
    const [stageShowUploadFile, setStageShowUploadFile] = useState(true) //Controller if should show
    var [file, setFile] = useState(null) //Set in Component
    const [fileColumns, setFileColumns] = useState(null) //Set here: handle_file_uploaded

    //column_mappings.jsx
    const [stageShowColumnMappings, setStageShowColumnMappings] = useState(false)
    const [fileColumnMappings, setFileColumnMappings] = useState([])
    
    //Column Renaming
    const [stageShowColumnRename, setStageShowColumnRename] = useState(false)
    const [fileRenameColumns, setFileRenameColumns] = useState([])
    

    //Tags
    const [stageShowTags, setStageShowTags] = useState(false)
    const [fileTags, setFileTags] = useState([])

    //Filters
    const [stageShowFilters, setStageShowFilters] = useState(false)
    const [fileFilters, setFileFilters] = useState([])

    //Saving to DB
    const [isSavingToDB, setIsSavingToDB] = useState(false)
    const [sentInserToDBApi, setSentInserToDBApi] = useState(false)
    const [dataInputTranslationId, setDataInputTranslationId] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [companyId, setCompanyId] = useState(null)

    //Converting File
    const [isConvertingFile, setIsConvertingFile] = useState(false)
    const [startedConvertingFile, setStartedConvertingFile] = useState(false)
    const [stringFile, setStringFile] = useState('')

    //Uploading File
    const [isUploading, setIsUploading] = useState(false)
    const [uploadedStarted, setUploadStarted] = useState(false)

    //Moving File to Private Bucket
    const [isMovingFile, setIsMovingFile] = useState(false)
    const [startedMovingFile, setStartedMovingFile] = useState(false)
    
    
    

  useEffect(() => {
    if(previousColumnMappings === null){
        get_previous_column_mappings()
    }
    else if(isSavingToDB && ! sentInserToDBApi){
        insert_data_file_data()
        setSentInserToDBApi(true)
    }
    else if(isConvertingFile && !startedConvertingFile){
        convert_file_to_string()
        setStartedConvertingFile(true)
    }
    else if(dataInputTranslationId !== null && !uploadedStarted && isUploading){
        upload_file()
        setUploadStarted(true)
    }
    else if(isMovingFile && !startedMovingFile){
        move_file_to_private_bucket()
        setStartedMovingFile(true)
    }
 
  });

    //API Calls
    async function get_previous_column_mappings(){
        var body = ''
        var response = ''
        try{
            body = {
            path:"data_input_translation",
            func:"get_column_mappings"
            };
            
            let response = await send_request(body)
            
            setPreviousColumnMappings(response)
        }
        catch(err){
            console.log('get_previous_column_mappings body:', body)
            console.log('get_previous_column_mappings response:' , response)
            throw new Error(err);
        }
    }

    async function insert_data_file_data(){
        var body = ''
        var response = ''
  
        try{
            body = {
                path:"transaction_files",
                func:"insert_data_input_translation",
                file_name: fileName,
                column_mappings: fileColumnMappings,
                additional_column_mappings: fileRenameColumns,
                tags: fileTags,
                column_names: fileColumns,
                file: file
            };
            
            let response = await send_request(body)
            debugger
            flushSync(
                setIsConvertingFile(true),
                setIsSavingToDB(false),
                setDataInputTranslationId(response['data_input_translation_id']),
                setCompanyId(response['company_id']),
                setFileName(response['file_name'])
            )
        }
        catch(err){
            console.log('insert_data_file_data body:', body)
            console.log('insert_data_file_data response:' , response)
            throw new Error(err);
        }
        }
    
    async function convert_file_to_string(){

        var files = document.getElementById('inp_file_dialog').files;
        
        
        var t_file = files[0]
        var reader = new FileReader();
        //reader.readAsDataURL(t_file);
        reader.readAsText(t_file)
        reader.onload = function () {
            var results = reader.result
            //debugger
            //let utf8Encode = new TextEncoder();
            //results = utf8Encode.encode(results);
            console.log(reader.result);
            flushSync(
                setStringFile(results),
                setIsConvertingFile(false),
                setIsUploading(true)
            )
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            debugger
        };

    }


    async function move_file_to_private_bucket(){
        var body = ''
        var response = ''
        try{
            body = {
                path:"transaction_files",
                func:"move_file_to_private",
                file_name: fileName
            };
            
            let response = await send_request(body)
            navigate('/settings/transaction_file_info',{state:{data_input_translation_id: dataInputTranslationId, file_name:fileName}});
            
        }
        catch(err){
            console.log('move_file_to_private_bucket body:', body)
            console.log('move_file_to_private_bucket response:' , response)
            throw new Error(err);
        }

    }

    
    async function upload_file(){ 
          AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: get_identity_pool()
            }),
            region: get_aws_region(),
        }); 


        var s3 = new AWS.S3({
            params: {Bucket: get_aws_bucket('public-uploads'), region: get_aws_region()}
        });


        var filePath = companyId + '/transaction_files/' + fileName;
        s3.upload(
            {
            Key: filePath,
            Body: stringFile,
            ACL: 'public-read',
            region: get_aws_region()
            }, 
            function(err, data) {
                if(err) {debugger}
                //Upload is successful
                setIsMovingFile(true)
            }
            ).on(
                'httpUploadProgress', 
                function (progress) {
                    var uploaded = parseInt((progress.loaded * 100) / progress.total);
                    console.log('uploaded: ', uploaded)
                    //$("progress").attr('value', uploaded);
                });
                         
    }

    //With Button to select file
    function handle_file_uploaded(file){
        flushSync(
            setFile(file), //Data Returned
            setFileName(file.name)
        )
    }

    function handle_file_header(csvHeader){

        flushSync(
            setFileColumns(csvHeader), //Data Returned

            //Set Components to change what is showing.
            setStageShowUploadFile(false),
            setStageShowColumnMappings(true)

        )
    }



    //With mappings to EQ columns
    function handle_column_mapping_next(file_column_mappings){
    flushSync(
        setFileColumnMappings(file_column_mappings), //Data Returned
        //Set Components to change what is showing.
        setStageShowColumnMappings(false),
        setStageShowColumnRename(true)
    )
    }

    //Rename Columns
    function handle_column_rename_next(file_rename_columns){
    flushSync(
        setFileRenameColumns(file_rename_columns), //Data Returned
        //Set Components to change what is showing.
        setStageShowColumnRename(false),
        setStageShowTags(true)
    )
    }

    //Tags
    function handle_tags_next(file_tags){
            
        flushSync(
            setFileTags(file_tags), //Data Returned
            //Set Components to change what is showing.
            setStageShowTags(false),
            setStageShowFilters(true)
        )
    }

    //Filters
    function handle_filters_next(file_filters){
        flushSync(
            setFileFilters(file_filters), //Data Returned
            //Set Components to change what is showing.
            setStageShowFilters(false),
            setIsSavingToDB(true)
        )
    }


 

  


    //Render Header
    function render_file_name_header(){
    if(file === null){
        return
    }
    return (
    <React.Fragment>
        <h5>File Name: {fileName}</h5>
    </React.Fragment>
    )

    }



    function render_uploading(){
        if(isSavingToDB){
            return loading_gif('Saving Data')
        }
        else if(isUploading){
            return loading_gif('Uploading File')
        }
        else if(isMovingFile){
            return loading_gif('Moving File')
        }
    }
  
    
    return (
      <Container>
        {render_file_name_header()}
        <UploadDialog
            stageShowUploadFile = {stageShowUploadFile}
            handle_file_uploaded = {handle_file_uploaded}
            handle_file_header = {handle_file_header}
            file = {file}
            fileColumns = {fileColumns}
        />

        <ColumnMappings
            stageShowColumnMappings = {stageShowColumnMappings}
            handle_column_mapping_next = {handle_column_mapping_next}
            file = {file}
            fileColumns = {fileColumns}
            fileRenameColumns = {fileRenameColumns}
            setFileRenameColumns = {setFileRenameColumns}
            previousColumnMappings = {previousColumnMappings}
            />

        <RenameColumns
            stageShowColumnRename = {stageShowColumnRename}
            handle_column_rename_next = {handle_column_rename_next}
            previousColumnMappings = {previousColumnMappings}
            setFileRenameColumns = {setFileRenameColumns}
            fileRenameColumns = {fileRenameColumns}
            fileColumns = {fileColumns}
            fileColumnMappings = {fileColumnMappings}
            />

        <Tags 
            stageShowTags = {stageShowTags}
            fileTags = {fileTags}
            setFileTags = {setFileTags}
            handle_tags_next = {handle_tags_next}
        />

        <Filters
            stageShowFilters = {stageShowFilters}
            handle_filters_next = {handle_filters_next}
            fileFilters = {fileFilters}
            setFileFilters = {setFileFilters}

        />

        {render_uploading()}



          
        {/*get_fileData()*/}
      
      </Container>
    );
  }
  
  