
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';


export const  CompTransactionFileInfoSliceData = (props) =>{
  
    

    //Passed from transaction_file_info.jsx
    const [dataInputTranslationId, setDataInputTranslationId] = useState(props.data_input_translation_id)
    const minDate = props.min_date

    console.log('dataInputTranslationId:', dataInputTranslationId)

    const [sliceData, setSliceData] = useState(null)
    

  //Check job_status
  useEffect(() => {
    if(sliceData === null){
      get_slice_data()
    }

  })

  //Api Calls
  async function get_slice_data(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'transaction_files',
        func:"get_slice_data",
        data_input_translation_id: dataInputTranslationId
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      setSliceData(response)
      
    } 
    catch(err){
      console.log('get_slice_data body', body)
      console.log('get_slice_data response', response)
      throw new Error(err)
    }
    
      

  }


  function render_table(){
     if(sliceData === null){
      return(
        loading_gif('Loading Slice Data')
      )
    }

    var rows = []
    for(var i=0;i<sliceData.length;i++){
      var row = sliceData[i]

      var t_ele = 
      <React.Fragment>
        <Row lg={4}>
          <Col>
            {row['slice_name']}
          </Col>
          <Col>
            {format_dollar(row['revenue'], false)}
          </Col>
          <Col>
            {format_number(row['orders'], false)}
          </Col>
        </Row>
        
      </React.Fragment>
      rows.push(t_ele)
    }


    return(
      <React.Fragment>
        {rows}
      </React.Fragment>
    )

    

  }



  function render_slices(){
    
    return(
      <React.Fragment>
        <Row>
          <Col lg ="auto">
            <h5>Slices</h5>
          </Col>
        </Row>

        <Row className='card p-2'>
          
        <Row lg={4}>
          <Col>
            <h6>Name</h6>
          </Col>
          <Col>
            <h6>Revenue</h6>
          </Col>
          <Col>
            <h6>Orders</h6>
          </Col>
        </Row>
        
        
        {render_table()}
        </Row>
      </React.Fragment>
    )
  }

  //Output
  return(
    <Container>
        {render_slices}
    </Container>
  )
    
    
  


 }

  
    