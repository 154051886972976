
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import { Link, json } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const  PageCompanyFilters = (props) =>{
    

    //Local
    const [globalFilters, setGlobalFilters] = useState(null)
    const [finalColumnNames, setFinalColumnNames] = useState(null)

    //Modal
    const [modalOpen, setModalOpen] = useState(false)
    const [modalColumnName, setModalColumnName] = useState(null)
    const [modalOperator, setModalOperator] = useState(null)
    const [modalValues, setModalValues] = useState({})
    const [modalCompanyFilterID, setCompanyFilterID] = useState(null)
    const [modalIsSaving, setModalIsSaving] = useState(false)
    const [modalSentSaveApi, setModalSentSaveApi] = useState(false)
    const [modalIsDeleting, setModalIsDeleting] = useState(false)
    const [modalSentDeleteApi, setModalSentDeleteApi] = useState(false)
    
    
    


  //Check job_status
  useEffect(() => {
    if(globalFilters === null){
      get_global_filters()
    }
    else if(finalColumnNames === null){
      get_final_column_names()
    }
    else if(modalIsSaving && !modalSentSaveApi){
      save_modal_filter()
      setModalSentSaveApi(true)
    }
    else if(modalIsDeleting && !modalSentDeleteApi){
      delete_modal_filter()
      setModalSentDeleteApi(true)
    }

  })

  //Api Calls
  async function get_global_filters(){
    var body = ''
    var response = ''
    try{
      body = { 
        path:'transaction_files',
        func:"get_global_filters"
      }
      response = await send_request(body)
      response = JSON.parse(response)
      console.log('get_global_filters body', body)
      for(var i = 0;i<response.length;i++){
        response[i]['value'] = JSON.parse(response[i]['value'])
      }
      
      setGlobalFilters(response)
    } 
    catch(err){
      console.log('get_global_filters body', body)
      console.log('get_global_filters response', response)
      throw new Error(err)
    }
  }

  async function get_final_column_names(){
    var body = ''
    var response = ''
    try{
      body = { 
        path:'global_filters',
        func:"get_final_column_names"
      }
      response = await send_request(body)
      console.log('get_final_column_names body:', body)
      console.log('get_final_column_names response', response)
      
      setFinalColumnNames(response)
    } 
    catch(err){
      console.log('get_final_column_names body', body)
      console.log('get_final_column_names response', response)
      throw new Error(err)
    }
  }

  async function save_modal_filter(){

    var body = ''
    var response = ''
    try{
      body = { 
        path:'global_filters',
        func:"save_filter",
        column_name: modalColumnName,
        operator: modalOperator,
        values: modalValues,
        company_filter_id: parseInt(modalCompanyFilterID)
      }
      response = await send_request(body)
      console.log('save_modal_filter body:', body)
      flushSync(
        setGlobalFilters(null),
        setModalOpen(false),
        setModalIsSaving(false),
        setModalSentSaveApi(false)
      )
    } 
    catch(err){
      console.log('save_modal_filter body', body)
      console.log('save_modal_filter response', response)
      throw new Error(err)
    }
  }

  async function delete_modal_filter(){

    var body = ''
    var response = ''
    try{
      body = { 
        path:'global_filters',
        func:"delete_filter",
        company_filter_id: parseInt(modalCompanyFilterID)
      }
      response = await send_request(body)
      console.log('delete_modal_filter body:', body)
      flushSync(
        setGlobalFilters(null),
        setModalOpen(false),
        setModalIsDeleting(false),
        setModalSentDeleteApi(false)
      )
    } 
    catch(err){
      console.log('delete_modal_filter body', body)
      console.log('delete_modal_filter response', response)
      throw new Error(err)
    }
  }



  //Rendering
  function render_table(){
    if(globalFilters === null || finalColumnNames === null){
      return(
        loading_gif('Loading Global Filters')
      )
    }



    var rows = []
    for(var i=0;i<globalFilters.length;i++){
      var row = globalFilters[i]

      var operator = row['operator']
      if(operator == 'is in'){
        operator = 'Equal To'
      }
      else if(operator == 'is not in'){
        operator = 'Not Equal To'
      }

      var column_name = row['column_name']
      
      var values = ''
      for(var j=0; j<row['value'].length;j++){
         values += row['value'][j] + ', '
      }
      values = values.substring(0,values.length - 2)

      var t_ele = 
      <React.Fragment>
        <Row lg={4}>
          <Col>
            {column_name}
          </Col>
          <Col>
            {operator}
          </Col>
          <Col>
            {values}
          </Col>
          <Col>
            <h6 className='hover_underline' global_filter_index={i} onClick={editFilter}>Edit</h6>
          </Col>
        </Row>
        
      </React.Fragment>
      rows.push(t_ele)
    }


    return(
      <React.Fragment>
        {rows}
      </React.Fragment>
    )

    

  }
  
  function render_modal(){
    if(globalFilters === null || finalColumnNames === null){
      return ''
    }

    
    
  var column_options = []
  for(var i=0; i<finalColumnNames.length;i++){
    column_options.push(<option value={finalColumnNames[i]}>{finalColumnNames[i]}</option>)
  }


  var values = modalValues
  if(values.length === 0){
    values = ['']
  }
  
  var inp_value_list = []
  for(var i=0;i<values.length;i++){
    var inp_class = i == 0 ? '' : 'mt-1'
    inp_value_list.push(
      <React.Fragment>
        <Row>
          <Col>
            <input type='text' defaultValue={values[i]} className={inp_class} key={'inp_modal_value_' + i} id={'inp_modal_value_' + i}/>
          </Col>
        </Row>
      </React.Fragment>
    )
  }



    var btn_add_new = <h6 className='hover_underline' onClick={modal_add_new_value}>Add New +</h6>
    if(!['is not in', 'is in'].includes(modalOperator)){
      btn_add_new = ''
    }

  
    //var btn_no = modalSavingFilter ? '': <button className='btn btn-lg btn-warning mt-3' onClick={close_remove_modal}>Cancel</button>
    var btn_save = modalIsSaving ? loading_gif('Saving'): <button className='btn btn-lg btn-warning mt-3' onClick={set_modal_save}>Save</button>
    if(modalIsDeleting){
      btn_save = ''
    }

    var btn_delete = modalIsSaving || modalCompanyFilterID == -1 || modalIsDeleting ? '': <button className='btn btn-lg btn-warning mt-3' onClick={set_modal_delete}>Delete</button>


    return(
      <React.Fragment>
            <Modal
            isOpen={modalOpen}
            onRequestClose={set_modal_close}
            style={customStyles}>
            <Row>
                <Col>
                  Column Name
                </Col>
                <Col>
                  Operator
                </Col>
                <Col>
                  Value
                </Col>
              
            </Row>
            <Row>
              <Col>
                <select defaultValue={modalColumnName} className='w-100' id='inp_modal_column_name'>{column_options}</select>
              </Col>
              <Col>
              <select defaultValue={modalOperator}  className='w-100' id='inp_modal_operator' onChange={set_modal_operator}>
                <option value='is not in'>Not Equal To</option>
                <option value='is in'>Equal To</option>
                <option value='<'>less than</option>
                <option value='<='>less than equal to</option>
                <option value='>'>greater than</option>
                <option value='>='>greater than equal to</option>
              </select>
                
              </Col>
              <Col>
                {inp_value_list}
                {btn_add_new}
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>{btn_delete}</Col>
              <Col>
                {btn_save}
              </Col>
            </Row>
            
          </Modal>
      </React.Fragment>
    )
  }

    //Modal Functions
    function editFilter(e){
      var global_filter_index = e.target.getAttribute('global_filter_index')
      
      var global_filter = global_filter_index == -1 ? null : globalFilters[global_filter_index]
      var operator = global_filter_index == -1 ? '' : global_filter['operator']
      var column_name = global_filter_index == -1 ? '' : global_filter['column_name']
      var value = global_filter_index == -1 ? '' : global_filter['value']
      var company_filter_id = global_filter_index == -1 ? -1 : global_filter['company_filter_id']


      flushSync(
        setModalOpen(true),
        setModalColumnName(column_name),
        setModalOperator(operator),
        setModalValues(value),
        setCompanyFilterID(company_filter_id)
      )

    }

    function set_modal_delete(){
      setModalIsDeleting(true)
    }

    function set_modal_save(){

      var values = []
      var i = 0
      while(i !== -1){
        try{
          var val = get_val('inp_modal_value_' + i)
          if(val === ''){
            i += 1
            continue
          }
          values.push(val)
          i += 1
        }
        catch(e){
          i = -1
        }
      }



      flushSync(
        setModalIsSaving(true),
        setModalColumnName(get_val('inp_modal_column_name')),
        setModalOperator(get_val('inp_modal_operator')),
        setModalValues(values),

      )
    }

    function modal_add_new_value(){
      var values = []
      for(var i = 0;i<modalValues.length;i++){
        values.push(modalValues[i])
      }
      values.push('')
      
      flushSync(
        setModalValues(values)
      )
    }

    function set_modal_close(){
      setModalOpen(false)
    }

    function set_modal_operator(e){
      setModalOperator(e.target.value)
    }




  //Output
  return(
    <Container lg={800}>
        <Row>
          <Col lg ="auto">
            <h5>Global Filters</h5>
          </Col>
        </Row>

        <Row className='card p-2'>
          
        <Row lg={4}>
          <Col>
            <h6>Column</h6>
          </Col>
          <Col>
            <h6>Operator</h6>
          </Col>
          <Col>
            <h6>Value</h6>
          </Col>
        </Row>
        
        
        {render_table()}
        
        </Row>

        <Row>
          <Col>
            <h6 className='hover_underline' onClick={editFilter} global_filter_index={-1}>Add New + </h6>
          </Col>
        </Row>

        {render_modal()}
          

        
    </Container>
  )
    
    
  


 }

  
    