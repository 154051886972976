import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {send_request, format_dollar} from "../globals/globals";
import arrow_rise from "../images/arrow_rise.svg";
import arrow_fall from "../images/arrow_fall.svg";
import loading_gif from "../globals/loading_gif";
import btn_right_arrow from "../images/btn_right_arrow.svg";
import btn_down_arrow from "../images/btn_down_arrow.svg";
import {Link, } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { isna } from "../globals/globals";
import { format_percent } from "../globals/globals";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';



export const  DashboardGraphNewReturningRevenue = (props) =>{
  const [graphData, setGraphData] = useState({})
  
  const graph_colors = {'green': '#87ff63', 'yellow': '#ffea63', 'blue': '#63a4ff', 'purple': '#a04bbf', 'orange': '#bf8b4b'}

  const prev_props = useRef();

  useEffect(() => {
    if((Object.keys(graphData).length === 0 || props.selected_calendar_type !== prev_props.selected_calendar_type) && props.selected_calendar_date['value'] !== undefined){
      prev_props.selected_calendar_type = props.selected_calendar_type
      get_graph_data()
    }
    if(props.selected_calendar_type !== null && props.selected_calendar_type !== prev_props.selected_calendar_type){
      prev_props.selected_calendar_type = props.selected_calendar_type
      setGraphData({})
    }
    else if(props.cohort_filter_id !== null && props.cohort_filter_id !== prev_props.cohort_filter_id){
      prev_props.cohort_filter_id = props.cohort_filter_id
      setGraphData({})
    }
    else if(props.selected_calendar_date !== null && props.selected_calendar_date !== prev_props.selected_calendar_date){
      prev_props.selected_calendar_date = props.selected_calendar_date
      setGraphData({})
    }
  });



  //Api Calls
  async function get_graph_data(){
    var body = ''
    var response = ''

    try{
      
      body = {
        path:"get_graphs",
        func:"get_data",
        as_of: props.selected_calendar_date['value'],
        calendar_type: props.selected_calendar_type,
        cohort_filter_id: props.cohort_filter_id,
        values: ['new_revenue_y', 'existing_revenue_y'],
        aggregate: ['cols']
      };
      response = await send_request(body)
      response = JSON.parse(response)
      setGraphData(response)
    }
    catch(err){
      console.log('get_graph_data body:', body)
      console.log('get_graph_data response:', response)
      throw new Error(err)
    }
    
  }
  

  //Rendering
  function render_graph(){
    if(Object.keys(graphData).length === 0){
      return(
        loading_gif('Loading Graph')
      )
    }

    var number_of_columns_to_graph = 12
    

    var as_of = Date.parse(props.selected_calendar_date['value'])
    var data_to_graph = []
    var tooltip_payload = []
    var temp_tooltip_payload = {}
    for(var i=Object.keys(graphData).length - 1 - number_of_columns_to_graph; i < Object.keys(graphData).length; i++){
      var col_key = Object.keys(graphData)[i]
      var col_date = Date.parse(col_key)

      
      if(col_date <= as_of){
        var data = graphData[col_key]
        if(props.selected_calendar_type === 'month_end_445'){
          for(var j=0;j<props.calendar['month_end_445'].length;j++){
            if(col_key === props.calendar['month_end_445'][j]['end_date']){
              col_key = props.calendar['month_end_445'][j]['label']
              break
            }
          }
        }
        data['col'] = col_key
        data_to_graph.push(data)
        temp_tooltip_payload = {name: col_key, value:data[props.selected_measure + '_v'], unit:col_key + '_2'}
        tooltip_payload.push(temp_tooltip_payload)
        
      }
    }

    


    const tick_format_graph_dollar = (tickItem) => {
      return format_dollar(tickItem, false)
    }

  
    const format_graph_percent = (tickItem) => {
      //console.log('tickitem', tickItem)
      return format_percent(tickItem, false)
    }

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        //console.log('active payload', active, payload, label)

        var tooltip_payload = []
        for(var i=0;i<payload.length;i++){
          
          var pl = payload[i]
          var val = pl['value']
          
          if(pl['name'].indexOf('YoY') === 0 || pl['name'].indexOf('%') === 0 || pl.dataKey === 'new_revenue_y' || pl.dataKey === 'existing_revenue_y'){
            val = format_percent(pl['value'], true)
          }
          else if(pl['name'].indexOf('$') === 0){
            val = format_dollar(pl['value'], true)
          }

          var spacer_line = ''
          if(pl['name'] === '% Total Revenue'){ //Add spacer line
            spacer_line = <hr className="hr mt-0" />
          }

          var t_payload = (
            <React.Fragment>
              {spacer_line}
              <p className="mt-0 pt-0 mb-0 pb-0"  style={{color: pl['stroke']}}>{pl['name'] + ': ' + val}</p>
            </React.Fragment>
          )
          tooltip_payload.push(t_payload)
        }
        
        return (
          <div className="custom-tooltip card p-2">
            <p className="label mt-0 pt-0 mb-0 pb-0">{`${label}`}</p>
            <hr className="hr mt-0" />
            {tooltip_payload}
          </div>
        );
      }
    
      return null;
    };

    const graph_text_style = 
        {
          fontSize: '0.8rem',
          fontFamily: 'Arial',
      }


      const renderLegend = (props) => {
        const { payload } = props;
        var header = <h6>Revenue Impact</h6>
        var spacer_line = <hr className="hr mt-0" />
        var output_list = [header, spacer_line]
      
        //console.log('legend payload', payload)
        var val = ''
        
        for(var i=0;i<payload.length;i++){
          var key = payload[i]['dataKey']
          var color = payload[i]['color']
          val = <p className="m-0" style={{color: payload[i]['color']}}>{payload[i]['value']}</p>
          output_list.push(val)
        }

        
        return (
          <React.Fragment>
            <Row>
              <Col lg="auto">
                <p className="m-0" style={{color: payload[0]['color']}}>{payload[0]['value']}</p>
              </Col>
              <Col lg="auto">
                <p className="m-0" style={{color: payload[1]['color']}}>{payload[1]['value']}</p>
              </Col>
            </Row>
          </React.Fragment>
        );
      }
      

 
    const renderLineChart = (
      <ResponsiveContainer width='100%' aspect={4.0/1.5}>
      <LineChart width={900} height={400} data={data_to_graph} margin={{ top: 0, right: 0, left: 0, bottom: 5 }}>
        
        
        <XAxis dataKey="col" style={graph_text_style} angle={-45} tick={{dy:20, dx:-20}}  height={60} padding={{left:10, right:10}}  interval={0}/>
        
        <Tooltip content={CustomTooltip}/>
        <Legend verticalAlign="bottom" align="center" layout="vertical" content={renderLegend}/>
        <YAxis yAxisId="left" label={{ value: '% Impact', angle: -90, position: 'center' }} tickFormatter={format_graph_percent} tickSize={0} width={150}style={graph_text_style} />

        <Line name={'New Customers'} yAxisId='left' type="monotone" dataKey={'new_revenue_y'} stroke={graph_colors['green']} dot={false}  strokeWidth={3} />
        <Line name={'Returning Customers'} yAxisId='left' type="monotone" dataKey={'existing_revenue_y'} stroke={graph_colors['blue']} dot={false}  strokeWidth={3} />
        
      </LineChart>
      </ResponsiveContainer>
      
    );




    return(
      <React.Fragment>
        <Row>
          <Col className="text-center w-100">
            <h5>Revenue Impact - New vs Returning Customers</h5>
          </Col>
        </Row>
        {renderLineChart}
      </React.Fragment>
    )

  }


  return (
      <React.Fragment>
        
        <Row>
          {render_graph()}
        </Row>
          
      </React.Fragment>
      )
  


    

}


