
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';
import Modal from 'react-modal';

import { Tooltip } from 'react-tooltip'

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

export const  PageManageCohortFilters = (props) =>{
  const [slices, setSlices] = useState(null)
  const [cohortFilters, setCohortFilters] = useState(null)
  

  //Modal Open
  const [modalOpen, setModalOpen] = useState(false)
  const [modalIsSaving, setModalIsSaving] = useState(false)
  const [modalSentSaveApi, setModalSentSaveApi] = useState(false)
  const [modalIsDeleting, setModalIsDeleting] = useState(false)
  const [modalSentDeleteApi, setModalSentDeleteApi] = useState(false)

  //Modal Variables
  const [modalCohortFilterName, setModalCohortFilterName] = useState(null)
  const [modalCohortFilterSlices, setModalCohortFilterSlices] = useState({})
  const [modalCohortFilterId, setModalCohortFilterId] = useState(null)

  //Check job_status
  useEffect(() => {
    if(slices === null){
      get_slices()
    }
    if(cohortFilters === null){
        get_cohort_filters()
    }
    
    if(modalIsSaving && !modalSentSaveApi){
        save_modal_cohort_filter()
        setModalSentSaveApi(true)
    }
    else if(modalIsDeleting && !modalSentDeleteApi){
        delete_modal_cohort_filter()
        setModalSentDeleteApi(true)
    }

  })

  //Api Calls
  async function get_slices(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'slices',
        func:"get_slices"
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      
      for(var i=0;i<Object.keys(response).length;i++){
        var slice_id = Object.keys(response)[i]
        response[slice_id]['search_values'] = JSON.parse(response[slice_id]['search_values'])
      }
      setSlices(response)
      
    } 
    catch(err){
      console.log('get_slices body', body)
      console.log('get_slices response', response)
      throw new Error(err)
    }
  }

  async function get_cohort_filters(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'app_cohort_filters',
        func:"get_cohort_filters"
      }
      response = await send_request(body)
      response = JSON.parse(response)
      
      for(var i=0;i<Object.keys(response).length;i++){
        var cohort_filter_id = Object.keys(response)[i]
        response[cohort_filter_id]['description'] = JSON.parse(response[cohort_filter_id]['description'])
      }
      setCohortFilters(response)
      
    } 
    catch(err){
      console.log('get_cohort_filters body', body)
      console.log('get_cohort_filters response', response)
      throw new Error(err)
    }
  }

  async function save_modal_cohort_filter(){

    var body = ''
    var response = ''
    try{
      body = { 
        path:'app_cohort_filters',
        func:"save_cohort_filter",
        nice_name: modalCohortFilterName,
        description: modalCohortFilterSlices,
        cohort_filter_id: parseInt(modalCohortFilterId)
      }
      console.log('save_modal_cohort_filter body', body)
      
      response = await send_request(body)
      flushSync(
        setCohortFilters(null),
        setModalOpen(false),
        setModalIsSaving(false),
        setModalSentSaveApi(false)
      )
    } 
    catch(err){
      console.log('save_modal_cohort_filter body', body)
      console.log('save_modal_cohort_filter response', response)
      throw new Error(err)
    }
  }

  async function delete_modal_cohort_filter(){

    var body = ''
    var response = ''
    try{
      body = { 
        path:'app_cohort_filters',
        func:"delete_cohort_filter",
        cohort_filter_id: parseInt(modalCohortFilterId)
      }
      response = await send_request(body)
      flushSync(
        setCohortFilters(null),
        setModalOpen(false),
        setModalIsDeleting(false),
        setModalSentDeleteApi(false)
      )
    } 
    catch(err){
      console.log('delete_modal_cohort_filter body', body)
      console.log('delete_modal_cohort_filter response', response)
      throw new Error(err)
    }
  }



    //Rendering 
    function render_body(){
        if(slices === null || cohortFilters === null){
            return(
            loading_gif('Loading Cohort Filters')
            )
        }

        var rows = []
        for(var i = 0; i<Object.keys(cohortFilters).length;i++){
            var cohort_filter_id = Object.keys(cohortFilters)[i]
            var cohort_filter = cohortFilters[cohort_filter_id]

            

            var description = ''
            if(cohort_filter['description'].length > 0){
                for(var j=0; j<cohort_filter['description'].length;j++){
                    var slice_id = cohort_filter['description'][j]
                    var slice_name = slices[slice_id]['slice_name']
                    description += slice_name + ', '
                }
                description = description.substring(0, description.length - 2)
            }
            


            var t_ele = 
            <React.Fragment>
                <Row>
                    <Col>
                        {cohort_filter['nice_name']}
                    </Col>
                    <Col>
                        {description}
                    </Col>
                    <Col>
                        <h6 className='hover_underline' cohort_filter_id={cohort_filter_id} onClick={editCohortFilter}>Edit</h6>
                    </Col>
                </Row>
            </React.Fragment>

            rows.push(t_ele)
        }

        return(
            <React.Fragment>
            <Row>
                <Col>
                  <h5>Name</h5>
                </Col>
                <Col>
                  <h5>Slices</h5>
                </Col>   
                <Col>
                {/*Edit*/}
                </Col>
            </Row>
            {rows}
            <Row className='mt-3'>
                <Col>
                    <h6 className='hover_underline' cohort_filter_id={-1} onClick={editCohortFilter}>Add New +</h6>
                </Col>
            </Row>
            </React.Fragment>
        )

    }

    function render_modal(){
        if(modalCohortFilterId === null){
            return ''
        }

        debugger

        //Get slice dropdown options
        var slice_options = []
        for(var i=0; i<Object.keys(slices).length;i++){
            var slice_id = Object.keys(slices)[i]
            var slice_name = slices[slice_id]['slice_name']
            slice_options.push(<option value={slice_id}>{slice_name}</option>)
        }

        
        var values = modalCohortFilterSlices
        if(values.length === 0){
            values = ['']
        }


        var inp_value_list = []
        for(var i=0;i<values.length;i++){
            var inp_class = i == 0 ? '' : 'mt-1'
            inp_value_list.push(
            <React.Fragment>
                <Row>
                <Col>
                    <select defaultValue={values[i]} className={inp_class} key={'inp_modal_value_' + i} id={'inp_modal_value_' + i}>{slice_options}</select>
                </Col>
                </Row>
            </React.Fragment>
            )
        }


        var btn_add_new = <h6 className='hover_underline' onClick={modal_add_new_value}>Add New +</h6>

        var btn_save = modalIsSaving ? loading_gif('Saving'): <button className='btn btn-lg btn-warning mt-3' onClick={set_modal_save}>Save</button>
        if(modalIsDeleting){
            btn_save = ''
        }

        var btn_delete = modalIsSaving || modalCohortFilterId == -1 ? '': <button className='btn btn-lg btn-warning mt-3' onClick={set_modal_delete}>Delete</button>
        if(modalIsDeleting){
            btn_delete  =   loading_gif('Deleting')
        }


        return(
            <React.Fragment>
                <Modal
                  isOpen={modalOpen}
                  onRequestClose={set_modal_close}
                  style={customStyles}>
                  <Row>
                      <Col>
                          Filter Name
                      </Col>
                      <Col>
                          Slices
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                      <input type='text' defaultValue={modalCohortFilterName} key={'inp_modal_cohort_filter_name'} id={'inp_modal_cohort_filter_name'}/>
                      </Col>
              
                      <Col>
                      {inp_value_list}
                      {btn_add_new}
                      </Col>
                  </Row>
                  <Row>
                      <Col>{btn_delete}</Col>
                      <Col>{btn_save}</Col>
                  </Row>
                </Modal>
            </React.Fragment>
        )
    }

    //Modal Functions
    function editCohortFilter(e){
        var cohort_filter_id = parseInt(e.target.getAttribute('cohort_filter_id'))

        var cohort_filter = cohort_filter_id == -1 ? null : cohortFilters[cohort_filter_id]
        var slices = cohort_filter_id == -1 ? [] : cohort_filter['description']
        var nice_name = cohort_filter_id == -1 ? null : cohort_filter['nice_name']


        flushSync(
            setModalOpen(true),
            setModalCohortFilterName(nice_name),
            setModalCohortFilterSlices(slices),
            setModalCohortFilterId(cohort_filter_id)
        )

    }

    function set_modal_close(){
        setModalOpen(false)
    }

    function set_modal_delete(){
      setModalIsDeleting(true)
    }

    function set_modal_save(){

        var values = []
        var i = 0
        while(i !== -1){
        try{
            var val = get_val('inp_modal_value_' + i)
            if(val === ''){
            i += 1
            continue
            }
            values.push(val)
            i += 1
        }
        catch(e){
            i = -1
        }
    }



    flushSync(
      setModalIsSaving(true),
      setModalCohortFilterName(get_val('inp_modal_cohort_filter_name')),
      setModalCohortFilterSlices(values),
    )
    }

    function modal_add_new_value(){
        var values = []
        for(var i = 0;i<modalCohortFilterSlices.length;i++){
            values.push(modalCohortFilterSlices[i])
        }
        values.push('')

        flushSync(
            setModalCohortFilterSlices(values)
        )
    }

    function set_modal_close(){
        setModalOpen(false)
    }

  //Output
  return(
    <Container>
        <Row>
          <Col lg ="auto">
            <h1>Cohort Filters</h1>
          </Col>
        </Row>
        <Row className=''>
          <Col>
            {render_body()}
          </Col>
        </Row>

        {render_modal()}
    </Container>
  )
    
    
  


 }

  
    