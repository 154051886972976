import React from "react";
import {send_request, format_dollar} from "../globals/globals";
import loading_gif from "../globals/loading_gif";
import { get_color, format_percent } from "../globals/globals";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ResponsiveContainer } from "recharts";


export const  DNDGraphs = (props) =>{
  const [graphData, setGraphData] = useState({})
  
  const graph_colors = {'green': '#87ff63', 'yellow': '#ffea63', 'blue': '#63a4ff'}

  const ao_measures = {
    total_lever_number_of_new: {label: 'Number of New'}, 
    new_order_frequency: {label: 'New Order Frequency'}, 
    new_units_per_order: {label: 'New Units Per Order'}, 
    new_price_per_unit: {label: 'New Price Per Unit'}, 
    existing_retention: {label: 'Retention'}, 
    existing_order_frequency: {label: 'Returning Order Frequency'}, 
    existing_units_per_order: {label: 'Returning Units Per Order'}, 
    existing_price_per_unit: {label: 'Returning Price Per Unit'}, 
  }

  const prev_props = useRef();

  useEffect(() => {
    console.log('prev props:', prev_props.selected_measure)
    console.log('props:', props)
    if(props.selected_calendar_date !== null && (Object.keys(graphData).length === 0 || 
      props.selected_measure !== prev_props.selected_measure || 
      props.selected_calendar_type != prev_props.selected_calendar_type || 
      (props.selected_calendar_date !== undefined && props.selected_calendar_date['value'] != prev_props.selected_calendar_date['value'])
      ) && props.selected_measure !== undefined && props.selected_calendar_date !== undefined && Object.keys(props.selected_calendar_date).includes('value')
      ){
      prev_props.selected_measure = props.selected_measure
      prev_props.selected_calendar_type = props.selected_calendar_type
      prev_props.selected_calendar_date = props.selected_calendar_date
      get_graph_data()
    }
    if(props.selected_calendar_type !== null && props.selected_calendar_type !== prev_props.selected_calendar_type){
      prev_props.selected_calendar_type = props.selected_calendar_type
      setGraphData({})
    }
    else if(props.cohort_filter_id !== null && props.cohort_filter_id !== prev_props.cohort_filter_id){
      prev_props.cohort_filter_id = props.cohort_filter_id
      setGraphData({})
    }
  });



  //Api Calls
  async function get_graph_data(){
    var body = ''
    
    var response = ''
    
    var values = [props.selected_measure]
    if(Object.keys(ao_measures).includes(props.selected_measure)){
      values = [props.selected_measure + '_v', props.selected_measure + '_y']
    }

    try{
      
      body = {
        path:"get_graphs",
        func:"get_data",
        as_of: props.selected_calendar_date['value'],
        calendar_type: props.selected_calendar_type,
        cohort_filter_id: props.cohort_filter_id,
        values: values,
        aggregate: ['cols']
      };
      response = await send_request(body)
      response = JSON.parse(response)
      console.log('get_graph_data body:', body)
      console.log('get_graph_data response:', response)
      setGraphData(response)
    }
    catch(err){
      console.log('get_graph_data body:', body)
      console.log('get_graph_data response:', response)
      throw new Error(err)
    }
    
  }
  

  //Rendering
  function render_graph(){
    if(Object.keys(graphData).length === 0){
      return(
        loading_gif('Loading Graph')
      )
    }

    var number_of_columns_to_graph = 12
    
  

    var as_of = Date.parse(props.selected_calendar_date['value'])
    var data_to_graph = []
    var tooltip_payload = []
    var temp_tooltip_payload = {}
    for(var i=Object.keys(graphData).length - 1 - number_of_columns_to_graph; i < Object.keys(graphData).length; i++){
      var col_key = Object.keys(graphData)[i]
      var col_date = Date.parse(col_key)

      
      if(col_date <= as_of){
        var data = graphData[col_key]
        if(props.selected_calendar_type === 'month_end_445'){
          for(var j=0;j<props.calendar['month_end_445'].length;j++){
            if(col_key === props.calendar['month_end_445'][j]['end_date']){
              col_key = props.calendar['month_end_445'][j]['label']
              break
            }
          }
        }
        data['col'] = col_key
        data_to_graph.push(data)
        temp_tooltip_payload = {name: col_key, value:data[props.selected_measure + '_v'], unit:col_key + '_2'}
        tooltip_payload.push(temp_tooltip_payload)
        
      }
    }

    

    const tick_format_graph_dollar = (tickItem) => {
      return format_dollar(tickItem, false)
    }

  
    const format_graph_percent = (tickItem) => {
      //console.log('tickitem', tickItem)
      return format_percent(tickItem, false)
    }

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        console.log('active payload', active, payload, label)

        var tooltip_payload = []
        for(var i=0;i<payload.length;i++){
          var pl = payload[i]
          var val = pl['value']
          if(pl['name'].indexOf('YoY') === 0 || pl['name'].indexOf('%') === 0){
            val = format_percent(pl['value'], true)
          }
          else if(pl['name'].indexOf('$') === 0){
            val = format_dollar(pl['value'], true)
          }

          var t_payload = (
            <React.Fragment>
              <p className="mt-0 pt-0 mb-0 pb-0"  style={{color: pl['stroke']}}>{pl['name'] + ': ' + val}</p>
            </React.Fragment>
          )
          tooltip_payload.push(t_payload)
        }

        return (
          <div className="custom-tooltip card p-2">
            <p className="label mt-0 pt-0 mb-0 pb-0">{`${label}`}</p>
            {tooltip_payload}
          </div>
        );
      }
    
      return null;
    };

    const graph_text_style = 
        {
          fontSize: '0.8rem',
          fontFamily: 'Arial',
      }


      var line_1 = ''
      var line_2 = ''
      var y_axis_1 = ''
      var y_axis_2 = ''
      var legend_payload = ''
    if(Object.keys(ao_measures).includes(props.selected_measure)){
      y_axis_1 =<YAxis yAxisId="left" label={{ value: '$ Impact', angle: -90, position: 'center' }} tickFormatter={tick_format_graph_dollar} tickSize={0} width={150}style={graph_text_style} />
      line_1 = <Line name={'$ Impact'} yAxisId='left' type="monotone" dataKey={props.selected_measure + '_v' } stroke={graph_colors['green']} activeDot={{ r: 8 }}  strokeWidth={3} />

      y_axis_2 =<YAxis yAxisId="right" label={{ value: '% Impact', angle: -90, position: 'inside' }} tickFormatter={format_graph_percent} tickSize={0} width={100}style={graph_text_style} orientation="right"/>
      line_2 = <Line name='% Impact' yAxisId='right' type="monotone" dataKey={props.selected_measure + '_y' } stroke={graph_colors['blue']} activeDot={{ r: 8 }} strokeWidth={3}/>

      legend_payload=[{'value': 'Impact Dollars', type:'line', id:'impact'}]

    }
    
 
    const renderLineChart = (
      <ResponsiveContainer width='100%' aspect={4.0/1.5}>
        <LineChart  data={data_to_graph} margin={{ top: 0, right: 0, left: 0, bottom: 5 }}>
          
          
          <XAxis dataKey="col" style={graph_text_style} angle={-45} tick={{dy:20, dx:-20}}  height={60} padding={{left:10, right:10}}  interval={0}/>
          
          <Tooltip content={CustomTooltip}/>
          <Legend verticalAlign="bottom" align="center" layout="vertical"/>
          {y_axis_1}
          {line_1}
          {y_axis_2}
          {line_2}
          
        </LineChart>
      </ResponsiveContainer>
      
    );




    return(
      <React.Fragment>
        <Row>
          <Col className="text-center w-100">
            <h5 className="text-center">{ao_measures[props.selected_measure]['label']}</h5>
          </Col>
        </Row>
        <Row>
          <Col className="text-center w-100">
            {renderLineChart}
          </Col>
        </Row>
      </React.Fragment>      
    )

  }


  return (
      <React.Fragment>
          {render_graph()}
      </React.Fragment>
      )
  










}


