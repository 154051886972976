import React from "react";
import { Container } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import '../globals/globals.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from "react";
import { useState } from "react";
import { send_request} from '../globals/globals.js';


export const  AdminDashboard = (props) =>{
  const [newCompanyID, setNewCompanyID] = useState(null)
  const [sentCompanyIDChange, setSentCompanyIDChange] = useState(null)
  const [isCompanyIDChanged, setIsCompanyIDChanged] = useState(null)
  

  //Check job_status
  useEffect(() => {
    if(newCompanyID !== null && !sentCompanyIDChange){
      change_company_id()
      setSentCompanyIDChange(true)
    }
    
  })


 

  // api calls
  async function change_company_id(){
    debugger
    var body = ''
    var response = ''
    try{
      body = { 
        path:'admin_dashboard',
        func:"update_admin_company_id",
        company_id: newCompanyID
      }
      
      response = await send_request(body)
      debugger
      alert('Company ID changed. Log out and in for changes to take effect. ')

    } 
    catch(err){
      console.log('change_company_id body', body)
      console.log('change_company_id response', response)
      throw new Error(err)
    }

  }



    function set_new_company_id(){
      var new_id = document.getElementById('inp_company_id').value
      setNewCompanyID(new_id)
    }

  


  function render_change_company(){


    return(
      <React.Fragment>
        <Row>
          <Col>
            Change Company to ID:
          </Col>
        </Row>
        <Row>
          <Col lg={2}>
            <input id="inp_company_id" key="inp_company_id" type='number'  className={'w-100 text-align-center '}/>
          </Col>
          <Col>
            <button onClick={set_new_company_id} className='btn btn-lg btn-warning' id='btn_update_company_id'>Update Company ID</button>
          </Col>
        </Row>
        

      </React.Fragment>
    )
  }




  //Output
    return (
      <React.Fragment>
      <Container>
        <Row>
          <h1>Admin Dashboard</h1>
        </Row>
        {render_change_company()}
          

      </Container>
   
    
    </React.Fragment>
    )
    
    
  


 }

  
    


  


    





  
















