
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../../globals/globals.js';
import loading_gif from "../../globals/loading_gif.js";
import btn_right_arrow from "../../images/btn_right_arrow.svg";
import btn_left_arrow from "../../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';
import Modal from 'react-modal';

import { Tooltip } from 'react-tooltip'

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

export const  PageManageSlices = (props) =>{
  const [slices, setSlices] = useState(null)
  const [finalColumnNames, setFinalColumnNames] = useState(null)
  

  //Modal Open
  const [modalOpen, setModalOpen] = useState(false)
  const [modalIsSaving, setModalIsSaving] = useState(false)
  const [modalSentSaveApi, setModalSentSaveApi] = useState(false)
  const [modalIsDeleting, setModalIsDeleting] = useState(false)
  const [modalSentDeleteApi, setModalSentDeleteApi] = useState(false)

  //Modal Variables
  const [modalSliceName, setModalSliceName] = useState(null)
  const [modalColumnName, setModalColumnName] = useState(null)
  const [modalOperator, setModalOperator] = useState(null)
  const [modalValues, setModalValues] = useState({})
  const [modalSliceId, setSliceId] = useState(null)

  //Check job_status
  useEffect(() => {
    if(slices === null){
      get_slices()
    }
    else if(finalColumnNames === null){
        get_final_column_names()
    }
    else if(modalIsSaving && !modalSentSaveApi){
        save_modal_slice()
        setModalSentSaveApi(true)
    }
    else if(modalIsDeleting && !modalSentDeleteApi){
        delete_modal_slice()
        setModalSentDeleteApi(true)
    }

  })

  //Api Calls
  async function get_slices(){
    var body = ''
    var response = ''
    try{
      //Put together the body
    
      body = { 
        path:'slices',
        func:"get_slices"
      }
      
      response = await send_request(body)
      response = JSON.parse(response)
      for(var i=0;i<Object.keys(response).length;i++){
        var slice_id = Object.keys(response)[i]
        response[slice_id]['search_values'] = JSON.parse(response[slice_id]['search_values'])
      }
      setSlices(response)
      
    } 
    catch(err){
      console.log('get_slices body', body)
      console.log('get_slices response', response)
      throw new Error(err)
    }
    
      

  }

  async function get_final_column_names(){
    var body = ''
    var response = ''
    try{
      body = { 
        path:'global_filters',
        func:"get_final_column_names"
      }
      response = await send_request(body)
      console.log('get_final_column_names body:', body)
      console.log('get_final_column_names response', response)
      
      setFinalColumnNames(response)
    } 
    catch(err){
      console.log('get_final_column_names body', body)
      console.log('get_final_column_names response', response)
      throw new Error(err)
    }
  }

  async function save_modal_slice(){

    var body = ''
    var response = ''
    try{
      body = { 
        path:'slices',
        func:"save_slice",
        search_column: modalColumnName,
        not_equal_to: modalOperator,
        search_values: modalValues,
        slice_id: parseInt(modalSliceId),
        slice_name: modalSliceName
      }
      response = await send_request(body)
      flushSync(
        setSlices(null),
        setModalOpen(false),
        setModalIsSaving(false),
        setModalSentSaveApi(false)
      )
    } 
    catch(err){
      console.log('save_modal_slice body', body)
      console.log('save_modal_slice response', response)
      throw new Error(err)
    }
  }

  async function delete_modal_slice(){

    var body = ''
    var response = ''
    try{
      body = { 
        path:'slices',
        func:"delete_slice",
        slice_id: parseInt(modalSliceId)
      }
      
      response = await send_request(body)
      console.log('delete_modal_slice body:', body)
      flushSync(
        setSlices(null),
        setModalOpen(false),
        setModalIsDeleting(false),
        setModalSentDeleteApi(false)
      )
    } 
    catch(err){
      console.log('delete_modal_slice body', body)
      console.log('delete_modal_slice response', response)
      throw new Error(err)
    }
  }



    //Rendering 
    function render_body(){
        if(slices === null){
            return(
            loading_gif('Loading Slices')
            )
        }

        var rows = []
        for(var i = 0; i<Object.keys(slices).length;i++){
            var slice_id = Object.keys(slices)[i]
            var slice_info = slices[slice_id]

            var operator = slice_info['not_equal_to'] ? 'Does Not Contain' : 'Contains'
            

            var search_values = ''
            if(slice_info['search_values'].length > 0){
                for(var j=0; j<slice_info['search_values'].length;j++){
                    search_values += slice_info['search_values'][j] + ', '
                }
                search_values = search_values.substring(0, search_values.length - 2)
            }
            


            var t_ele = 
            <React.Fragment>
                <Row>
                    <Col>
                        {slice_info['slice_name']}
                    </Col>
                    <Col>
                        {slice_info['search_column']}
                    </Col>
                    <Col>
                        {operator}
                    </Col>
                    <Col>
                        {search_values}
                    </Col>
                    <Col>
                        <h6 className='hover_underline' slice_id={slice_id} onClick={editSlice}>Edit</h6>
                    </Col>
                </Row>
            </React.Fragment>

            rows.push(t_ele)
        }

        return(
            <React.Fragment>
            <Row>
                <Col>
                <h5>Name</h5>
                </Col>
                <Col>
                <h5>Column</h5>
                </Col>
                <Col>
                    <h5>Operator</h5>
                </Col>
                <Col>
                    <h5>Search Values</h5>
                </Col>
                
                <Col>
                {/*Edit*/}
                </Col>
            </Row>
            {rows}
            <Row className='mt-3'>
                <Col>
                    <h6 className='hover_underline' slice_id={-1} onClick={editSlice}>Add New +</h6>
                </Col>
            </Row>
            </React.Fragment>
        )

    }

    function render_modal(){
        if(modalSliceId === null){
            return ''
        }

        var column_options = []
        for(var i=0; i<finalColumnNames.length;i++){
            column_options.push(<option value={finalColumnNames[i]}>{finalColumnNames[i]}</option>)
        }

        var values = modalValues
        if(values.length === 0){
            values = ['']
        }


        var inp_value_list = []
        for(var i=0;i<values.length;i++){
            var inp_class = i == 0 ? '' : 'mt-1'
            inp_value_list.push(
            <React.Fragment>
                <Row>
                <Col>
                    <input type='text' defaultValue={values[i]} className={inp_class} key={'inp_modal_value_' + i} id={'inp_modal_value_' + i}/>
                </Col>
                </Row>
            </React.Fragment>
            )
        }


        var btn_add_new = <h6 className='hover_underline' onClick={modal_add_new_value}>Add New +</h6>

        var btn_save = modalIsSaving ? loading_gif('Saving'): <button className='btn btn-lg btn-warning mt-3' onClick={set_modal_save}>Save</button>
        if(modalIsDeleting){
            btn_save = ''
        }

        var btn_delete = modalIsSaving || modalSliceId == -1 ? '': <button className='btn btn-lg btn-warning mt-3' onClick={set_modal_delete}>Delete</button>
        if(modalIsDeleting){
            btn_delete  =   loading_gif('Deleting')
        }

        var operator = modalOperator ? 'not_equal_to' : 'equal_to'


        return(
            <React.Fragment>
                <Modal
                isOpen={modalOpen}
                onRequestClose={set_modal_close}
                style={customStyles}>
                <Row>
                    <Col>
                        Slice Name
                    </Col>
                    <Col>
                        Search Column
                    </Col>
                    <Col>
                        Operator
                    </Col>
                    <Col>
                        Value
                    </Col>
                    
                </Row>
                <Row>
                    <Col>
                    <input type='text' defaultValue={modalSliceName} key={'inp_modal_slice_name'} id={'inp_modal_slice_name'}/>
                    </Col>
                    <Col>
                        <select defaultValue={modalColumnName} className='w-100' id='inp_modal_column_name'>{column_options}</select>
                    </Col>
                    <Col>
                    <select defaultValue={operator}  className='w-100' id='inp_modal_operator' onChange={set_modal_operator}>
                    <option value='equal_to'>Contains</option>
                    <option value='not_equal_to'>Does Not Contain</option>
                    </select>
                    
                    </Col>
                    <Col>
                    {inp_value_list}
                    {btn_add_new}
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>{btn_delete}</Col>
                    <Col>
                    {btn_save}
                    </Col>
                </Row>
                
                </Modal>
            </React.Fragment>
        )
    }

    //Modal Functions
    function editSlice(e){
        var slice_id = parseInt(e.target.getAttribute('slice_id'))

        var slice = slice_id == -1 ? null : slices[slice_id]
        var operator = slice_id == -1 ? '' : slice['not_equal_to']
        var column_name = slice_id == -1 ? '' : slice['search_column']
        var value = slice_id == -1 ? '' : slice['search_values']
        var slice_name = slice_id == -1 ? '' : slice['slice_name']


        flushSync(
            setModalOpen(true),
            setModalColumnName(column_name),
            setModalOperator(operator),
            setModalValues(value),
            setSliceId(slice_id),
            setModalSliceName(slice_name)
        )

    }

    function set_modal_close(){
        setModalOpen(false)
    }

    function set_modal_delete(){
     setModalIsDeleting(true)
    }

    function set_modal_save(){

        var values = []
        var i = 0
        while(i !== -1){
        try{
            var val = get_val('inp_modal_value_' + i)
            if(val === ''){
            i += 1
            continue
            }
            values.push(val)
            i += 1
        }
        catch(e){
            i = -1
        }
    }



    flushSync(
      setModalIsSaving(true),
      setModalSliceName(get_val('inp_modal_slice_name')),
      setModalColumnName(get_val('inp_modal_column_name')),
      setModalOperator(get_val('inp_modal_operator')),
      setModalValues(values),
    )
    }

    function modal_add_new_value(){
        var values = []
        for(var i = 0;i<modalValues.length;i++){
            values.push(modalValues[i])
        }
        values.push('')

        flushSync(
            setModalValues(values)
        )
    }

    function set_modal_close(){
        setModalOpen(false)
    }

    function set_modal_operator(e){
        setModalOperator(e.target.value)
    }


  //Output
  return(
    <Container>
        <Row>
          <Col lg ="auto">
            <h1>Slices</h1>
          </Col>
        </Row>
        <Row className=''>
          <Col>
            {render_body()}
          </Col>
        </Row>

        {render_modal()}
    </Container>
  )
    
    
  


 }

  
    