
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SideMenu } from '../side_menu function/SideMenu.jsx';
import moment from "moment";
import {epoch_to_date_string, date_string_to_epoch, send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../globals/globals.js';
import loading_gif from "../globals/loading_gif";
import btn_right_arrow from "../images/btn_right_arrow.svg";
import btn_left_arrow from "../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import {get_calendar_selected_date} from '../side_menu function/SideMenu.jsx'



  
function get_ly_calendar_date(date_str, calendar_type, calendar){
  // date_str '2022-01-07' (example format)
  // calendar_type: One of: week_end_445, month_end_445, quarter_end_445, year_end_445
  // calendar: dict of calendar: {'week_end_445':{'2017-01-01': ...}, 'month_end_445': {...}, ...}

  var date = Date.parse(date_str)
  var t_calendar = calendar[calendar_type]

  var days = 364
  var hours = days * 24
  var minutes = hours * 60
  var seconds = minutes * 60
  var milliseconds = seconds * 1000
  var ly_date = date - milliseconds

  for(var i=0;i<Object.keys(t_calendar).length;i++){
    var end_date_key = t_calendar[i]['end_date']
    var end_date = Date.parse(end_date_key)
    if(ly_date == end_date){
      return end_date_key
    }
    else if(end_date < ly_date){//53rd week
      return end_date_key
    }
  }

  return null

}


export const  PageWhatIf = (props) =>{
  const queryParameters = new URLSearchParams(window.location.search)
  //URL Params
  const [outputFiles, setOutputFiles] = useState(queryParameters.get('output_files') === null ? 'false' : queryParameters.get('output_files'))
  

  //Side menu api
  const [filters, setFilters] = useState(window.localStorage.getItem('filters') === null ? null : JSON.parse(window.localStorage.getItem('filters')))
  const [calendar, setCalendar] = useState(window.localStorage.getItem('calendar') === null ? null : JSON.parse(window.localStorage.getItem('calendar')))

  //Side menu set
  const [firstTransaction, setFirstTransaction] = useState(window.localStorage.getItem('firstTransaction') === null ? null : (window.localStorage.getItem('firstTransaction')))
  const [lastTransaction, setLastTransaction] = useState(window.localStorage.getItem('lastTransaction') === null ? null : (window.localStorage.getItem('lastTransaction')))
  
  const [cohort_filter_id, setCohortFilterID] = useState(window.localStorage.getItem('cohort_filter_id') === null ? null : parseInt(window.localStorage.getItem('cohort_filter_id')))
  const [selected_calendar_type, setSelectedCalendarType] = useState(window.localStorage.getItem('selected_calendar_type') === null ? 'month_end_445' : window.localStorage.getItem('selected_calendar_type')) 
  const [selected_calendar_date, setSelectedCalendarDate] = useState(window.localStorage.getItem('selected_calendar_date') === null ? null : JSON.parse(window.localStorage.getItem('selected_calendar_date')))


  


  //Job Status
  const [parent_job_status_id, setParentJobStatusId] = useState(null)
  const [job_status, setJobStatus] = useState('')
  const [jobStepCounter, setJobStepCounter] = useState(-1)

  //Head Index
  const [projection_first_column_date_index, setProjectionFirstColumnDateIndex] = useState(null)
  const [lastProjectionYearEnd, setLastProjectionYearEnd] = useState(null)

  //Inputs and data
  const [inputSettings, setInputSettings] = useState(null)
  const [sentInputSettingsApi, setSentInputSettingsApi] = useState(null)
  
  const [data, setData] = useState(null) // This will be nested as cohort_filter_id -> selected_calendar_type -> selected_calendar_date
  const [sentGetDataApi, setSentGetDataApi] = useState(null)
  const [changed_input_settings, setChangedInputSettings] = useState({})
  const warningColors = {'red': '#e8b0b4', 'yellow': '#f7d661', 'white': '#ffffff'}

  //Save Input Settings
  const [saveProjectionSettings, setSaveProjectionSettings] = useState(false)
  const [sentSaveProjectionSettings, setSentSaveProjectionSettings] = useState(false)

  
  //const [app_state, setState] = useState(props['props'])
  //var [file, setFile] = useState()


  const prev_props = useRef();





  useEffect(() => {
    //Set Initial Values
    if(cohort_filter_id !== null && prev_props.cohort_filter_id === undefined){
      prev_props.cohort_filter_id = cohort_filter_id
    }
    if(selected_calendar_type !== null && prev_props.selected_calendar_type === undefined){
      prev_props.selected_calendar_type = selected_calendar_type
    }
    if(selected_calendar_date !== null && prev_props.selected_calendar_date === undefined){
      prev_props.selected_calendar_date = selected_calendar_date
    }
    if(lastProjectionYearEnd === null && cohort_filter_id !== null && filters !== null && calendar !== null){
      var last_transaction = filters[cohort_filter_id]['max_transaction_date']
      // Get the initial year end and the last year of projections date.
      for(var i=0;i<calendar['year_end_445'].length;i++){
        var end_date = calendar['year_end_445'][i]['end_date']
        if(Date.parse(end_date) < last_transaction){
          setLastProjectionYearEnd(calendar['year_end_445'][i-4]['end_date'])
          break
        }
      }
    }

    // Cohort Filter Changed
    if(cohort_filter_id !== prev_props.cohort_filter_id){
      prev_props.cohort_filter_id = cohort_filter_id
      

      flushSync(
        setInputSettings(null),
        setData(null),
        setSelectedCalendarDate(null),
        setProjectionFirstColumnDateIndex(null),
        setLastProjectionYearEnd(null)
      )
      return
    }
    
    //Type Changed
    else if(selected_calendar_type !== prev_props.selected_calendar_type){
      prev_props.selected_calendar_type = selected_calendar_type
      
      flushSync(
        //setInputSettings(null),
        //setData(null),
        setSelectedCalendarDate(null),
        setProjectionFirstColumnDateIndex(null)
      )
      return
    }

    else if(projection_first_column_date_index === null && calendar !== null){
      var selected_date = get_calendar_selected_date(lastTransaction, calendar, selected_calendar_type)
      var ind = selected_date['calendar_index'] - 1
      setProjectionFirstColumnDateIndex(ind)
      return
    }
    

    else if(parent_job_status_id !== null){
      const interval = setInterval(() => {

        const fetchData = async () => {
          const body = { 
            path:'job_status',
            func:"get_job_status",
            cohort_filter_id: cohort_filter_id,
            parent_job_status_id: parent_job_status_id,
            calendar_type: selected_calendar_type,
            exclusion_id: 1
          }
          console.log('job_status body', body)
          var response = await send_request(body)
          console.log('job_status response', response)
          if(response['status'] === 'finish' || response['status'] === 'no job found'){
              setJobStatus(response['status'])
              setJobStepCounter(-1)
              setParentJobStatusId(null)

          }
          else{
            
            setJobStatus(response['status'])
            setJobStepCounter(response['step_count'])

          }
        }

        fetchData()
        // make sure to catch any error
        .catch(console.error);

      }, 2000);
      return () => clearInterval(interval);
    
      
    }


    else if(saveProjectionSettings && !sentSaveProjectionSettings){
      save_projection_settings()
      flushSync(
        setSentSaveProjectionSettings(true),
        setInputSettings(null)
      )

      return
    }

    
   

  })

  // Get Projection Settings / Projection Data
  useEffect(() => {
    //debugger
    if(cohort_filter_id !== null && selected_calendar_date !== null && selected_calendar_type !== null){
      //We get input settings first before projection data
      
      if(!sentInputSettingsApi && inputSettings === null){  
        get_input_settings()
        setSentInputSettingsApi(true)

        return
      }
      else if(inputSettings !== null && data === null & selected_calendar_date['value'] !== null & parent_job_status_id === null & !saveProjectionSettings && !sentGetDataApi){ // Initial load
        get_projection_data()
        setSentGetDataApi(true)

        return
     }
    }
  });



  

  // api calls
  async function get_input_settings(){
    var body = ''
    var response = ''

    var selected_date = get_calendar_selected_date(lastTransaction, calendar, 'week_end_445')

    try{
      //Put together the body
      const as_of = selected_date['value']
      body = { 
        path:'whatif',
        func:"load_initial_projection_settings",
        cohort_filter_id: cohort_filter_id,
        as_of: as_of
      }
      console.log('get_input_settings body', body)
      
      response = await send_request(body)
      
      console.log('get_input_settings response', response)
      
      if(typeof response === 'string'){
        response = JSON.parse(response)
      }
    } 
    catch(err){
      console.log('get_input_settings body', body)
      console.log('get_input_settings response', response)
      throw new Error(err)
    }

    if(Object.keys(response).includes('message')){
      setInputSettings(response) 
    }
    else{
      for(var i=0; i<Object.keys(response['settings']).length; i++){
        var calendar_type = Object.keys(response['settings'])[i]
        response['settings'][calendar_type] = JSON.parse(response['settings'][calendar_type])
      }
      flushSync(
        setSentInputSettingsApi(null),
        setInputSettings(response['settings']) 
      )

      
    }
      

  }

  async function get_projection_data(){
    var body = ''
    var response = ''

    var selected_date = get_calendar_selected_date(lastTransaction, calendar, 'week_end_445')

    try{
      body = { 
        path:'whatif',
        func:"load_projection_data",
        cohort_filter_id: cohort_filter_id,
        as_of: selected_date['value'],
        calendar_type: selected_calendar_type,
        output_files: outputFiles
      }
      console.log('get_projection_data body', body)
      response = await send_request(body)

      if(Object.keys(response).includes('errorMessage')){
        throw new Error(response)
      }
    }
    catch(err){
      console.log('get_projection_data body', body)
      console.log('get_projection_data response', response)
      throw new Error(err)
    }
  
    const keys = Object.keys(response)
    if (keys.includes('projection_id')){
      console.log('projection_id' + response['projection_id'])
      if(outputFiles === 'true'){
        alert('projection_id for files: ' +  response['projection_id'])
      }
    }
    if(keys.includes('parent_job_status_id')){
      setParentJobStatusId(response['parent_job_status_id'])
      setData(null)
      setSentGetDataApi(null)
    }
    else if(keys.includes('message') && response['message'] === 'Not enough data to perform cohort forecasts.'){
      setData(response)
      setSentGetDataApi(null)
    }
    
    else{
      var cal_types = ['week_end_445', 'month_end_445', 'quarter_end_445', 'year_end_445']
      for(var i=0;i<cal_types.length;i++){
        var cal_type = cal_types[i]
        response['data'][cal_type] = JSON.parse(response['data'][cal_type])
      }
      

      setData(response)  
      setSentGetDataApi(null)
    }
  }

  function set_state_to_save_projection_settings(){
    var need_resend_get_data_api = false
    var current_settings = inputSettings[selected_calendar_type]
    for(var i=0;i<Object.keys(changed_input_settings).length;i++){
      var key = Object.keys(changed_input_settings)[i]

      var changed_settings = changed_input_settings[key]
      var new_input_type = changed_settings['input_type']
      var new_input_val = changed_settings['input_val']

      var current_input_type = current_settings[key]['input_type']
      var current_input_val = current_settings[key]['input_val']

      if(new_input_type !== current_input_type || new_input_val !== current_input_val){
        need_resend_get_data_api = true
      }

      inputSettings[selected_calendar_type][key] = changed_settings
    }

    if(need_resend_get_data_api){
      var set_data = null
    }
    else{
      var set_data = data
    }
    

    //variable_operating_expense
    //gross_margin_perc

    flushSync(
      setParentJobStatusId(null),
      setData(set_data),
      setChangedInputSettings({}),
      setInputSettings(inputSettings),
      setSaveProjectionSettings(true),
      setSentSaveProjectionSettings(false)
    )
  }

  async function save_projection_settings(){
    
    var current_settings = inputSettings[selected_calendar_type]
    var t_input_settings = {}
    for(var i=0;i<Object.keys(current_settings).length;i++){
      var key = Object.keys(current_settings)[i]
      t_input_settings[key] = {}
      t_input_settings[key]['input_type'] = current_settings[key]['input_type']
      t_input_settings[key]['input_val'] = current_settings[key]['input_val']
      t_input_settings[key]['variable_operating_expense'] = current_settings[key]['variable_operating_expense']
      t_input_settings[key]['gross_margin_perc'] = current_settings[key]['gross_margin_perc']

    }


    var body = ''
    var response = ''
    try{
      const as_of = selected_calendar_date.value
      body = { 
        path:'whatif',
        func:"save_projection_settings",
        cohort_filter_id: cohort_filter_id,
        as_of: as_of,
        calendar_type: selected_calendar_type,
        projection_inputs: t_input_settings
      }
      console.log('save_projection_settings body', body)
      
      response = await send_request(body)
      

      console.log('save_projection_settings response', response)
      
    }
    catch(err){
      console.log('save_projection_settings body', body)
      console.log('save_projection_settings response', response)
      throw new Error(err)
    }

    flushSync(
      setSaveProjectionSettings(false),
      setSentSaveProjectionSettings(false),
      setInputSettings(null)  

    )
  }



  // Button functions
  function change_projection_date(which){
    console.log('change_projection_date', projection_first_column_date_index)

    //var new_changed_input_settings = get_changed_projection_settings()

    var new_index = projection_first_column_date_index
    var interval = 4
    if(which === 'left'){
      new_index += interval
      new_index = Math.min(new_index, calendar[selected_calendar_type].length - 1)
    }
    else{
      new_index -= interval

      new_index = Math.max(new_index, 0)

    }

    

    flushSync(
      setProjectionFirstColumnDateIndex(new_index),
      //setChangedInputSettings(new_changed_input_settings)
    )
    
    
  }





  // render functions
  function body_loader(){
    
    if(selected_calendar_date === null){
      return
    }
    else if(selected_calendar_type !== prev_props.selected_calendar_type || projection_first_column_date_index === null){
      return
    }
    else if(inputSettings === null){
      return(<React.Fragment><Row><Col>{loading_gif('Loading Inputs')}</Col></Row></React.Fragment>)
    }
    else if((Object.keys(inputSettings).includes('message') && inputSettings['message'] === 'Not enough data to perform cohort forecasts.') || (data !== null && Object.keys(data).includes('message') && data['message'] === 'Not enough data to perform cohort forecasts.')){
      return(
        <React.Fragment><Row><Col>
          <h6>The selected filter does not have enough data to generate a projection. Please select a different filter.</h6>
        </Col></Row></React.Fragment>
      )
    }
    else if(saveProjectionSettings){
      return(
        <React.Fragment><Row><Col>
          {loading_gif('Saving Inputs')}
        </Col></Row></React.Fragment>
      )
    }


    //debugger

      var is_data_loading = null
      var loading_data_status = 'Running Projections'
      if(parent_job_status_id !== null){
        if(jobStepCounter === -1){
          loading_data_status = 'Running Projection'
        }
        else{
          loading_data_status = jobStepCounter + '/18 ' + job_status
        }
        

        is_data_loading = loading_gif(loading_data_status)
      }
      else if(data === null){
        is_data_loading = loading_gif('Loading Data')
      }


      var btn_update_class = 'btn btn-lg btn-warning d-none'
      var update_warning_class = 'd-none'
      if(cohort_filter_id !== 0 && Object.keys(changed_input_settings).length > 0){
        btn_update_class = 'btn btn-lg btn-warning'
        update_warning_class = ''
      }
      
      
      var header = calendar[selected_calendar_type][parseInt(projection_first_column_date_index)-0]

      //Tooltips have to be rendered last so they appear on top of everything, otherwise they may be fully/partially hidden by later rendered components.
      var list_tooltips = []
      for(var i=0;i<4;i++){
        var end_date = calendar[selected_calendar_type][parseInt(projection_first_column_date_index)-i]['end_date']
        var tooltips = ['tooltip_inp_type_', 'tooltip_inp_val_', 'inp_gross_margin_tooltip_', 'inp_voe_tooltip_', 'tooltip_header_inp_gross_margin_', 'tooltip_header_inp_voe_', 'tooltip_header_revenue_', 'tooltip_header_pro_forma_gross_profit_', 'tooltip_header_pro_forma_voe_', 'tooltip_header_pro_forma_contribution_', 'tooltip_header_pro_forma_net_contribution_', 'tooltip_header_CLTR_']
        for(var j=0; j<tooltips.length;j++){
          var t_ele = <Tooltip id={tooltips[j] + end_date} />
          list_tooltips.push(t_ele)
        }

      }

      
          

      
      return(
        <React.Fragment>
          <Row>
            <Col lg="auto" className='mt-3'>
              <p className={update_warning_class}>Changes made in one calendar view won't be updated in other calendar views until saved.</p>
            </Col>
          </Row>
          <Row>
          <Col lg="3" className='mt-1'>
            <button onClick={set_state_to_save_projection_settings} className={btn_update_class} id='btn_update_projections'>Update Projection</button>
            <div id='loading_gif_saving_projections' className='d-none'>
              {loading_gif('Saving Projections')}
            </div>
          </Col>
          </Row>
          <Row lg="auto">
            
            {render_column(0)}
            {render_column(1)}
            {render_column(2)}
            {render_column(3)}
            {list_tooltips}
            <div id="fiscal_year_container">
              {render_fiscal_years(header['end_date'])}
            </div>
          </Row>
          <Row>
            <Col>
              {is_data_loading}
            </Col>
          </Row>
        </React.Fragment>
      )
    
    
  }

    // Child
    function render_column(i){
      
      var header = calendar[selected_calendar_type][parseInt(projection_first_column_date_index)-i]


      var as_of = Date.parse(selected_calendar_date['value'])
      var this_period_end = Date.parse(header['end_date'])
      var this_period_start = Date.parse(header['start_date'])



      var data_type = 'projection'
      if(this_period_end <= as_of){
        data_type = 'actual'
      }
      else if(this_period_end > as_of && this_period_start <= as_of){
        data_type = 'partial'
      }

      
      var rendered_outputs = ''  // for warnings
      if(parent_job_status_id !== null){
        if(i === 0){
          //console.log('skipping render_column due to  parent_job_status_id: ', parent_job_status_id)
        }
        rendered_outputs = ''
      }
      else{
        rendered_outputs = render_output(i, header, data_type)
      }



      if(i===0){
        //debugger
      }
      
      return(
        <React.Fragment>
          <Col lg="2" className="ms-2">
              {render_header(header, i, data_type)}
              
              {render_inputs(header['end_date'], i)}
              <div id={'output_container_' + i}>
              {rendered_outputs}
              </div>
      
          </Col>
        </React.Fragment>
      )
    }

      // Children
      function render_header(header, i, data_type){
        
        var end_reached = <h5>&nbsp;</h5>
        

        if(i === 0 ){
          var left_arrow = 
            <Col className='ps-0 pe-0' lg={2}>
              <img alt="Left Arrow" src={btn_left_arrow} className="" height={37} width={37} onClick={newVal => change_projection_date('left')}/>
            </Col>

          if(data !== null && data !== undefined && Object.keys(data).length > 0 && header['end_date'] === Object.keys(data['data'])[0]){
            left_arrow = ''
            end_reached = <h6>Start of Projections</h6>
          }

          return(
            <React.Fragment>
              <Row className='mt-5'>
                {left_arrow}
                <Col className='card'>
                    <h5 className='align-items-center mt-1 float-end'>{header['label']}</h5>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                {end_reached}
                </Col>
              </Row>
            </React.Fragment>
          )
        }
        else if(i === 3){
          var right_arrow = 
            <Col className='pe-0 pe-0' lg={2}>
              <img alt="Right Arrow" src={btn_right_arrow} className="" height={37} width={37} onClick={newval=>change_projection_date('right')}/>
            </Col>

          

          var current_year_index = get_calendar_end_date('year_end_445', header['end_date'], 'index')
          var current_year_end = calendar['year_end_445'][current_year_index]['end_date']
          if(header['end_date'] === current_year_end && data !== null && Object.keys(data['data']['year_end_445']).includes('fiscal_year')){// The next would yield a new year  
            //Check projection data for years
            var projection_data_index = Object.keys(data['data']['year_end']).indexOf(current_year_end)
            if(data === null || projection_data_index + 2 >= Object.keys(data['fiscal_year']).length){
              right_arrow = ''
              end_reached = <h6>End of Projections</h6>
            }
          }

        
          return(
            <React.Fragment>
              <Row className='mt-5'>
                <Col className='card'>
                    <h5 className='align-items-center mt-1 float-end'>{header['label']}</h5>
                </Col>
                {right_arrow}
              </Row>
              <Row>
                <Col className='text-center'>
                  {end_reached}
                </Col>
              </Row>
            </React.Fragment>
          )

        }

        else{
          return(
            <React.Fragment>
              <Row className='mt-5'>
                <Col className='card'>
                    <h5 className='align-items-center mt-1 float-end'>{header['label']}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                {end_reached}
                </Col>
              </Row>
            </React.Fragment>
          )
        }

        
      }

      function render_inputs(end_date, i){
        var settings = inputSettings[selected_calendar_type][end_date]
        
        //Holder to put elements into
        var render_elements = []
        

        //Inputs Title
        var inputs_title = <h5 className='mt-1'>Inputs:</h5>
        if(i !== 0){
          inputs_title = <h5 className='mt-1'>&nbsp;</h5>
        }


        //Input Type
        var [t_ele, input_type, original_input_type] = render_input_type(end_date, settings, i)
        render_elements.push(t_ele)


        //Input Val
        t_ele = render_input_val(end_date, settings, i, input_type, original_input_type)
        render_elements.push(t_ele)


        //Gross Margin
        t_ele = render_gross_margin(end_date, settings, i)
        render_elements.push(t_ele)


        //VOE
        t_ele = render_voe(end_date, settings, i)
        render_elements.push(t_ele)

        return(
          <React.Fragment>
            <Row>
              <Col  className="px-0">
                {inputs_title}
                
              </Col>
            </Row>
            <Row>
              <Col className='card'>
                {render_elements}
              </Col>
            </Row>
          </React.Fragment>
        )
      }

        //Input Type
        function render_input_type(end_date, settings, i){
          
          var input_class_warning = {backgroundColor: warningColors['white']}
          var tooltip_content = ''

          var input_type = ''
          var original_input_type = settings['input_type']
          if(Object.keys(changed_input_settings).includes(end_date)){//If Changed
            input_type = changed_input_settings[end_date]['input_type']
          }
          else{
            input_type = original_input_type
          }


          var tooltip_label_class = ''
          if(Object.keys(changed_input_settings).includes(end_date) && input_type !== original_input_type){//If Changed
            input_class_warning = {backgroundColor: warningColors['yellow']}
            tooltip_label_class = ''
            
            if(settings['input_type'] === 'payback_period'){tooltip_content = 'Original Value: Payback Period'}
            else if(settings['input_type'] === 'ad_spend_dollars'){tooltip_content = 'Original Value: Ad Spend'}
            else if(settings['input_type'] === 'ad_spend_perc'){tooltip_content = 'Original Value: Ad Spend Perc'}
            else if(settings['input_type'] === 'Various'){tooltip_content = 'Weekly projections have varying input types. Updating this will change all weekly input types within this period.'}
          }


          var option_various = ''
          if(input_type === 'Various' || (original_input_type == 'Various' && input_type !== 'Various')){
            option_various = <option value='Various'>Various</option>
            tooltip_content = 'Weekly projections have varying input types. Updating this will change all weekly input types within this period.'
          }

      

          var t_ele = 
            <React.Fragment>
              <Row lg="auto">
                      <Col  className="mt-3" lg="auto">
                        <select 
                          id={"inp_type_"+end_date}  
                          key={"inp_type_"+end_date} 
                          className={'float-start '}
                          style={input_class_warning} 
                          defaultValue={input_type} 
                          onChange={check_input_type_change} 

                          data-tooltip-id={'tooltip_inp_type_' + end_date}
                          data-tooltip-content={tooltip_content}
                          data-tooltip-place="top"
                          
                          data-input-val-id={"inp_val_"+end_date} 
                          data-label-id={"inp_val_label_"+end_date}
                          data-end-date={end_date}

                        >
                          {option_various}
                          <option value='payback_period'>Payback Period</option>
                          <option value='ad_spend_dollars'>Ad Spend $</option>
                          <option value='ad_spend_perc'>Ad Spend YoY %</option>
                        </select>
                      </Col>
                    </Row>
            </React.Fragment>
          
          return [t_ele, input_type, original_input_type]
        }

          //Input Type
          function check_input_type_change(e){
            //Get Attributes
            var input_type_id = e.target.id
            var end_date = get_attr(input_type_id, 'data-end-date')
            
            var new_val = e.target.value

            //Get original Values
            var settings = inputSettings[selected_calendar_type][end_date]
            var original_input_type = settings['input_type']
            
            //input_val
            var input_val_id = 'inp_val_'+end_date
            var original_input_val = settings['input_val']

            
            var tooltip_content = ''
            if(original_input_type !== new_val){//Not Original
              //Set input_val
              get_ele(input_val_id).value = ''

            }
            else{//Original
              //Set Input Val
              get_ele(input_val_id).value = original_input_val
            }

            check_if_need_to_update_changed_settings(end_date)

          }


        //Input Val
        function render_input_val(end_date, settings, i, input_type, original_input_type){
          var original_input_val = settings['input_val'] // Default Val
          var input_val = original_input_val
          var tooltip_content = ''
          var input_class_warning = {backgroundColor: warningColors['white']}

          if(Object.keys(changed_input_settings).includes(end_date)){//If Changed
            input_val = changed_input_settings[end_date]['input_val']
            if(input_val !== ''){
              input_val = parseFloat(input_val)
            }

            //Set Background Color
            if(input_val === ''){
              input_class_warning = {backgroundColor: warningColors['red']}
            }
            else if(input_val !== original_input_val){
              input_class_warning = {backgroundColor: warningColors['yellow']}
            }

            //Set Tooltip
            if(input_val === original_input_type){
              tooltip_content = 'Original Value: ' + settings['input_val']
            }

          }

          

          var input_text = ''
          if(input_type === 'payback_period'){input_text = 'Weeks'}
          else if(input_type === 'ad_spend_dollars'){input_text = '$'}
          else if(input_type === 'ad_spend_perc'){
            input_text = '%'
            input_val = input_val * 100
          }


          var na_input_style = {display: 'none'}
          var na_input_tooltip_content = ''
          if(input_type === 'Various'){
            input_class_warning = {display: 'none'}
            na_input_style = {}
            na_input_tooltip_content = 'Change input type to enable.'
          }

          try{
            
            input_val = Math.round(parseFloat(input_val) * 100) / 100
          }
          catch(e){}

          if(String(input_val) === 'NaN'){
            input_val = ''
          }


          var t_ele = 
            <React.Fragment>
              <Row className='mt-2'>
                <Col lg={6} className="">
                  <input 
                    id={"inp_val_"+end_date} 
                    key={"inp_val_"+end_date} 
                    type='number' 
                    className={'w-100 text-align-center '}
                    style={input_class_warning}
                    defaultValue={input_val}   
                    onBlur={check_input_val_change}

                    data-tooltip-id={'tooltip_inp_val_'+end_date}
                    data-tooltip-content={tooltip_content}
                    data-tooltip-place="top"

                    data-end-date = {end_date}

                    />

                    <input 
                      style={na_input_style}
                      defaultValue='NA'   
                      className={'w-100 text-align-center '}
                      type='text'                      
                      disabled
                      />
                    
                    
                </Col>
                <Col className='ps-0 d-flex align-items-center' lg="auto">
                  <h6 id={"inp_val_label_"+end_date}>{input_text}</h6>
                </Col>
              </Row>
            </React.Fragment>
          return(t_ele)
        }

          function check_input_val_change(e){
            //Get Attributes
            var input_val_id = e.target.id
            var end_date = get_attr(input_val_id, 'data-end-date')
            
            check_if_need_to_update_changed_settings(end_date)
            
          }


        
        //Input: Gross Margin
        function render_gross_margin(end_date, settings, i){
          var original_gross_margin_perc = Math.round(settings['gross_margin_perc'] * 1000) / 10
          var gross_margin_perc = original_gross_margin_perc
          var tooltip_id = 'inp_gross_margin_tooltip_' + end_date
          var tooltip_content = ''
          var input_class_warning = {backgroundColor: warningColors['white']}



          if(Object.keys(changed_input_settings).includes(end_date)){//If Changed
            gross_margin_perc = changed_input_settings[end_date]['gross_margin_perc']
            if(gross_margin_perc !== ''){
              gross_margin_perc = Math.round(gross_margin_perc * 1000) / 10
            }
            
            
            //Tooltip
            if(original_gross_margin_perc !== gross_margin_perc){
              input_class_warning = {backgroundColor: warningColors['yellow']}
              tooltip_content = 'Original Value: ' + gross_margin_perc + '%'
            }
            else if(gross_margin_perc === ''){
              input_class_warning = {backgroundColor: warningColors['red']}
              tooltip_content = 'Original Value: ' + gross_margin_perc + '%'
            }

          }
          else{//Not Changed
            if(settings['has_different_gross_margin_perc']){
              tooltip_content = 'Weekly projections have varying gross margin inputs. <br /> This is an average of the inputs, not the dollar amount.'
            }
          }



          return(
            <React.Fragment>
              <Row>
                <Col className="mt-3">
                  <h6 className='mb-0'
                  data-tooltip-id={"tooltip_header_inp_gross_margin_" + end_date}
                  data-tooltip-html={"Percent of sales remaining after Cost of Goods sold."}
                  data-tooltip-place="top"
                  >Gross Margin %:</h6>
                  
               </Col>
              </Row>
              <Row className='mt-0'>
                <Col lg={6} className="">
                  <input 
                  id={"inp_gross_margin_perc_"+end_date} 
                  key={"inp_gross_margin_perc_"+end_date} 
                  type='number' placeholder='58' 
                  className='w-100' 
                  style={input_class_warning}
                  defaultValue={gross_margin_perc}  
                  onBlur={check_input_gross_margin_change} 

                  data-tooltip-id={tooltip_id}
                  data-tooltip-html={tooltip_content}
                  data-tooltip-place="top"

                  data-end-date = {end_date}

                  />
                  
                </Col>
                <Col lg="auto" className='pt-1 ps-0'>
                  <h6 className='mb-0'>%</h6>
                </Col>
              </Row>
            </React.Fragment>
          )
        }

          function check_input_gross_margin_change(e){
            //Get Attributes
            var gross_margin_perc_id = e.target.id
            var end_date = get_attr(gross_margin_perc_id, 'data-end-date')

            check_if_need_to_update_changed_settings(end_date)
            
          }

        //Input: VOE
        function render_voe(end_date, settings, i){
          var original_variable_operating_expense =  Math.round(settings['variable_operating_expense'] * 1000) / 10
          var variable_operating_expense = original_variable_operating_expense
          var tooltip_content = ''
          var input_class_warning = {backgroundColor: warningColors['white']}
          if(Object.keys(changed_input_settings).includes(end_date)){//If Changed
            variable_operating_expense = changed_input_settings[end_date]['variable_operating_expense']
            if(variable_operating_expense !== ''){
              variable_operating_expense = Math.round(variable_operating_expense * 1000) / 10
            }

            //Tooltip
            
            if(original_variable_operating_expense !== variable_operating_expense){
              tooltip_content = 'Original Value: ' + original_variable_operating_expense + '%'
              input_class_warning = {backgroundColor: warningColors['yellow']}
            }
            else if(variable_operating_expense === ''){
              tooltip_content = 'Original Value: ' + original_variable_operating_expense + '%'
              input_class_warning = {backgroundColor: warningColors['red']}
            }
          }

          if(settings['has_different_variable_operating_expense']){
            tooltip_content = 'Weekly projections have varying VOE inputs. <br /> This is an average of the inputs, not the dollar amount.'
          }

          
          var t_ele = 
            <React.Fragment>
              <Row>
                <Col className="mt-3 mb-0">
                  <h6 className='mb-0'
                  data-tooltip-id={"tooltip_header_inp_gross_margin_" + end_date}
                  data-tooltip-html={"Percent of Revenue for Variable Operating Expenses."}
                  data-tooltip-place="top"
                  >VOE:</h6>
                </Col>
              </Row>
                <Row className='mt-0 mb-3'>
                  <Col lg={6} className="">
                    <input 
                    id={"inp_variable_operating_expense_"+end_date} 
                    key={"inp_variable_operating_expense_"+end_date} 
                    type='number'  
                    className='w-100' 
                    style={input_class_warning}
                    defaultValue={variable_operating_expense}  
                    onBlur={check_input_voe_change}

                    data-tooltip-id={'inp_voe_tooltip_'+end_date}
                    data-tooltip-html={tooltip_content}
                    data-tooltip-place="top"

                    data-end-date = {end_date}
                    />
                  </Col>
                  <Col lg="auto" className='pt-1 ps-0'>
                    <h6 className='mb-0'>%</h6>
                  </Col>
                </Row>
            </React.Fragment>
          return t_ele
        }

          function check_input_voe_change(e){
            //Get Attributes
            var voe_id = e.target.id
            var end_date = get_attr(voe_id, 'data-end-date')

            check_if_need_to_update_changed_settings(end_date)
            
          }

        //Input: Handle Change
        function check_if_need_to_update_changed_settings(end_date){
          //Check if we can remove the entry from changedInputSettings
          var settings = inputSettings[selected_calendar_type][end_date]
          var input_type = get_val('inp_type_'+end_date)
          
          var input_val = get_val('inp_val_'+end_date)
          input_val = parseFloat(input_val)
          if(input_type === 'ad_spend_perc'){input_val = input_val / 100}

          var gross_margin_perc = get_val('inp_gross_margin_perc_'+end_date) / 100
          var variable_operating_expense = get_val('inp_variable_operating_expense_'+end_date) / 100

          
          if(input_type !== settings['input_type'] 
              || (input_val !== settings['input_val'] && input_type !== 'Various')
              || gross_margin_perc !== settings['gross_margin_perc'] 
              || variable_operating_expense !== settings['variable_operating_expense'] ){
                //It is different, so we'll add or update.
                changed_input_settings[end_date] = {
                  input_type: input_type, 
                  input_val: input_val, 
                  gross_margin_perc: gross_margin_perc, 
                  variable_operating_expense: variable_operating_expense
                }
          }
          else if(Object.keys(changed_input_settings).includes(end_date)){//It is the same
            delete changed_input_settings[end_date]
          }

          setChangedInputSettings({...changed_input_settings})


        }

        

      //----DATA------
      function render_output(i, header, data_type){
        if(parent_job_status_id !== null){
          return loading_gif('Running Projection')
        }
        else if(data === null){
          return 
        }

        return(
          <React.Fragment>
            {/*render_fiscal_years(header['end_date'])*/}
            {render_proforma(header['end_date'], data_type, i)}
            {render_customers(header['end_date'], data_type, i)}
          </React.Fragment>
        )
      }

        //Children
        function render_fiscal_years(end_date){
          if(data === null || Object.keys(data).length == 0){
            return
          }
          

          end_date = Date.parse(end_date)
          var render_data = data['data']['year_end_445']

          const year_keys = Object.keys(render_data)
        
          for(var i=0;i<year_keys.length;i++){
            var year = Date.parse(year_keys[i])
            if(year >= end_date){
              var year_index = i
              break
            }
          }

          var year_data = render_data[year_keys[year_index]]
          var year_one_label = 'Fiscal Year ' +  get_calendar_nested_label('year_end_445', 'end_date', year_keys[year_index], 'label')
          var year_one_total = format_dollar(year_data['revenue']['v'], false)
          var year_one_yoy = format_percent(year_data['revenue']['yoy'], true)
          

          year_data = render_data[year_keys[year_index+1]]
          var year_two_label = 'Fiscal Year ' +  get_calendar_nested_label('year_end_445', 'end_date', year_keys[year_index+1], 'label')
          var year_two_total = format_dollar(year_data['revenue']['v'], false)
          var year_two_yoy = format_percent(year_data['revenue']['yoy'], true)

          year_data = render_data[year_keys[year_index+2]]
          
          var year_three_label = 'Fiscal Year ' +  get_calendar_nested_label('year_end_445', 'end_date', year_keys[year_index+2], 'label')
          var year_three_total = format_dollar(year_data['revenue']['v'], false)
          var year_three_yoy = format_percent(year_data['revenue']['yoy'], true)

          return(
            <React.Fragment>
              <Col className='ms-5' lg="auto">
              <Row>
              <Col  className="px-0">
                <h5 className='mt-4 mb-0'>Proj Revenue:</h5>
              </Col>
            </Row>
            <Row>
              <Col className='card'>
                {render_info_block(year_one_label, year_one_yoy, year_one_total, '')}
                {render_info_block(year_two_label, year_two_yoy, year_two_total, '')}
                {render_info_block(year_three_label, year_three_yoy, year_three_total, '')}
              </Col>
            </Row>
            </Col>

            </React.Fragment>
          )
        }

        function render_proforma(end_date, data_type, i){
          var is_actual_text = ''
          if(data_type === 'actual'){
            is_actual_text = ' Actual'
          }
          else if(data_type === 'partial'){
            is_actual_text = 'Partial'
          }



          // Get this Year Data
          var ty_data = data['data'][selected_calendar_type][end_date]
          var ty_input_settings = inputSettings[selected_calendar_type][end_date]

          // Get LY data
          //debugger
          var ly_date_key = get_ly_calendar_date(end_date, selected_calendar_type, calendar)
          var ly_data = data['data'][selected_calendar_type][ly_date_key]
          var ly_input_settings = inputSettings[selected_calendar_type][ly_date_key]

    
          //Revenue
          var revenue = format_dollar(ty_data['revenue']['v'] / 1000, false)
          var yoy_revenue = format_percent(ty_data['revenue']['yoy'], true)
          var diff_revenue = format_dollar(ty_data['revenue']['ly'], true)


          //Gross Profit
          var ty_gross_profit = ty_data['gross_profit']['v']
          var diff_gross_profit = ty_gross_profit - ty_data['gross_profit']['ly']
          var yoy_gross_profit =  ty_data['gross_profit']['yoy']

          var render_gross_profit = format_dollar(ty_gross_profit / 1000, false) 
          var render_diff_gross_profit = format_dollar(diff_gross_profit, false)
          var render_yoy_gross_profit = format_percent(yoy_gross_profit, true)


          //VOE
          var ty_voe = ty_data['pro_forma_variable_operating_expense']['v']
          var ly_voe = ty_data['pro_forma_variable_operating_expense']['ly']
          var diff_voe = ty_voe - ly_voe
          var yoy_voe =  ty_data['pro_forma_variable_operating_expense']['yoy']

          var render_voe = format_dollar(ty_voe / 1000, false) 
          var render_yoy_voe = format_percent(yoy_voe, true)
          var render_diff_voe = format_dollar(diff_voe, false) 


          //variable_contribution Profit
          var ty_variable_profit = ty_data['pro_forma_contribution']['v']
          var ly_variable_profit = ty_data['pro_forma_contribution']['ly']
          var diff_variable_profit = ty_variable_profit - ly_variable_profit
          var yoy_variable_profit =  ty_data['pro_forma_contribution']['yoy']

          var render_contribution = format_dollar(ty_variable_profit / 1000, false) 
          var render_yoy_contribution = format_percent(yoy_variable_profit, true)
          var render_diff_contribution = format_dollar(diff_variable_profit, false)

          //Ad Spend
          var ty_ad_spend = ty_data['ad_spend']['v']
          var ly_ad_spend = ty_data['ad_spend']['ly']
          var diff_ad_spend = ty_ad_spend - ly_ad_spend
          var yoy_ad_spend =  ty_data['ad_spend']['yoy']

          var render_ad_spend = format_dollar(ty_ad_spend / 1000, false)
          var render_yoy_ad_spend = format_percent(yoy_ad_spend, true)
          var render_diff_ad_spend = format_dollar(diff_ad_spend, true)

          //Net Variable Contribution
          var ty_net_contribution = ty_data['pro_forma_net_contribution']['v']
          var ly_net_contribution = ty_data['pro_forma_net_contribution']['ly']
          var diff_net_contribution = ty_net_contribution - ly_net_contribution
          var yoy_net_contribution =  ty_data['pro_forma_net_contribution']['yoy']


          var render_net_variable_contribution = format_dollar(ty_net_contribution / 1000, false) 
          var render_yoy_net_variable_contribution = format_percent(yoy_net_contribution, true)
          var render_diff_net_variable_contribution = format_dollar(diff_net_contribution, false) 

          
          
          var pro_forma_title = <h5 className='mt-1'>(000's)  {is_actual_text}</h5>
          var revenue_title = 'Revenue:'
          var gross_profit_title = 'Gross Profit:'
          var voe_title = 'VOE:'
          var contribution_title = 'Variable Contribution:'
          var ad_spend_title = 'Ad Spend:'
          var net_variable_contribution_title = 'Net Variable Cont:'

          if(i !== 0){
            pro_forma_title = <h5 className='mt-1'>&nbsp;  {is_actual_text}</h5>
            revenue_title = ''
            gross_profit_title= ''
            voe_title = ''
            contribution_title = ''
            ad_spend_title = ''
            net_variable_contribution_title = ''
          }


          



          return(
            <React.Fragment>
              <Row className='mt-3'>
              <Col  className="px-0">
                {pro_forma_title}
              </Col>
            </Row>
            <Row>
              <Col className='card' >
              {render_info_block(revenue_title, yoy_revenue, revenue, diff_revenue, 'Gross Revenue', 'tooltip_header_revenue_' + end_date)}
              {render_info_block(gross_profit_title, render_yoy_gross_profit, render_gross_profit, render_diff_gross_profit, 'Gross Revenue less Gross Margin %', 'tooltip_header_pro_forma_gross_profit_' + end_date)}
                {render_info_block(voe_title, render_yoy_voe, render_voe, render_diff_voe, 'Amount of Variable Operating Costs.', 'tooltip_header_pro_forma_voe_' + end_date)}  
                {render_info_block(contribution_title, render_yoy_contribution, render_contribution, render_diff_contribution, 'Gross Profit less Variable Operating Expenses', 'tooltip_header_pro_forma_contribution_' + end_date)}                
                {render_info_block(ad_spend_title, render_yoy_ad_spend, render_ad_spend, render_diff_ad_spend)}
                {render_info_block(net_variable_contribution_title, render_yoy_net_variable_contribution, render_net_variable_contribution, render_diff_net_variable_contribution, 'Variable Contribution less Ad Spend', 'tooltip_header_pro_forma_net_contribution_' + end_date)}

              </Col>
            </Row>

            </React.Fragment>
          )
        }

        function render_customers(end_date, data_type, i){
          var is_actual_text = '\u00A0'
          if(data_type === 'actual'){
            is_actual_text = ' Actual'
          }
          else if(data_type === 'partial'){
            is_actual_text = 'Partial'
          }

          var t_data = data['data'][selected_calendar_type][end_date]

          
          //Total
          var total = format_number(t_data['customers']['v'])
          var yoy_total = format_percent(t_data['customers']['yoy'], true)
          var diff_total = format_number(t_data['customers']['v'] - t_data['customers']['ly'])


          //New-vs-returning 
          //New
          if(t_data === undefined){
            //New
            var newc = '\u00A0'
            var yoy_newc = '\u00A0'
            var diff_newc = '\u00A0'

            //CAC
            var cac = '\u00A0'
            var yoy_cac = '\u00A0'
            var diff_cac = '\u00A0'
          }
          else{
            //New
            var newc = format_number(t_data['new_customers']['v'])
            var yoy_newc = format_percent(t_data['new_customers']['yoy'], true)
            var diff_newc = format_number(t_data['new_customers']['v'] - t_data['new_customers']['ly'])

            //CAC
            
            var cac = format_dollar(t_data['cac']['v'], false)
            var yoy_cac = format_percent(t_data['cac']['yoy'], true)
            var diff_cac = format_dollar(t_data['cac']['v'] - t_data['cac']['ly'], false)
          }
          
        

          //Returning
          var returning = 'NA'
            var yoy_returning = ''
            var diff_returning = ''  
          if(t_data !== undefined){
            returning = format_number(t_data['returning_customers']['v'])
            yoy_returning = format_percent(t_data['returning_customers']['yoy'], true)
            diff_returning = format_number(t_data['returning_customers']['v'] - t_data['returning_customers']['ly'])
          }
          

          //CLTR
          if(t_data === undefined){
            var cltr = 'NA'
            var yoy_cltr = 'NA'
            var diff_cltr = 'NA'
          }
          else{
            var cltr = format_dollar(t_data['cltr']['v'], false)
            var yoy_cltr = format_percent(t_data['cltr']['yoy'], true)
            var diff_cltr = format_number(t_data['cltr']['v'] - t_data['cltr']['ly'])
          }
          
          var customer_title = <h5 className='mt-1'>Customers: {is_actual_text}</h5>
          var total_title = 'Total: '
          var new_title = 'New: '
          var returning_title = 'Returning: '
          var cltr_title = 'CLTR: '
          var cac_title = 'CAC: '
          if(i !== 0){
            customer_title = <h5 className='mt-1'>{is_actual_text}</h5>
            total_title = ''
            new_title = ''
            returning_title = ''
            cltr_title = ''
            cac_title = ''
          }

          

          return(
            <React.Fragment>
              <Row className='mt-3'>
              <Col  className="px-0">
                {customer_title}
              </Col>
            </Row>
            <Row className='mb-5'>
              <Col className='card'>
                {render_info_block(total_title, yoy_total, total, diff_total)}
                {render_info_block(new_title, yoy_newc, newc, diff_newc)}
                {render_info_block(returning_title, yoy_returning, returning, diff_returning)}
                {render_info_block(cltr_title, yoy_cltr, cltr, diff_cltr, 'Customer Lifetime Revenue. Based on 3 year life.', 'tooltip_header_CLTR_' + end_date)}
                {render_info_block(cac_title, yoy_cac, cac, diff_cac)}
              </Col>
            </Row>
            </React.Fragment>
          )
        }

    
  // Render Utility
  function render_info_block(title, percent, total, change, tooltip_content, tooltip_id){
    change = ''

    if(title === ''){
      title = <h6 
                  className='mb-0'
              >&nbsp;</h6>
    }else{
      title = <h6 
                  className='mb-0' 
                  data-tooltip-id={tooltip_id}
                  data-tooltip-html={tooltip_content}
                  data-tooltip-place="top"
      >{title}</h6>
    }
    return(
      <React.Fragment>
        <Row>
          <Col  className="mt-2">
            {title}
          </Col>
        </Row>
        <Row className='mt-0'>
          <Col lg="auto" className=" ps-2 pe-1 d-flex align-items-center">
            <h4 className='mb-0'>{percent}</h4>
          </Col>
          <Col lg="auto" className='ps-1'>
            <Row><Col><h6 className='mb-0 mt-1'>{total}</h6></Col></Row>
            {/*<Row><Col><h6 className='mb-0'>{change}</h6></Col></Row>*/}
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  // Other Utility
  function get_calendar_nested_label(cal_type, compare_col, val, return_col){
    for(var i = 0; i<calendar[cal_type].length;i++){
      if(calendar[cal_type][i][compare_col] === val){
        return calendar[cal_type][i][return_col]
      }
    }
  }
  
  function get_calendar_end_date(compare_col, val, return_val){
    var index = -1
    var end_val = ''
    for(var i=calendar[compare_col].length-1;i>=0;i--){
      end_val = calendar[compare_col][i]['end_date']
      if(Date.parse(end_val) >= Date.parse(val)){
        index = i
        break
      }
    }

    if(return_val === 'index'){
      return index
    }
    else{
      return calendar[compare_col][index]
    }

  }






  //Output


  return(
    <Container lg={800}>
        <Row>
          <Col lg ="auto">
            <h1>What If</h1>
          </Col> 
        </Row>
        <Row>
        <Col>
            <p className='mt-3 pe-0'>Data As Of: {get_calendar_selected_date(lastTransaction, calendar, 'week_end_445')['value']}</p>
          </Col>
        </Row>
        
            {SideMenu({
        firstTransaction:firstTransaction, setFirstTransaction:setFirstTransaction,
        lastTransaction:lastTransaction, setLastTransaction:setLastTransaction,
        filters:filters, setFilters:setFilters,
        calendar:calendar, setCalendar:setCalendar,
        selected_calendar_type:selected_calendar_type, setSelectedCalendarType:setSelectedCalendarType,
        selected_calendar_date:selected_calendar_date, setSelectedCalendarDate:setSelectedCalendarDate,
        cohort_filter_id:cohort_filter_id, setCohortFilterID:setCohortFilterID,
        parent_component: 'page_what_if'
      })}
          
        
        
        {body_loader()}
        
        
    </Container>
  )
    
    
  


 }

  
    