import React from "react";
import moment from "moment";
import { useLocation } from 'react-router-dom'

//import { Line } from "react-chartjs-2";
//import dashboard_api from "../../api/dashboard";
import { Container } from "react-bootstrap";
import { Chart, registerables } from 'chart.js';
import "react-datepicker/dist/react-datepicker.css";
import '../globals/globals.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { format_percent } from "../globals/globals";

import { SideMenu } from '../side_menu function/SideMenu.jsx';

import { useState, useEffect } from "react";

import {DriversAndDrags} from "./drivers_and_drags";
import { Tiles } from "./tiles";
import { flushSync } from "react-dom";
import { DashboardGraphLevers } from "./dashboard_graph_levers";
import { DashboardGraphRevenue } from "./dashboard_graph_revenue";
import { DashboardGraphNewReturningRevenue } from "./dashboard_graph_new_returning_revenue";





Chart.register(...registerables);


export const  PageDashboard = (props) =>{
  const location = useLocation()
  
  //Side menu api
  const [filters, setFilters] = useState(window.localStorage.getItem('filters') === null ? null : JSON.parse(window.localStorage.getItem('filters')))
  const [calendar, setCalendar] = useState(window.localStorage.getItem('calendar') === null ? null : JSON.parse(window.localStorage.getItem('calendar')))

  //Side menu set
  const [firstTransaction, setFirstTransaction] = useState(window.localStorage.getItem('firstTransaction') === null ? null : (window.localStorage.getItem('firstTransaction')))
  const [lastTransaction, setLastTransaction] = useState(window.localStorage.getItem('lastTransaction') === null ? null : (window.localStorage.getItem('lastTransaction')))
  const [selected_calendar_date, setSelectedCalendarDate] = useState(window.localStorage.getItem('selected_calendar_date') === null ? null : JSON.parse(window.localStorage.getItem('selected_calendar_date')))
  const [cohort_filter_id, setCohortFilterID] = useState(window.localStorage.getItem('cohort_filter_id') === null ? null : parseInt(window.localStorage.getItem('cohort_filter_id')))
  const [selected_calendar_type, setSelectedCalendarType] = useState(window.localStorage.getItem('selected_calendar_type') === null ? 'month_end_445' : window.localStorage.getItem('selected_calendar_type')) 
  

  //Job Status
  //const [parent_job_status_id, setParentJobStatusId] = useState(0)
  //const [job_status, setJobStatus] = useState('')

  useEffect(() => {
    
    
  });


  //page_dashboard
  var colors = {'good': '87ff63', 'bad':'ff6384', 'upper_bound':0.05, 'lower_bound':-0.05}


  

  
  
  //Rendering

  function render_page_components(){
    
    if(cohort_filter_id === null){
      
      return 
    }
    else{
      return(
        <React.Fragment>
          <Row className="mt-5">
            <Tiles
              selected_calendar_type= {selected_calendar_type}
              selected_calendar_date={selected_calendar_date}
              cohort_filter_id={cohort_filter_id}
              colors={colors}
              lastTransaction={lastTransaction}
            />
          </Row>
          <Row className="mt-3">
            <DriversAndDrags
              selected_calendar_type= {selected_calendar_type}
              selected_calendar_date={selected_calendar_date}
              cohort_filter_id={cohort_filter_id}
            />
          </Row>
          <Row className="mt-5">
              <DashboardGraphRevenue
                selected_calendar_type= {selected_calendar_type}
                selected_calendar_date={selected_calendar_date}
                cohort_filter_id={cohort_filter_id}
                calendar={calendar}
                />
            </Row>
          <Row className="mt-5"><Col>&nbsp;</Col></Row>
            <Row className="mt-5">
              <DashboardGraphNewReturningRevenue
                selected_calendar_type= {selected_calendar_type}
                selected_calendar_date={selected_calendar_date}
                cohort_filter_id={cohort_filter_id}
                calendar={calendar}
                />
            </Row>
            <Row className="mt-5"><Col>&nbsp;</Col></Row>
          <Row className="mt-5">
              <DashboardGraphLevers
                selected_calendar_type= {selected_calendar_type}
                selected_calendar_date={selected_calendar_date}
                cohort_filter_id={cohort_filter_id}
                calendar={calendar}
                />
            </Row>
        </React.Fragment>
      )
    }
  }


    return (
      <Container>
        <Row>
          <Col lg ="2">
            <h1>Dashboard</h1>
          </Col>
          {SideMenu({
            firstTransaction:firstTransaction, setFirstTransaction:setFirstTransaction,
            lastTransaction:lastTransaction, setLastTransaction:setLastTransaction,
            filters:filters, setFilters:setFilters,
            calendar:calendar, setCalendar:setCalendar,
            selected_calendar_type:selected_calendar_type, setSelectedCalendarType:setSelectedCalendarType,
            selected_calendar_date:selected_calendar_date, setSelectedCalendarDate:setSelectedCalendarDate,
            cohort_filter_id:cohort_filter_id, setCohortFilterID:setCohortFilterID,
            parent_component: 'page_dashboard',
            calendar_dropdown_to_fill: 'last_transaction_and_prior', // See SideMenu for accepted values
          })}
        </Row>

        {render_page_components()}
        
      </Container>
    );
}







