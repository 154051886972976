
import { useState, useEffect } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get_val, send_request } from '../../../globals/globals';



export const  RenameColumns = (props) =>{

    
    useEffect(() => {
        
    });


    //Rename Columns
    function render_column_rename(){
        if(!props.stageShowColumnRename){
          return 
        }

        //Get what the options can be
        var columns_used_in_mapping = []
        for(var i = 0;i<Object.keys(props.fileColumnMappings).length;i++){
            var col_name = props.fileColumnMappings[Object.keys(props.fileColumnMappings)[i]]
            if(col_name === ''){continue}
            columns_used_in_mapping.push(col_name)
        }

        var sorted_file_columns = props.fileColumns
        sorted_file_columns.sort()

        var options = [<option value=''></option>]
        for(let i = 0; i<sorted_file_columns.length;i++){
            var col_name = sorted_file_columns[i]
            if(columns_used_in_mapping.includes(col_name)){//Column was used in mapping so it can't be renamed.
                continue
            }
          

            options.push(<option value={col_name}>{col_name}</option>)
        }



        

        //Generate the rows
        var rows = []
        for(var i=0;i<props.fileRenameColumns.length;i++){
            
            var row_classname = i === 0 ? '' : 'mt-2'
            
            rows.push(
                <React.Fragment>
                    <Row lg={4} className={row_classname}>
                        <Col>
                            <select id={'inp_from_col_'+i} defaultValue={props.fileRenameColumns[i]['from_col']} >{options}</select>    
                        </Col>
                        <Col>
                            <input id={'inp_to_col_'+i} defaultValue={props.fileRenameColumns[i]['to_col']}/>    
                        </Col>
                    </Row>
                </React.Fragment>
            )
        }

        //No Values Have been added
        if(rows.length === 0){
            rows.push(
                <React.Fragment>
                    <Row lg={4}>
                        <Col>
                            <select id={'inp_from_col_'+i} defaultValue={''} >{options}</select>    
                        </Col>
                        <Col>
                            <input id={'inp_to_col_'+i} defaultValue={''} />    
                        </Col>
                    </Row>
                </React.Fragment>
            )
        }

        
        
        return(
          <React.Fragment>
            <Row className='mt-5'>
              <Col>
                <h4>Rename Columns:</h4>
              </Col>
            </Row>
            <Row lg={4}>
              <Col>
                <h5>Column:</h5>
              </Col>
              <Col>
                <h5>Renames To:</h5>
              </Col>
            </Row>

            {rows}

            <Row>
              <Col>
                <h6 onClick={()=>add_column_rename()} className='hover_underline'>Add +</h6>
              </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col>
                    <button onClick={set_file_rename_columns} className='btn btn-lg btn-warning mt-3'>Next</button>
                </Col>
            </Row>
                
          </React.Fragment>
        )
  
  
      }


    //Functions
    function add_column_rename(){
        var i = 0
        var inputs = []
        while(true){
            try{
                var from_col = get_val('inp_from_col_'+i)
                var to_col = get_val('inp_to_col_'+i)
                var row = {'from_col': from_col, 'to_col':to_col}
                inputs.push(row)
            }
            catch(e){
                break
            }
            i += 1
        }

        inputs.push({'from_col':'', 'to_col':''})

        debugger
        props.setFileRenameColumns(inputs);
        }

    function set_file_rename_columns(){
        var i = 0
        var inputs = {}
        while(true){
            try{
                var from_col = get_val('inp_from_col_'+i)
                var to_col = get_val('inp_to_col_'+i)
                if(from_col === '' || to_col === ''){
                    i += 1
                    continue
                }
                inputs[from_col] = to_col
            }
            catch(e){
                break
            }
            i += 1
        }

        props.handle_column_rename_next(inputs)
    }

    return(
      <React.Fragment>
        {render_column_rename()}
      </React.Fragment>
    )

}