
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SideMenu } from '../side_menu function/SideMenu.jsx';
import moment from "moment";
import { send_request, format_dollar, format_number, format_percent, get_val, isna, get_ele, set_attr, get_attr, get_job_status_step_counter } from '../globals/globals.js';
import loading_gif from "../globals/loading_gif.js";
import btn_right_arrow from "../images/btn_right_arrow.svg";
import btn_left_arrow from "../images/btn_left_arrow.svg"
import { flushSync } from 'react-dom';

import { Tooltip } from 'react-tooltip'
import { dateISOString } from 'react-s3/lib/Date.js';



export const  PageMarketing = (props) =>{
  //Side menu api
  const [filters, setFilters] = useState(window.localStorage.getItem('filters') === null ? null : JSON.parse(window.localStorage.getItem('filters')))
  const [calendar, setCalendar] = useState(window.localStorage.getItem('calendar') === null ? null : JSON.parse(window.localStorage.getItem('calendar')))

  //Side menu set
  const [firstTransaction, setFirstTransaction] = useState(window.localStorage.getItem('firstTransaction') === null ? null : (window.localStorage.getItem('firstTransaction')))
  const [lastTransaction, setLastTransaction] = useState(window.localStorage.getItem('lastTransaction') === null ? null : (window.localStorage.getItem('lastTransaction')))
  const [selected_calendar_date, setSelectedCalendarDate] = useState(window.localStorage.getItem('selected_calendar_date') === null ? null : JSON.parse(window.localStorage.getItem('selected_calendar_date')))
  const [cohort_filter_id, setCohortFilterID] = useState(window.localStorage.getItem('cohort_filter_id') === null ? null : parseInt(window.localStorage.getItem('cohort_filter_id')))
  const [selected_calendar_type, setSelectedCalendarType] = useState('month_end_445') // Default

  const prev_props = useRef();

  //Get Data
  const [cohortData, setCohortData] = useState(null)
  const [dates, setDates] = useState(null)
  const [adSpend, setAdSpend] = useState(null)
  const [sentGetData, setSentGetData] = useState(null)

  const [showValue, setShowValue] = useState('value') //Value / Percent

  const max_col_rows = 4

  //Check job_status
  useEffect(() => {
    //Get data
    if(cohortData === null && !sentGetData){
      prev_props.cohort_filter_id = cohort_filter_id
      prev_props.selected_calendar_type = selected_calendar_type
      prev_props.selected_calendar_date = selected_calendar_date
      get_data()
      setSentGetData(true)
    }
   
    //Side menu changes----
    //Calendar Type
    else if(selected_calendar_type !== prev_props.selected_calendar_type){
      prev_props.selected_calendar_type = selected_calendar_type
      flushSync(
        setCohortData(null),
        setDates(null),
        setAdSpend(null)
      )
    }
    else if(cohort_filter_id !== prev_props.cohort_filter_id){
      prev_props.cohort_filter_id = cohort_filter_id
      flushSync(
        setCohortData(null),
        setDates(null),
        setAdSpend(null)
      )
    }
    else if(selected_calendar_date !== prev_props.selected_calendar_date){
      prev_props.selected_calendar_date = selected_calendar_date
      flushSync(
        setCohortData(null),
        setDates(null),
        setAdSpend(null)
      )
    }

  })


  //Api Calls
  async function get_data(){
    var body = ''
    var response = ''
    
    try{
      body = {
        path:"marketing",
        func:"get_dashboard",
        calendar_type: selected_calendar_type,
        cohort_filter_id: cohort_filter_id,
        as_of: selected_calendar_date['value']
      };
      response = await send_request(body)
      response['cohort_data'] = JSON.parse(response['cohort_data'])
      response['ad_spend'] = JSON.parse(response['ad_spend'])
      response['dates'] = JSON.parse(response['dates'])
      
      debugger
      
      flushSync(
        setCohortData(response['cohort_data']), //Row, Col is the key
        setAdSpend(response['ad_spend']),
        setDates(response['dates']),
        setSentGetData(null)
      )
    }
    catch(err){
      console.log('get_data body:', body)
      console.log('get_data response:', response)
      throw new Error(err)
    }
    
  }


  //Rendering
  function render_body(){
    if(cohortData === null || adSpend === null || dates === null){
      return loading_gif('Loading')
    }


    return(
      <React.Fragment>
        <Row className='mt-4'>

          <Col lg={2} className='px-0'>{render_first_columns()}</Col>
          <Col className='border-left'>
             {render_table('customers')}
          </Col>
          <Col>
            {render_table('revenue')}
          </Col>
        </Row>
        <Row className='mt-4'>

          <Col lg={2} className='px-0'>{render_first_columns()}</Col>
          <Col className='border-left'>
             {render_table('customer_lifetime_revenue')}
          </Col>
          <Col>
            {render_table('lifespan')}
          </Col>
        </Row>
      </React.Fragment>
    )
  }


  function pretty_print_calendar(val){
    var t_cal = calendar[selected_calendar_type]
    
    for(var i=0; i<t_cal.length;i++){
      var row = t_cal[i]
      if(row['end_date'] === val){
        var label = row['label']
        break
      }
    }

    if(selected_calendar_type === 'month_end_445'){
      var split_label = label.split(' ')
      label = split_label[0] + '-' + split_label[1].substring(2,4)
    }

    return label
  }

  function render_first_columns(){

    var col_size = 5

    var ad_spend_0 = Object.keys(adSpend).includes(dates[0]) ? adSpend[dates[0]]['ad_spend'] : 0
    var ad_spend_1 = Object.keys(adSpend).includes(dates[1]) ? adSpend[dates[1]]['ad_spend'] : 0
    var ad_spend_2 = Object.keys(adSpend).includes(dates[2]) ? adSpend[dates[2]]['ad_spend'] : 0
    var ad_spend_3 = Object.keys(adSpend).includes(dates[3]) ? adSpend[dates[3]]['ad_spend'] : 0


    return(
      <React.Fragment>
        <Row>
          {//placeholder for table titles
          }
          <Col><h5>&nbsp;</h5></Col>
        </Row>
        <Row className='mx-0' lg='auto'>
          <Col lg={col_size} className='px-0'>
            <h6 class='text-end'>Date</h6>
          </Col>
          <Col lg={col_size} className='px-0'>
            <h6 class='text-end me-2'>Ad Spend</h6>
          </Col>
        </Row>
        <Row className='mx-0'>
          <Col lg={col_size} className='px-0'><p class="text-end mb-0 ">{pretty_print_calendar(dates[0])}</p></Col>
          <Col lg={col_size} className='border-end'><p class="text-end mb-0 ">{format_dollar(ad_spend_0)}</p></Col>
        </Row>
        <Row className='mx-0'>
          <Col lg={col_size} className='px-0'><p class="text-end mb-0 ">{pretty_print_calendar(dates[1])}</p></Col>
          <Col lg={col_size} className='border-end'><p class="text-end mb-0 ">{format_dollar(ad_spend_1)}</p></Col>
        </Row>
        <Row className='mx-0'>
          <Col lg={col_size} className='px-0'><p class="text-end mb-0 ">{pretty_print_calendar(dates[2])}</p></Col>
          <Col lg={col_size} className='border-end'><p class="text-end mb-0 ">{format_dollar(ad_spend_2)}</p></Col>
        </Row>
        <Row className='mx-0'>
          <Col lg={col_size} className='px-0'><p class="text-end mb-0 ">{pretty_print_calendar(dates[3])}</p></Col>
          <Col lg={col_size} className='border-end'><p class="text-end mb-0">{format_dollar(ad_spend_3)}</p></Col>
        </Row>

      </React.Fragment>
    )
  }



  function render_table(measure){

    var col_width = 2
    var header_width = 8

    var first_row = <React.Fragment>
      <Row>
          <Col lg={col_width} className='mx-0 px-0'><h6 className='text-end'>{pretty_print_calendar(dates[0])}</h6></Col>
          <Col lg={col_width} className='mx-0 px-0'><h6 className='text-end'>{pretty_print_calendar(dates[1])}</h6></Col>
          <Col lg={col_width} className='mx-0 px-0'><h6 className='text-end'>{pretty_print_calendar(dates[2])}</h6></Col>
          <Col lg={col_width} className='mx-0 px-0'><h6 className='text-end'>{pretty_print_calendar(dates[3])}</h6></Col>
        </Row>

    </React.Fragment>


    var table_rows = []
    var row_num = 0
    while(row_num < max_col_rows){
      var col_num = 0
      var row_data = []
      while(col_num < max_col_rows){
        if(col_num < row_num){
          row_data.push( null)  
        }
        else{
          var val = get_cohort_data_row_col(row_num, col_num, measure) // Row Col
          row_data.push(val)
        }
        
        col_num += 1
      } 
      

      var row_cols = [
        //<Col><h6>{dates[0]}</h6></Col>,
       // <Col><h6>{adSpend[dates[0]]['ad_spend']}</h6></Col>
      ]
      
      col_num = 0
      while(col_num < max_col_rows){
        if(col_num < row_num){
          row_cols.push(<Col lg={col_width} className='px-0 mx-0'>&nbsp;</Col>)
        }
        else{
          var classname = 'px-0 mx-0'
          if(col_num === row_num){
            classname += ' border-start border-bottom'
          }
          row_cols.push(<Col lg={col_width}  className={classname}><h6 className='px-0 mx-0 text-end'>{row_data[col_num]}</h6></Col>)
        }

        col_num += 1
      }

      table_rows.push(<Row>{row_cols}</Row>)
      row_num += 1
    }

    var title = ' '
    if(measure === 'customers'){title = 'Number of Customers'}
    else if(measure === 'revenue'){title = 'Revenue'}
    else if(measure === 'customer_lifetime_revenue'){title = 'Customer Lifetime Revenue'}
    else if(measure === 'lifespan'){title = 'Average Life'}


    return(
      <React.Fragment>
        <Row>
          <Col lg={header_width}><h5 className='text-center'>{title}</h5></Col>
        </Row>
        {first_row}
        {table_rows}
        
      </React.Fragment>
    )


  }


    function get_cohort_data_row_col(row, col, measure){
      var row_key = dates[row]
      var col_key = dates[col]
      var row_col_key = "('" + row_key + "', '" + col_key + "')"

      var cohort_data = cohortData[row_col_key]
      var show_value = showValue === 'percent' ? 'p' : 'v'
      var data = cohort_data[measure + '_' + show_value]

      if(data === null){
        return data
      }
        
      if(measure === 'customers'){
        data = format_number(parseInt(data), false)
      }
      else if(measure === 'lifespan'){
        data = parseFloat(data)*100
        data = Math.round(data)
        data = data/100

        data = format_number(data, false)
      }
      else{
        data = format_dollar(data)
      }


      return data
    }


  //Output
  return(
    <Container lg={800}>
        <Row>
          <Col lg ="auto">
            <h1>Marketing</h1>
          </Col>
          {SideMenu({
            firstTransaction:firstTransaction, setFirstTransaction:setFirstTransaction,
            lastTransaction:lastTransaction, setLastTransaction:setLastTransaction,
            filters:filters, setFilters:setFilters,
            calendar:calendar, setCalendar:setCalendar,
            selected_calendar_type:selected_calendar_type, setSelectedCalendarType:setSelectedCalendarType,
            selected_calendar_date:selected_calendar_date, setSelectedCalendarDate:setSelectedCalendarDate,
            cohort_filter_id:cohort_filter_id, setCohortFilterID:setCohortFilterID,
            parent_component: 'page_dashboard',
            calendar_dropdown_to_fill: 'last_transaction_and_prior', // See SideMenu for accepted values
          })}
          
        </Row>
        

        {render_body()}
        
        
        
    </Container>
  )
    
    
  


 }

  
    