
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { send_request, get_val} from '../../../globals/globals';
import { UploadDialog } from './uploadDialog';
import { ColumnMappings } from './column_mappings';
import { flushSync } from 'react-dom';
import { RenameColumns } from './rename_columns';



export const  Filters = (props) =>{
  



     

    //File Filters
    function render_file_filters(){
        if(!props.stageShowFilters){
          return 
        }
  
        var inputs = get_file_filters()
  
        return(
          <React.Fragment>
            <Row className='mt-5'>
              <Col>
                <h4>Filters:</h4>
              </Col>
            </Row>
            <Row lg={5}>
              <Col><h5>Column:</h5></Col>
              <Col><h5>Filter:</h5></Col>
              <Col><h5>Value:</h5></Col>
            </Row>
            {inputs}
            <Row className='mt-2'>
              <Col>
                <h6 onClick={add_file_filters} className='hover_underline'>Add +</h6>
              </Col>
            </Row>
            <Row lg={5}>
                <Col></Col>
                <Col></Col>
                <Col>
                    <button onClick={props.handle_filters_next} className='btn btn-lg btn-warning mt-3'>Finish</button>
                </Col>
            </Row>
                
          </React.Fragment>
        )
  
  
  
      }
  
        function get_file_filters(){
      
            var filters = props.fileFilters
            if(filters.length === 0){
                filters.push({'column': '', 'filter': '', 'value': ''})
            }



            var render_inputs = []
            for(let i=0; i<props.fileFilters.length;i++){
            var column = props.fileFilters[i]['column']
            var filter = props.fileFilters[i]['filter']
            var value = props.fileFilters[i]['value']
            var ele = 
            <Row className='mt-2' lg={5}>
                <Col>
                <input type='text' id={'inp_col_'+i} defaultValue={column}></input>
                </Col>
                <Col>
                <input type='text' id={'inp_filter_'+i} defaultValue={filter}></input>
                </Col>
                <Col>
                <input type='text' id={'inp_value_'+i} defaultValue={column}></input>
                </Col>
            </Row>
            render_inputs.push(ele)
            }
            
            return render_inputs
        }
  
         //Add Transaction Filter
        function add_file_filters(){
            var i = 0
            var inputs = []
            while(true){
                try{
                    var column = get_val('inp_col_'+i)
                    var filter = get_val('inp_filter_'+i)
                    var value = get_val('inp_value_'+i)
                    var row = {'column': column, 'value':value, 'filter': filter}
                    inputs.push(row)
                }
                catch(e){
                    break
                }
                i += 1
            }
    
            inputs.push({'column':'', 'value':'', 'filter': filter})
    
            props.setFileFilters(inputs);
        }
          
  
  

    return(
        <React.Fragment>
            {render_file_filters()}
            
        </React.Fragment>
    )


}