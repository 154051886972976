
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { send_request, get_val} from '../../../globals/globals';
import { UploadDialog } from './uploadDialog';
import { ColumnMappings } from './column_mappings';
import { flushSync } from 'react-dom';
import { RenameColumns } from './rename_columns';



export const  Tags = (props) =>{
  




    //File Tags
    function render_file_tags(){
        if(!props.stageShowTags){
          return 
        }
  
        var inputs = get_file_tags()
  
        return(
          <React.Fragment>
            <Row className='mt-5'>
              <Col>
                <h4>File Tags:</h4>
              </Col>
            </Row>
            <Row lg={4}>
              <Col>
                <h5>Column Name:</h5>
              </Col>
              <Col>
                <h5>Value:</h5>
              </Col>
            </Row>
            {inputs}
            <Row className='mt-2'>
              <Col>
                <h6 onClick={()=>add_file_tag()} className='hover_underline'>Add +</h6>
              </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col>
                    <button onClick={set_file_tags} className='btn btn-lg btn-warning mt-3'>Next</button>
                </Col>
            </Row>
                
          </React.Fragment>
        )
  
  
  
      }
  
        function get_file_tags(){
      

            var tags = props.fileTags
            if(tags.length === 0){
                tags.push({'column': '', 'value': ''})
            }


            var render_inputs = []
            for(let i=0; i<tags.length;i++){
            var column = tags[i]['column']
            var value = tags[i]['value']
            render_inputs.push(
                <Row className='mt-2' lg={4}>
                <Col>
                    <input type='text' id={'inp_tags_column_'+i} defaultValue={column}></input>
                </Col>
                <Col>
                    <input type='text' id={'inp_tags_value_'+i} defaultValue={value}></input>
                </Col>
                </Row>)
            }
      
            return render_inputs
        }
  
      
        //Add File Tag
        function add_file_tag(){
            var i = 0
            var inputs = []
            while(true){
                try{
                    var column = get_val('inp_tags_column_'+i)
                    var value = get_val('inp_tags_value_'+i)
                    var row = {'col': column, 'value':value}
                    inputs.push(row)
                }
                catch(e){
                    break
                }
                i += 1
            }
    
            inputs.push({'column':'', 'value':''})
    
            props.setFileTags(inputs);
        }

        function set_file_tags(){
            var i = 0
            var inputs = {}
            while(true){
                try{
                    var column = get_val('inp_tags_column_'+i)
                    var value = get_val('inp_tags_value_'+i)
                    if(column === '' || value === ''){
                        i += 1
                        continue
                    }
                    inputs[column] = value
                }
                catch(e){
                    break
                }
                i += 1
            }

            props.handle_tags_next(inputs)
        }

  


    return(
        <React.Fragment>
            {render_file_tags()}
        </React.Fragment>
    )

}