
import { useState, useEffect } from 'react';
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get_val, send_request } from '../../../globals/globals';
import loading_gif from '../../../globals/loading_gif';



export const  ColumnMappings = (props) =>{
  
    

    
    useEffect(() => {
        
    });


    




        


          function get_column_dropdown(selected_value){
            console.log('get_column_dropdown', props.fileColumns)
            if(props.fileColumns === undefined){
              return
            }
            var file_columns = [<option key=''></option>]
            for(let i = 0; i < props.fileColumns.length;i++){
              //var selected = ''
              var val = props.fileColumns[i]
              if(selected_value !== undefined && val === selected_value){
                //selected = 'selected'
              }
              file_columns.push(<option key={val}>{val}</option>)
            }
            return file_columns
            
              
          }




    function render_column_mapping(){
        if(!props.stageShowColumnMappings){
          return
        }
        else if(props.previousColumnMappings === null){
            return loading_gif('Matching From Previous Columns')
        }

  
        var column_dropdown = get_column_dropdown(undefined)
  
        const row_lg = 4
        const row_mt = 'mt-1'
        
  
        var revenue = ''
        var order_id = ''
        var sku = ''
        var product_title = ''
        var tax = ''
        var shipping = ''
        var transaction_date = ''
        var customers = ''
        var quantity = ''
        var discounts = ''
        if(Object.keys(props.previousColumnMappings).length > 0){
            revenue = get_preset_column_mapping('revenue')
          order_id = get_preset_column_mapping('order_id')
          sku = get_preset_column_mapping('sku')
          product_title = get_preset_column_mapping('product_title')
          tax = get_preset_column_mapping('tax')
          shipping = get_preset_column_mapping('shipping')
          transaction_date = get_preset_column_mapping('transaction_date')
          customers = get_preset_column_mapping('customers')
          quantity = get_preset_column_mapping('quantity')
          discounts = get_preset_column_mapping('discounts')
        }
  
        
  
        return (
          <React.Fragment>
          <Row>
            <Col>
                <h3>Column Mapping</h3>
            </Col>
          </Row>
          <Row className='mt-2' lg={row_lg}>
            <Col><h4>EQ Value</h4></Col>
            <Col><h4>File Column</h4></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>Revenue</h6></Col>
            <Col><select id='inp_revenue' defaultValue={revenue}>{column_dropdown}</select></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>Order ID</h6></Col>
            <Col><select id='col_order_id' defaultValue={order_id}>{column_dropdown}</select></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>SKU</h6></Col>
            <Col><select id='col_sku' defaultValue={sku}>{column_dropdown}</select></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>Product Title</h6></Col>
            <Col><select id='col_product_title' defaultValue={product_title}>{column_dropdown}</select></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>Tax</h6></Col>
            <Col><select id='col_tax' defaultValue={tax}>{column_dropdown}</select></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>Shipping</h6></Col>
            <Col><select id='col_shipping' defaultValue={shipping}>{column_dropdown}</select></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>Transaction Date</h6></Col>
            <Col><select id='col_transaction_date' defaultValue={transaction_date}>{column_dropdown}</select></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>Customers</h6></Col>
            <Col><select id='col_customers' defaultValue={customers}>{column_dropdown}</select></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>Quantity</h6></Col>
            <Col><select id='col_quantity' defaultValue={quantity}>{column_dropdown}</select></Col>
          </Row>
          <Row className={row_mt} lg={row_lg}>
            <Col><h6>Discounts</h6></Col>
            <Col><select id='col_discounts' defaultValue={discounts}>{column_dropdown}</select></Col>
          </Row>
        <Row className={row_mt} lg={row_lg}>
            <Col></Col>
            <Col>
                <button onClick={set_column_mappings} className='btn btn-lg btn-warning mt-3'>Next</button>
            </Col>
        </Row>
          
          </React.Fragment>
        );
  
        
      }

      function set_column_mappings(){
            var mappings = {
                revenue: get_val('inp_revenue'),
                order_id: get_val('col_order_id'),
                sku: get_val('col_sku'),
                product_title: get_val('col_product_title'),
                tax: get_val('col_tax'),
                shipping: get_val('col_shipping'),
                transaction_date: get_val('col_transaction_date'),
                customers: get_val('col_customers'),
                quantity: get_val('col_quantity'),
                discounts: get_val('col_discounts'),
            }

            props.handle_column_mapping_next(mappings)

        
      }




      



      function get_preset_column_mapping(col_name){
        if(Object.keys(props.previousColumnMappings).includes(col_name)){
          for(var i=0;i<props.previousColumnMappings[col_name].length;i++){
            var previously_set_column_name = props.previousColumnMappings[col_name][i]
            if(props.fileColumns.includes(previously_set_column_name)){
              return previously_set_column_name
              
            }
          }
        }
        return ''
      }

      //On Change
      const update_rename_columns = (e) =>{
        console.log('update_rename_columns', props.fileRenameColumns)
        var val = e.target.value
        var id = e.target.id
        console.log('update_rename_columns', val)
        console.log('update_rename_columns', id)

        
        var t_column_mappings = props.fileColumnMappings.filter(a => a.col !== id)
        props.setFileColumnMappings(oldArray => [...t_column_mappings,{col:id, val:val}]);
        console.log('update_rename_columns', t_column_mappings)
        /*
        var found = false
        for(let i=0;i<t_column_mappings.length;i++){
          var col = t_column_mappings[i][0]
          if(col == id){
            t_column_mappings[i][1] = val
            found = true
            break
          }
        }

        if(!found){
          t_column_mappings.push([id,val])
        }

        
        //Update selected value
        setFileRenameColumns(oldArray => [...t_column_mappings,]);
    */    

        /*
        const found = false
        for(let k=0;k<fileRenameColumns.length;k++){
          var col = fileRenameColumns[k][0]
          
        }
        */
      }

      
    
    return (
      <Container>
          {render_column_mapping()}
      </Container>
    );
  }
  
  